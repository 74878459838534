import React, {useEffect, useState} from 'react';
import '../auth.sass'
import axios from "axios";
import {useNavigate} from "react-router";
import {NavLink} from "react-router-dom";
import {closeSnackbar, enqueueSnackbar} from "notistack";
import CloseIcon from "@mui/icons-material/Close";

const Recovery = (props) => {
    const [userData, setUserData] = useState([]);
    const action = snackbarId => (
        <button onClick={() => { closeSnackbar(snackbarId) }}>
            <CloseIcon/>
        </button>
    );

    const [email, setEmail] = useState();
    const [emailError, setEmailError] = useState('e-mail cannot be empty!');

    const [emailDirty, setEmailDirty] = useState(false);

    const [formValid, setFormValid] = useState(false);

    useEffect(() => {
        if (emailError) {
            setFormValid(false)
        } else {
            setFormValid(true)
        }
    },[emailError])

    const blurHandler = (e) =>  {
        switch (e.target.name){
            case 'email':
                setEmailDirty(true)
                break
        }

    };

    const emailHandler = (e) => {
        setEmail(e.target.value)
        const re =
            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (!re.test(String(e.target.value).toLowerCase())) {
            setEmailError('incorrect e-mail')
            if(!e.target.value){
                setEmailError('e-mail cannot be empty!')
            }
        }
        else{
            setEmailError("")
        }
    }


    const handleChangeEmail = event => {
        setEmail(event.target.value);
    };

    let navigate = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();
        axios.post(`https://api.fantom-fx.org/api/api/users/password/reset?email=${email}`)
            .then(res => {
                setEmail('');
                enqueueSnackbar(`Новый пароль отправлен на вашу почту`, {action, 'variant': 'success', 'className': "snacksuccess"});
            })
            .catch( error => {
                enqueueSnackbar(`Возникла ошибка: ${error.response.data.detail.msg}`, {action, 'variant': 'error', 'className': "snackerror"});
            });
    };

    return (
        <div className="flex-center">
            <form className="loginform">
                <h1 className="loginform__title">Восстановление пароля</h1>
                {(emailDirty && emailError) && <div className="error" style={{color: "red"}}>{emailError}</div>}
                <input onChange={e => emailHandler(e) && handleChangeEmail} value={email} onBlur={e => blurHandler(e)} name="email" type="email" className="loginform__input" placeholder="Почта"/>
                <button className="loginform__button" disabled={!formValid} onClick={handleSubmit}>Восстановить</button>
                </form>

        </div>
    );
};

export default Recovery;
