import React, {useEffect, useState} from 'react';
import axios from "axios";
import '../orderTable.css'
import long from "./img/long.svg";
import short from "./img/short.svg";
import {closeSnackbar, useSnackbar} from "notistack";
import CloseIcon from "@mui/icons-material/Close";

const OrdersList = (props) => {


    (function() {
        function decimalAdjust(type, value, exp) {
            if (typeof exp === 'undefined' || +exp === 0) {
                return Math[type](value);
            }
            value = +value;
            exp = +exp;
            if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
                return NaN;
            }
            value = value.toString().split('e');
            value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
            value = value.toString().split('e');
            return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
        }

        if (!Math.floor10) {
            Math.floor10 = function(value, exp) {
                return decimalAdjust('floor', value, exp);
            };
        }
    })();
    const divider = props.price * props.amount
    const [margin2, setmargin2] = useState([]);
    const [PNL2, setPNL2] = useState([]);
    const [PNL2ft, setPNL2ft] = useState([]);


    let size1, margin1
    let size = props.price * props.amount * props.leverage
    let margin = props.marzha

    const userToken = localStorage.getItem('token');
    // let dateDivided = props.date[2] + props.date[3]  + "."+ props.date[5] + props.date[6] + "." + props.date[8] + props.date[9] + " " + props.date[11] + props.date[12] + props.date[13] + props.date[14] + props.date[15];
    let pnlClose =  props.isLong ? Math.floor10( (margin / 100 * PNL2  * -1), -3) : Math.floor10((margin / 100 * PNL2), -3)


    let leverageDecimal = 100 / props.leverage
    let likvidationProm = props.price / 100 * leverageDecimal
    let likvidationLong = props.price - likvidationProm
    let likvidationShort = props.price + likvidationProm
    return(

        <div className="bin__orders-line">
            <div className={`bin__orders-line-name ${props.isLong ? "long-ord" : "short-ord"}`}>
                <p className="mb0">{props.coin}/{props.coinfrom.toUpperCase()}</p>
                <span>{props.leverage}x</span>
            </div> {/* пара монеты */}

            <div className="story-orders__line-storona">
                <img
                    src={ props.isLong ? long : short}
                    alt=""
                />
                <div className="story-orders__storona-box">
                    <div className="story-orders__storona-value">
                        { props.isLong ? "Лонг" : "Шорт"}
                    </div>
                    {/*<div className="story-orders__storona-data">*/}
                    {/*    {dateDivided}*/}
                    {/*</div>*/}
                </div>
            </div> {/* шорт/лонг */}

            <div className="order-table__children_r bin_razmer-usualy">
                <div className="bin__orders-line-cena bin__orders-line-cena_r">{Math.floor10(size, -5)} USD</div>
            </div> {/* размер */}

            <div className="order-table__children dkjfg">{props.price}</div> {/* цена входа */}

            <div className="order-table__children bin_razmer-usualy">{Math.floor10(props.marzha, -5)} USDT</div> {/* маржа */}

            <div className="order-table__children bin_razmer-usualy">{ props.isLong ? Math.floor10(likvidationLong, -5) : Math.floor10(likvidationShort, -5)}</div >{/* лик-я */}

            <div className={`bin__orders-line-pnl bin_razmer-usualy ${ props.pnl > 0 ? "success" : "error"}`}>
                {Math.floor10((props.pnl), -3)} USDT <br />
                ({Math.floor10((props.pnl_percent), -2)}%)
            </div>

            <div className="order-table__children bin_razmer-unusualy2">{props.takeProfit}</div> {/* TP */}

            <div className="order-table__children bin_razmer-unusualy2">{props.stopLoss}</div> {/* SL */}
        </div>

    );
};

export default OrdersList;
