import React, {useEffect, useState} from 'react';
import q1 from "./img/q1.svg";
import reqwi from "./img/reqwi.png";
import q2 from "./img/q2.svg";
import './css/dpwd.css';
import axios from "axios";
import {closeSnackbar, enqueueSnackbar} from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import {grey, yellow} from '@mui/material/colors';
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {useNavigate} from "react-router";
import QRCode from "react-qr-code";

const primary = yellow[600]; // #f44336
const Deposit = () => {


    const [formValid1, setFormValid1] = useState(false);
    const [formValid2, setFormValid2] = useState(false);

    const token = localStorage.getItem('token');


    (function() {
        function decimalAdjust(type, value, exp) {
            if (typeof exp === 'undefined' || +exp === 0) {
                return Math[type](value);
            }
            value = +value;
            exp = +exp;
            if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
                return NaN;
            }
            value = value.toString().split('e');
            value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
            value = value.toString().split('e');
            return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
        }

        if (!Math.floor10) {
            Math.floor10 = function(value, exp) {
                return decimalAdjust('floor', value, exp);
            };
        }
    })();

    const action = snackbarId => (
        <button onClick={() => { closeSnackbar(snackbarId) }}>
            <CloseIcon/>
        </button>
    );
    const [showBankTransfer, setShowBankTransfer] = useState(true);
    const [showCryptoPayment, setShowCryptoPayment] = useState(true);



    const [idbk, setIdbk] = useState();
    const [idcr, setIdcr] = useState();

    const [hdnpp, setHdnpp] = useState(true)
    const [HdnppBANK, setHdnppBANK] = useState(true)



    const [bankTransferAmount, setBankTransferAmount] = useState('');

    const [bankTransferVar, setBankTransferVar] = useState('');
    const [bankTransferDep, setBankTransferDep] = useState('');
    const [bankTransferREKS, setBankTransferDepREKS] = useState('');

    const [cryptoTransferVar, setCryptoTransferVar] = useState('');
    const [cryptoNet, setCryptoNet] = useState('');
    const [cryptoTransferDep, setCryptoTransferDep] = useState('');
    const [cryptoVar, setCryptoVar] = useState('');


    useEffect(() => {
        if(bankTransferDep > 0) {
            setFormValid1(true)
        }
        else{
            setFormValid1(false)
        }
            }, [bankTransferDep])

    useEffect(() => {
        if(cryptoTransferDep > 0) {
            setFormValid2(true)
        }
        else{
            setFormValid2(false)
        }
            }, [cryptoTransferDep])

    const [qiwiAmount, setQiwiAmount] = useState('');
    const [mirCardAmount, setMirCardAmount] = useState('');
    const [CardAmount4, setCardAmount4] = useState('');
    const [CardAmount5, setCardAmount5] = useState('');
    const [CardAmount6, setCardAmount6] = useState('');
    const [bankCustom, sbc] = useState('');
    const [CryptoAmount4, setCryptoAmount4] = useState('');
    const [CryptoAmount5, setCryptoAmount5] = useState('');

    const [btcAmount, setBtcAmount] = useState('');
    const [ethAmount, setEthAmount] = useState('');
    const [usdtAmount, setUsdtAmount] = useState('');
    const [cursCb, setCursCb] = useState(90);
    const [aviableVarsBank, setAviableVarsBank] = useState([]);
    const [aviableVarsCrypto, setAviableVarsCrypto] = useState([]);

    const url = new URL(document.location.href);
    let bankTransferId = (url.searchParams.get('bankTransferId'));
    let bankAmount = (url.searchParams.get('amount'));


    const checkParams = () => {
        if  (bankTransferId) {
            setIsContinued(true)
            setIdbk(bankTransferId)
            setShowBankTransfer(!showBankTransfer);
            setBankTransferDep(bankAmount)
        }
    }

    useEffect(() => {
        checkParams()
    }, [])

    const cbc2 = () => {
        axios.get(`https://api.fantom-fx.org/api/api/current_coin/ruble`)
            .then(res => {

                setCursCb(res.data)
            })
    }
    const getAviableVarsBank = () => {
        axios.get(`https://api.fantom-fx.org/api/api/bank/avilable`)
            .then(res => {
                setAviableVarsBank(res.data)
            })
    }
    const getAviableVarsCrypto = () => {
        axios.get(`https://api.fantom-fx.org/api/api/crypto/avilable`)
            .then(res => {
                setAviableVarsCrypto(res.data)
            })
    }

    useEffect(() =>{
        cbc2()
        getAviableVarsBank()
        getAviableVarsCrypto()
    }, [])


    const handleBankTransferClick = () => {
        setShowBankTransfer(!showBankTransfer);
    };

    const handleCryptoPaymentClick = () => {
        setShowCryptoPayment(!showCryptoPayment);
        console.log(aviableVarsCrypto)
    };

    function copyWallet(wallet) {
        navigator.clipboard.writeText(wallet)
            .then(() => {
                enqueueSnackbar(`Скопированно в буфер обмена`, {action, 'variant': 'success', 'className': "snacksuccess"});
            })
    }

    const [walletBank, setWalletBank] = useState("-")
    const [walletCrypto, setWalletCrypto] = useState("-")

    const inputSwitcher = (btv) => {
        switch (btv) {
            case `${aviableVarsBank.bankTransferVar1}`:
                setBankTransferDep(bankTransferAmount)
                setWalletBank(aviableVarsBank.bankCardNumber1)
                break
            case `${aviableVarsBank.bankTransferVar2}`:
                setBankTransferDep(qiwiAmount)
                setWalletBank(aviableVarsBank.bankCardNumber2)
                break
            case `${aviableVarsBank.bankTransferVar3}`:
                setBankTransferDep(mirCardAmount)
                setWalletBank(aviableVarsBank.bankCardNumber3)
                break
            case `${aviableVarsBank.bankTransferVar4}`:
                setBankTransferDep(CardAmount4)
                setWalletBank(aviableVarsBank.bankCardNumber4)
                break
            case `${aviableVarsBank.bankTransferVar5}`:
                setBankTransferDep(CardAmount5)
                setWalletBank(aviableVarsBank.bankCardNumber5)
                break
            case "другой банк":
                setBankTransferDep(CardAmount6)
                setWalletBank("другой банк")
                break
        }
    }
    const inputSwitcherCrypto = (btv) => {
        switch (btv) {
            case `${aviableVarsCrypto.cryptoTransferVar1}`:
                setCryptoTransferDep(btcAmount)
                setWalletCrypto(aviableVarsCrypto.cryptoCardNumber1)
                break
            case `${aviableVarsCrypto.cryptoTransferVar2}`:
                setCryptoTransferDep(ethAmount)
                setWalletCrypto(aviableVarsCrypto.cryptoCardNumber2)
                break
            case `${aviableVarsCrypto.cryptoTransferVar3}`:
                setCryptoTransferDep(usdtAmount)
                setWalletCrypto(aviableVarsCrypto.cryptoCardNumber3)
                break
            case `${aviableVarsCrypto.cryptoTransferVar4}`:
                setCryptoTransferDep(CryptoAmount4)
                setWalletCrypto(aviableVarsCrypto.cryptoCardNumber4)
                break
            case `${aviableVarsCrypto.cryptoTransferVar5}`:
                setCryptoTransferDep(CryptoAmount5)
                setWalletCrypto(aviableVarsCrypto.cryptoCardNumber5)
                break
        }
    }

    useEffect(() => {
        inputSwitcher(bankTransferVar)
    }, )

    useEffect(() => {
        inputSwitcherCrypto(cryptoTransferVar)
    }, )



    let navigate = useNavigate();

    const handleBankStatus = () => {
        axios.get(`https://api.fantom-fx.org/api/api/refill/bank/status?id=${idbk}`)
            .then(res => {
                if (res.data == "accepted"){
                    navigate(`../account/`, { replace: true });
                }
                else if (res.data == "waiting"){
                    setTimeout(() => handleBankStatus(), 2000)
                }
            })
    }

    useEffect(() => {
        handleBankStatus()
    }, [HdnppBANK])

const [walletbk, setwalletbk] = useState("")
    const handleBankWallet = () => {
        axios.get(`https://api.fantom-fx.org/api/api/refill/bank/details/status?id=${idbk}`)
            .then(res => {
                if (res.data.accepted == true){
                    setIsContinuedSS(true)
                    setwalletbk(res.data.wallet)
                    setBankTransferDepREKS(res.data)

                }
                else if (res.data.accepted == false){
                    setTimeout(() => handleBankWallet(), 2000)
                }
            })
    }

    const handleCryptoStatus = () => {
        axios.get(`https://api.fantom-fx.org/api/api/refill/crypto/status?id=${idcr}`)
            .then(res => {
                if (res.data == "accepted"){
                    navigate(`../account/`, { replace: true });
                }
                else if (res.data == "waiting"){
                    setTimeout(() => handleCryptoStatus(), 2000)
                }
            })
    }
    useEffect(() => {
        handleCryptoStatus()
    }, [idcr])
    useEffect(() => {
        handleBankWallet()
    }, [idbk])

    const handleBankPayment = () => {
        axios.post(`https://api.fantom-fx.org/api/api/users/balance/refill/bank`,
            {
                "value": bankTransferDep,
                "card_type": bankTransferVar,
                "card_type_custom": bankCustom,
                "owner_name": "string",
                "owner_details": "string",
                "coin": "USDT",
                "coin_to": "USDT",
                "balance_type": "main",
                "value_rubles": Math.floor10(bankTransferDep * (cursCb * 1.04))
            },
            {
                headers: {"Authorization" : `Bearer ${token}`}
            })
            .then(res => {
                enqueueSnackbar(`Заявка отправлена`, {action, 'variant': 'success', 'className': "snacksuccess"});
                setIdbk(res.data)
                navigate(`../account/deposit?bankTransferId=${res.data}&amount=${bankTransferDep}`, { replace: true });
            })
            .catch( error => {
                error.response.data.detail.map(
                    errormsg => {
                        enqueueSnackbar(`Возникла ошибка: ${errormsg.msg}`, {action, 'variant': 'error', 'className': "snackerror"});
                    }
                )


            });
    }

    const handeBankConfirming = () => {
        setHdnppBANK(false)
        setHdnpp(false)
    }


    const handleCryptoPayment = () => {
        axios.post(`https://api.fantom-fx.org/api/api/users/balance/refill/crypto`,{
                "balance_type": "main",
                "value": cryptoTransferDep,
                "coin_to": cryptoTransferVar,
                "coin_from": cryptoTransferVar,
                "owner_details": "string"
            },
            {
                headers: {"Authorization" : `Bearer ${token}`}
            })
            .then(res => {
                enqueueSnackbar(`Заявка отправлена`, {action, 'variant': 'success', 'className': "snacksuccess"});
                setHdnpp(false)
                setIdcr(res.data)
            })
            .catch( error => {
                error.response.data.detail.map(
                    errormsg => {
                        enqueueSnackbar(`Возникла ошибка: ${errormsg.msg}`, {action, 'variant': 'error', 'className': "snackerror"});
                    }
                )


            });
    }

    const theme = createTheme({
        palette: {
            primary: {
                main: yellow[600],
            },
        },
    });
    const theme2 = createTheme({
        palette: {
            primary: {
                main: grey[900],
            },
        },
    });

    const [isContinued, setIsContinued] = useState(false)
    const [isContinuedSecondStep, setIsContinuedSS] = useState(false)
    const handleContinue = () => {
        setIsContinued(true)
        handleBankPayment()
    };
    return (
        
        <div className="tabs__body">

            <div className={`deposit-popup-window ${hdnpp ? "hidden" : ""}`} onClick={() => setHdnpp(true)}>
                    <div className="deposit-popup" >
                        <p className="dp-pp-text">Ожидание платежа</p>
                        <ThemeProvider theme={theme}>
                            <CircularProgress />
                        </ThemeProvider>

                    </div>

            </div>
            <div className="account__box">
                <div className="deposits__body">
                    <div className="account__balans">Пополнения</div>
                    <div className="deposits__wrap">



                        <div className="deposits__bar">
                            <div className={`deposits__q1 `} onClick={handleBankTransferClick}>
                                <img src={q1} alt="" />
                                <span>Банковский перевод</span>
                            </div>
                            <div className={`dep2  ${showBankTransfer ? "hidden" : ""}`}>
                                <div className={`deposits__oplata ${isContinuedSecondStep ? "hidden" : ""}`}>
                                    <div className={`deposits__oplata-line ${aviableVarsBank.bankEnabled1 ? "" : "hidden"}`}>
                                        <input
                                            id="c_12"
                                            data-error="Ошибка"
                                            className="check-with-label checkbox__input"
                                            type="radio"
                                            defaultValue={1}
                                            onClick={() => setBankTransferVar(aviableVarsBank.bankTransferVar1)}
                                            name="form[]"
                                        />
                                        <label
                                            htmlFor="c_12"
                                            className="checkbox__label label-for-check"
                                        >
                                            <span className="checkbox__text">{aviableVarsBank.bankTransferVar1}</span>
                                        </label>
                                        <input
                                            type="text"
                                            className={`deposits__summa ${bankTransferVar == aviableVarsBank.bankTransferVar1 ? "" : "hidden"}`}
                                            placeholder="Сумма пополнения в $"
                                            onKeyPress={(event) => {
                                                if (!/[0-9.]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            value={bankTransferAmount}
                                            onChange={(e) => setBankTransferAmount(e.target.value)}
                                        />
                                    </div>
                                    <div className={`deposits__oplata-line ${aviableVarsBank.bankEnabled2 ? "" : "hidden"}`}>
                                        <input
                                            id="c_122"
                                            data-error="Ошибка"
                                            className="check-with-label checkbox__input"
                                            type="radio"
                                            defaultValue={1}
                                            onClick={() => setBankTransferVar(aviableVarsBank.bankTransferVar2)}
                                            name="form[]"
                                        />
                                        <label
                                            htmlFor="c_122"
                                            className="checkbox__label label-for-check"
                                        >
                                            <span className="checkbox__text">{aviableVarsBank.bankTransferVar2}</span>
                                        </label>
                                        <input
                                            type="text"
                                            className={`deposits__summa ${bankTransferVar == aviableVarsBank.bankTransferVar2 ? "" : "hidden"}`}
                                            placeholder="Сумма пополнения в $"
                                            onKeyPress={(event) => {
                                                if (!/[0-9.]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            value={qiwiAmount}
                                            onChange={(e) => setQiwiAmount(e.target.value)}
                                        />
                                    </div>
                                    <div className={`deposits__oplata-line ${aviableVarsBank.bankEnabled3 ? "" : "hidden"}`}>
                                        <input
                                            id="c_123"
                                            data-error="Ошибка"
                                            className="check-with-label checkbox__input"
                                            type="radio"
                                            defaultValue={1}
                                            onClick={() => setBankTransferVar(aviableVarsBank.bankTransferVar3)}
                                            name="form[]"
                                        />
                                        <label
                                            htmlFor="c_123"
                                            className="checkbox__label label-for-check"
                                        >
                                            <span className="checkbox__text">{aviableVarsBank.bankTransferVar3}</span>
                                        </label>
                                        <input
                                            type="text"
                                            className={`deposits__summa ${bankTransferVar == aviableVarsBank.bankTransferVar3 ? "" : "hidden"}`}
                                            placeholder="Сумма пополнения в $"
                                            onKeyPress={(event) => {
                                                if (!/[0-9.]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            value={mirCardAmount}
                                            onChange={(e) => setMirCardAmount(e.target.value)}
                                        />
                                    </div>
                                    <div className={`deposits__oplata-line ${aviableVarsBank.bankEnabled4 ? "" : "hidden"}`}>
                                        <input
                                            id="c_1244"
                                            data-error="Ошибка"
                                            className="check-with-label checkbox__input"
                                            type="radio"
                                            defaultValue={1}
                                            onClick={() => setBankTransferVar(aviableVarsBank.bankTransferVar4)}
                                            name="form[]"
                                        />
                                        <label
                                            htmlFor="c_1244"
                                            className="checkbox__label label-for-check"
                                        >
                                            <span className="checkbox__text">{aviableVarsBank.bankTransferVar4}</span>
                                        </label>
                                        <input
                                            type="text"
                                            className={`deposits__summa ${bankTransferVar == aviableVarsBank.bankTransferVar4 ? "" : "hidden"}`}
                                            placeholder="Сумма пополнения в $"
                                            onKeyPress={(event) => {
                                                if (!/[0-9.]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            value={CardAmount4}
                                            onChange={(e) => setCardAmount4(e.target.value)}
                                        />
                                    </div>
                                    <div className={`deposits__oplata-line ${aviableVarsBank.bankEnabled5 ? "" : "hidden"}`}>
                                        <input
                                            id="c_1255"
                                            data-error="Ошибка"
                                            className="check-with-label checkbox__input"
                                            type="radio"
                                            defaultValue={1}
                                            onClick={() => setBankTransferVar(aviableVarsBank.bankTransferVar5)}
                                            name="form[]"
                                        />
                                        <label
                                            htmlFor="c_1255"
                                            className="checkbox__label label-for-check"
                                        >
                                            <span className="checkbox__text">{aviableVarsBank.bankTransferVar5}</span>
                                        </label>
                                        <input
                                            type="text"
                                            className={`deposits__summa ${bankTransferVar == aviableVarsBank.bankTransferVar5 ? "" : "hidden"}`}
                                            placeholder="Сумма пополнения в $"
                                            onKeyPress={(event) => {
                                                if (!/[0-9.]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            value={CardAmount5}
                                            onChange={(e) => setCardAmount5(e.target.value)}
                                        />
                                    </div>
                                    <div className={`deposits__oplata-line`}>
                                        <input
                                            id="c_12565"
                                            data-error="Ошибка"
                                            className="check-with-label checkbox__input"
                                            type="radio"
                                            defaultValue={1}
                                            onClick={() => setBankTransferVar("другой банк")}
                                            name="form[]"
                                        />
                                        <label
                                            htmlFor="c_12565"
                                            className="checkbox__label label-for-check"
                                        >
                                            <span className="checkbox__text2">Другое</span>
                                        </label>
                                        <input
                                            type="text"
                                            className={`deposits__summa-custom ${bankTransferVar == "другой банк" ? "" : "hidden"}`}
                                            placeholder="Название банка"
                                            value={bankCustom}
                                            onChange={(e) => sbc(e.target.value)}
                                        />
                                        <input
                                            type="text"
                                            className={`deposits__summa-custom ${bankTransferVar == "другой банк" ? "" : "hidden"}`}
                                            placeholder="Сумма пополнения в $"
                                            onKeyPress={(event) => {
                                                if (!/[0-9.]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            value={CardAmount6}
                                            onChange={(e) => setCardAmount6(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <button className={`deposits__btn ${isContinued ? "hidden" : ""} ${isContinuedSecondStep ? "hidden" : ""}`} id="continue1" disabled={!formValid1}  onClick={handleContinue}>продолжить {bankTransferDep ? `( ${Math.floor10(bankTransferDep * (cursCb * 1.04))}₽ )` : ""}</button>
                                <div className={`deposits__btn ${isContinued ? "" : "hidden"} ${isContinuedSecondStep ? "hidden" : ""}`}>
                                    <div className="mr10">Не перезагружайте страницу</div>
                                    <ThemeProvider theme={theme2}>
                                        <CircularProgress className={"sdkjfksdjfkn"} sx={{width: 5, height: 5}} />
                                    </ThemeProvider>
                                </div>
                                <div className={`deposits__reqwi ${isContinuedSecondStep ? "" : "hidden"}`}>
                                    <div className="deposits__reqwi-title">Реквизиты пополнения</div>

                                    <QRCode value={`${walletbk}`}  className="qrcode" />
                                    <div className="deposits__text">
                                        Будьте внимательны при вводе реквизитов. Отправленные средства на другие реквизиты возврату не подлежат.
                                    </div>
                                    <div className="deposits__lion">Cумма к оплате: {Math.floor10(bankTransferDep * (cursCb * 1.04))} RUB</div>
                                    <div className="deposits__lion">Банк: {bankTransferREKS.bank}</div>
                                    <div className="deposits__lion">ФИО: {bankTransferREKS.name}</div>
                                    <div className="deposits__lion">комментарий: {bankTransferREKS.comment}</div>
                                    <div className="deposits__lion2">
                                        <p>{walletbk}</p>
                                        <button type="button" onClick={() => copyWallet(walletbk)} className="deposits__copy">
                                            Копировать
                                        </button>
                                    </div>
                                </div>
                                <div className={`deposits__btn ${isContinuedSecondStep ? "" : "hidden"}`} onClick={handeBankConfirming}>Пополнить</div>
                            </div>
                        </div>










                        <div className="deposits__bar">
                            <div className="deposits__q1" onClick={handleCryptoPaymentClick}>
                                <img src={q2} alt="" />
                                <span>Оплата криптовалютой</span>
                            </div>
                            <div className={`dep2  ${showCryptoPayment ? "hidden" : ""}`}>
                                <div className="deposits__oplata">
                                    <div className={`deposits__oplata-line ${aviableVarsCrypto.cryptoEnabled1 ? "" : "hidden"}`}>
                                        <input
                                            id="c_124"
                                            data-error="Ошибка"
                                            className="check-with-label checkbox__input"
                                            type="radio"
                                            defaultValue={1}
                                            onClick={() => {
                                                setCryptoTransferVar(aviableVarsCrypto.cryptoTransferVar1)
                                                setCryptoNet(aviableVarsCrypto.cryptoNet1)
                                            }}
                                            name="form[]"
                                        />
                                        <label
                                            htmlFor="c_124"
                                            className="checkbox__label label-for-check"
                                        >
                                            <span className="checkbox__text">{aviableVarsCrypto.cryptoTransferVar1}<span style={{fontSize: ".8rem", background: "#f0b90b", borderRadius: 3, color: "#000", padding: "0px 5px", marginLeft: 7}}>{aviableVarsCrypto.cryptoNet1}</span></span>
                                        </label>
                                        <input
                                            type="text"
                                            className={`deposits__summa ${cryptoTransferVar == aviableVarsCrypto.cryptoTransferVar1 ? "" : "hidden"}`}
                                            placeholder="Сумма пополнения"
                                            onKeyPress={(event) => {
                                                if (!/[0-9.]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            value={btcAmount}
                                            onChange={(e) => setBtcAmount(e.target.value)}
                                        />
                                    </div>
                                    <div className={`deposits__oplata-line ${aviableVarsCrypto.cryptoEnabled2 ? "" : "hidden"}`}>
                                        <input
                                            id="c_125"
                                            data-error="Ошибка"
                                            className="check-with-label checkbox__input"
                                            type="radio"
                                            defaultValue={1}
                                            onClick={() => {
                                                setCryptoTransferVar(aviableVarsCrypto.cryptoTransferVar2)
                                                setCryptoNet(aviableVarsCrypto.cryptoNet2)
                                            }}
                                            name="form[]"
                                        />
                                        <label
                                            htmlFor="c_125"
                                            className="checkbox__label label-for-check"
                                        >
                                            <span className="checkbox__text">{aviableVarsCrypto.cryptoTransferVar2}<span style={{fontSize: ".8rem", background: "#f0b90b", borderRadius: 3, color: "#000", padding: "0px 5px", marginLeft: 7}}>{aviableVarsCrypto.cryptoNet2}</span></span>
                                        </label>
                                        <input
                                            type="text"
                                            className={`deposits__summa ${cryptoTransferVar == aviableVarsCrypto.cryptoTransferVar2 ? "" : "hidden"}`}
                                            placeholder="Сумма пополнения"
                                            onKeyPress={(event) => {
                                                if (!/[0-9.]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            value={ethAmount}
                                            onChange={(e) => setEthAmount(e.target.value)}
                                        />
                                    </div>
                                    <div className={`deposits__oplata-line ${aviableVarsCrypto.cryptoEnabled3 ? "" : "hidden"}`}>
                                        <input
                                            id="c_126"
                                            data-error="Ошибка"
                                            className="check-with-label checkbox__input"
                                            type="radio"
                                            defaultValue={1}
                                            onClick={() => {
                                                setCryptoTransferVar(aviableVarsCrypto.cryptoTransferVar3)
                                                setCryptoNet(aviableVarsCrypto.cryptoNet3)
                                            }}
                                            name="form[]"
                                        />
                                        <label
                                            htmlFor="c_126"
                                            className="checkbox__label label-for-check"
                                        >
                                            <span className="checkbox__text">{aviableVarsCrypto.cryptoTransferVar3}<span style={{fontSize: ".8rem", background: "#f0b90b", borderRadius: 3, color: "#000", padding: "0px 5px", marginLeft: 7}}>{aviableVarsCrypto.cryptoNet3}</span></span>
                                        </label>
                                        <input
                                            type="text"
                                            className={`deposits__summa ${cryptoTransferVar == aviableVarsCrypto.cryptoTransferVar3 ? "" : "hidden"}`}
                                            placeholder="Сумма пополнения"
                                            onKeyPress={(event) => {
                                                if (!/[0-9.]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            value={usdtAmount}
                                            onChange={(e) => setUsdtAmount(e.target.value)}
                                        />
                                    </div>
                                    <div className={`deposits__oplata-line ${aviableVarsCrypto.cryptoEnabled4 ? "" : "hidden"}`}>
                                        <input
                                            id="c_1265"
                                            data-error="Ошибка"
                                            className="check-with-label checkbox__input"
                                            type="radio"
                                            defaultValue={1}
                                            onClick={() => {
                                                setCryptoTransferVar(aviableVarsCrypto.cryptoTransferVar4)
                                                setCryptoNet(aviableVarsCrypto.cryptoNet4)
                                            }}
                                            name="form[]"
                                        />
                                        <label
                                            htmlFor="c_1265"
                                            className="checkbox__label label-for-check"
                                        >
                                            <span className="checkbox__text">{aviableVarsCrypto.cryptoTransferVar4}<span style={{fontSize: ".8rem", background: "#f0b90b", borderRadius: 3, color: "#000", padding: "0px 5px", marginLeft: 7}}>{aviableVarsCrypto.cryptoNet4}</span></span>
                                        </label>
                                        <input
                                            type="text"
                                            className={`deposits__summa ${cryptoTransferVar == aviableVarsCrypto.cryptoTransferVar4 ? "" : "hidden"}`}
                                            placeholder="Сумма пополнения"
                                            onKeyPress={(event) => {
                                                if (!/[0-9.]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            value={CryptoAmount4}
                                            onChange={(e) => setCryptoAmount4(e.target.value)}
                                        />
                                    </div>
                                    <div className={`deposits__oplata-line ${aviableVarsCrypto.cryptoEnabled5 ? "" : "hidden"}`}>
                                        <input
                                            id="c_1266"
                                            data-error="Ошибка"
                                            className="check-with-label checkbox__input"
                                            type="radio"
                                            defaultValue={1}
                                            onClick={() => {
                                                setCryptoTransferVar(aviableVarsCrypto.cryptoTransferVar5)
                                                setCryptoNet(aviableVarsCrypto.cryptoNet5)
                                            }}

                                            name="form[]"
                                        />
                                        <label
                                            htmlFor="c_1266"
                                            className="checkbox__label label-for-check"
                                        >
                                            <span className="checkbox__text">{aviableVarsCrypto.cryptoTransferVar5}<span style={{fontSize: ".8rem", background: "#f0b90b", borderRadius: 3, color: "#000", padding: "0px 5px"}}>{aviableVarsCrypto.cryptoNet5}</span></span>
                                        </label>
                                        <input
                                            type="text"
                                            className={`deposits__summa ${cryptoTransferVar == aviableVarsCrypto.cryptoTransferVar5 ? "" : "hidden"}`}
                                            placeholder="Сумма пополнения"
                                            onKeyPress={(event) => {
                                                if (!/[0-9.]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            value={CryptoAmount5}
                                            onChange={(e) => setCryptoAmount5(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="deposits__reqwi">
                                    <div className="deposits__reqwi-title">Адрес пополнения</div>
                                    <QRCode value={`${walletCrypto}`}  className="qrcode" />
                                    <div className="deposits__text">
                                        Будьте внимательны при вводе реквизитов. Отправленные средства на другие реквизиты возврату не подлежат.
                                    </div>
                                    <div className="deposits__lion">Cумма к оплате: {cryptoTransferDep} {cryptoTransferVar} <span style={{fontSize: ".8rem", background: "#f0b90b", borderRadius: 3, color: "#000", padding: "0px 5px"}}>{cryptoNet}</span></div>
                                    <div className="deposits__lion2">
                                        <p>{walletCrypto}</p>
                                        <button type="button" onClick={() => copyWallet(walletCrypto)} className="deposits__copy">
                                            Копировать
                                        </button>
                                    </div>
                                </div>
                                <button className="deposits__btn" onClick={handleCryptoPayment}  disabled={!formValid2}  >Пополнить</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Deposit;
