import React from 'react';
import './comps.css'
import Bar from "./bar";
const About = () => {
    return (
        <div className="comps__body">
            <Bar active={1}/>
            <div className="comps__content">
                <p>
                    <span style={{ fontSize: 20, color: "#f1c40f" }}>О нас</span>
                </p>
                <p>
                    <strong>
                        Добро пожаловать в <span style={{ color: "#f1c40f" }}>FantomFX</span>!
                    </strong>
                </p>
                <p>
                    <br />
                    <span style={{ fontSize: 14 }}>
      Мы - команда энтузиастов и профессионалов, цель которых заключается в
      предоставлении современной и надежной платформы для торговли
      криптовалютами. Наша миссия - сделать мир криптовалют доступным и удобным
      для всех, независимо от опыта и технических знаний.
    </span>
                </p>
                <p>
                    <br />
                    <span style={{ fontSize: 14 }}>
      <strong>Наши ценности:</strong>
      <br />
      1. Инновации: Мы ставим перед собой задачу быть на переднем крае
      технологических инноваций. Мир криптовалют постоянно меняется, и мы
      стремимся предоставлять передовые инструменты и функции, чтобы наши
      пользователи могли максимально эффективно участвовать в этом динамичном
      пространстве.
      <br />
      2. Безопасность: Безопасность наших пользователей - наш приоритет. Мы
      применяем самые современные методы шифрования и защиты данных, чтобы
      обеспечить безопасность Вашей личной информации и средств.
      <br />
      3. Прозрачность: Мы ценим открытость и прозрачность во всем, что мы
      делаем. На нашей платформе Вы всегда будете в курсе того, как работают
      наши функции и как мы обрабатываем информацию.
      <br />
      4. Пользовательский опыт: Мы стремимся к тому, чтобы наша платформа была
      интуитивно понятной и легкой в использовании для всех пользователей,
      независимо от их уровня опыта.
    </span>
                </p>
                <p>
                    <br />
                    <span style={{ fontSize: 14 }}>
      <strong>Что мы предлагаем:</strong>
      <br />
      Широкий выбор активов: На нашей платформе Вы найдете множество
      криптовалютных активов для торговли, позволяя Вам диверсифицировать свой
      портфель.
      <br />
      Мгновенные транзакции: Мы стремимся к тому, чтобы ваши транзакции были
      выполнены мгновенно, обеспечивая высокую скорость и эффективность.
      <br />
      Образовательные ресурсы: Мы предоставляем обширные образовательные
      материалы и ресурсы, чтобы помочь вам разобраться в мире криптовалют и
      торговли.
      <br />
      Круглосуточная поддержка: Наша команда поддержки всегда готова помочь Вам
      с любыми вопросами или проблемами, которые могут возникнуть.
    </span>
                </p>
                <p>
    <span style={{ fontSize: 14 }}>
      Мы строим <span style={{ color: "#f1c40f" }}>FantomFX</span> как место,
      где опытные трейдеры и новички могут находиться в комфортной и
      инновационной среде для осуществления своих криптовалютных операций.{" "}
    </span>
                </p>
                <p>
    <span style={{ fontSize: 14 }}>
      Спасибо, что выбрали нас, и добро пожаловать в мир криптовалютной торговли
      с{" "}
    </span>
                    <span style={{ color: "#f1c40f" }}>FantomFX</span>
                    <span style={{ fontSize: 14 }}>!</span>
                </p>
            </div>
        </div>
    );
};

export default About;