import React from 'react';
import './comps.css'
import Bar from "./bar";
const Privacy = () => {
    return (
        <div className="comps__body">
            <Bar active={3}/>

            <div className="comps__content">
                <p>УСЛОВИЯ ИСПОЛЬЗОВАНИЯ</p>
                <p>
                    <br />
                </p>
                <p>Последнее обновление: 31 августа 2023 г.</p>
                <p>
                    <br />
                </p>
                <p>
                    Настоящие Условия представляют собой юридически обязывающее соглашение между
                    вами («вы», «ваш») и FANTOM FX («FANTOM FX», «мы», «наш», «нам»). Настоящие
                    Условия регулируют использование вами Услуг FANTOM FX, которые доступны вам
                    на Платформе или иным образом. Услуги FANTOM FX могут предоставляться FANTOM
                    FX или, если это указано в настоящих Условиях, каких-либо Условиях продукта
                    или каких-либо дополнительных условиях, любым Партнером FANTOM FX.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    Регистрируя Аккаунт FANTOM FX, получая доступ к Платформе и/или используя
                    Услуги FANTOM FX, вы подтверждаете, что прочитали, поняли и приняли
                    настоящие Условия, а также любые дополнительные документы или условия,
                    упомянутые в настоящих Условиях. Вы подтверждаете и принимаете, что
                    обязуетесь соблюдать настоящие Условия, которые периодически будут
                    обновляться и изменяться.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    Если вы не понимаете и не принимаете настоящие Условия в полном объеме, вам
                    не следует регистрировать Аккаунт FANTOM FX, получать доступ к Платформе или
                    использовать любые Услуги FANTOM FX.
                </p>
                <p>
                    <br />
                </p>
                <p>Предупреждение о риске</p>
                <p>
                    <br />
                </p>
                <p>
                    Стоимость Цифровых активов может значительно измениться, что приведет к
                    риску экономических потерь, если вы покупаете, продаете, держите или
                    инвестируете в Цифровые активы. Поэтому вам необходимо оценить, подходит ли
                    торговля или владение Цифровыми активами вам с учетом вашего финансового
                    состояния.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    Вы должны полностью понимать все риски, прежде чем принять решение об
                    использовании Услуг FANTOM FX.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    Мы не являемся вашим брокером, посредником, агентом или консультантом, и у
                    нас нет фидуциарных отношений или обязательств перед вами в связи с любыми
                    Транзакциями или другими действиями, которые вы совершаете при использовании
                    Услуг FANTOM FX. Мы не предоставляем инвестиционных или консультационных
                    советов любого рода, и никакая информация или сообщения, которые мы
                    предоставляем вам, не представляют собой и не должны рассматриваться как
                    советы любого рода.&nbsp;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    Вы единолично определяете, соответствуют ли какие-либо инвестиции,
                    инвестиционные стратегии или связанные с ними транзакции вашим личным
                    инвестиционным целям, финансовому положению и допустимому риску, и вы несете
                    ответственность за любые связанные с этим убытки или обязательства. Мы не
                    рекомендуем вам покупать, зарабатывать, продавать или удерживать какой-либо
                    Цифровой актив. Перед принятием решения о покупке, продаже или удержании
                    любого Цифрового актива вы должны провести собственное исследование и
                    проконсультироваться с финансовым специалистом. Мы не несем ответственности
                    за ваши решения о покупке, заработке, продаже или удержании Цифровых активов
                    на основе предоставленной нами информации, включая любые убытки, которые вы
                    понесли в результате таких решений.
                </p>
                <p>
                    <br />
                </p>
                <p>ИНФОРМАЦИЯ О НАШЕМ СОГЛАШЕНИИ С ВАМИ</p>
                <p>
                    <br />
                </p>
                <p>1. &nbsp; &nbsp;Введение</p>
                <p>
                    <br />
                </p>
                <p>
                    1.1. &nbsp; &nbsp;О нас. Группа FANTOM FX — это экосистема, в центре которой
                    находится онлайн-биржа для торговли Цифровыми активами. Группа FANTOM FX
                    предоставляет пользователям торговую платформу для покупки и продажи
                    Цифровых активов, интегрированное кастодиальное решение, позволяющее
                    пользователям хранить свои Цифровые активы, и другие услуги, связанные с
                    Цифровыми активами.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    1.2. &nbsp; &nbsp;Настоящие Условия. Регистрируясь для создания Аккаунта
                    FANTOM FX, вы заключаете с нами юридически обязывающее соглашение. Настоящие
                    Условия будут регулировать использование вами Услуг FANTOM FX и расскажут
                    вам, кто мы, как мы будем предоставлять вам Услуги FANTOM FX, как эти
                    Условия могут быть изменены или прекращены, что делать в случае
                    возникновения проблем, а также другую важную информацию.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    Вы должны внимательно прочитать настоящие Условия, а также документы,
                    упомянутые в Условиях, и сообщить нам, если вам что-то непонятно.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    Если к использованию вами Услуг FANTOM FX применяются какие-либо Местные
                    условия, такие Местные условия имеют преимущественную силу над настоящими
                    Условиями.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    1.3. &nbsp; &nbsp;Дополнительные документы. Настоящие Условия ссылаются на
                    ряд дополнительных документов, которые также применяются к использованию
                    вами Услуг FANTOM FX. К ним относятся:
                </p>
                <p>
                    <br />
                </p>
                <p>
                    Пользуясь Услугами FANTOM FX, вы даете согласие на обработку персональных
                    данных и гарантируете, что все предоставляемые вами данные достоверные и
                    актуальные.&nbsp;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    b. &nbsp; &nbsp;Наше Предупреждение о рисках, излагающее важную информацию о
                    рисках, которые могут возникнуть при покупке, продаже, удержании или
                    инвестициях в Цифровые активы.&nbsp;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    d. &nbsp; &nbsp;Условия продукта, излагающие дополнительные положения и
                    условия, которые будут применяться к использованию вами конкретных Услуг
                    FANTOM FX.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    Вы принимаете, что обязуетесь соблюдать любые соответствующие дополнительные
                    условия и положения, которые применяются к использованию вами Услуг FANTOM
                    FX.
                </p>
                <p>
                    <br />
                </p>
                <p>2. &nbsp; &nbsp;Доступность</p>
                <p>
                    <br />
                </p>
                <p>
                    2.1. &nbsp; &nbsp;Критерии доступности. Чтобы иметь право зарегистрировать
                    Аккаунт FANTOM FX и использовать Услуги FANTOM FX, вы должны соблюдать
                    следующие критерии:
                </p>
                <p>
                    <br />
                </p>
                <p>
                    a. &nbsp; &nbsp;быть физическим лицом, корпорацией, юридическим лицом,
                    компанией или иной организацией, у которой есть все полномочия, права и
                    возможности для (1) доступа и использования Услуг FANTOM FX; и (2)
                    заключения и выполнения своих обязательств по настоящим Условиям;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    b. &nbsp; &nbsp;если вы являетесь физическим лицом, быть не моложе 18 лет;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    c. &nbsp; &nbsp;если вы действуете как сотрудник или агент юридического лица
                    и заключаете настоящие Условия от его имени, вы должны быть должным образом
                    уполномочены действовать от имени такого юридического лица и связывать его
                    обязательствами в целях заключения настоящих Условий;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    d. &nbsp; &nbsp;вам ранее не приостанавливали или не прекращали
                    предоставление Услуг FANTOM FX;
                </p>
                <p>
                    <br />
                </p>
                <p>e. &nbsp; &nbsp;вы не являетесь Лицом с ограниченным доступом;&nbsp;</p>
                <p>
                    <br />
                </p>
                <p>f. &nbsp; &nbsp;у вас нет действующего Аккаунта FANTOM FX;&nbsp;</p>
                <p>
                    <br />
                </p>
                <p>
                    g. &nbsp; &nbsp;вы не расположены, зарегистрированы или иным образом
                    учреждены в стране, не являетесь гражданином или резидентом страны, не
                    ведете коммерческую деятельность в стране, которая является:
                </p>
                <p>
                    <br />
                </p>
                <p>
                    i. &nbsp; &nbsp;юрисдикцией, в которой, согласно Применимому
                    законодательству, доступ или использование вами Услуг FANTOM FX является
                    незаконным или приведет к нарушению нами или любой третьей стороной любого
                    Применимого законодательства; или
                </p>
                <p>
                    <br />
                </p>
                <p>
                    2.2. &nbsp; &nbsp;Изменение наших критериев доступности. Мы можем изменить
                    наши критерии доступности в любое время по своему усмотрению. По возможности
                    мы заранее уведомим вас об изменении. Однако иногда нам может потребоваться
                    внести изменения без предварительного уведомления. Это может произойти в
                    следующих случаях:
                </p>
                <p>
                    <br />
                </p>
                <p>
                    a. &nbsp; &nbsp;мы вносим изменения в результате законодательных и/или
                    нормативных изменений;
                </p>
                <p>
                    <br />
                </p>
                <p>b. &nbsp; &nbsp;вносимые изменения отвечают вашим интересам; и/или</p>
                <p>
                    <br />
                </p>
                <p>
                    c. &nbsp; &nbsp;существует любая другая уважительная причина, в рамках
                    которой у нас нет времени на уведомление.&nbsp;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    Если мы не можем уведомить вас заранее, мы сообщим вам об изменениях как
                    можно скорее после их внесения.
                </p>
                <p>
                    <br />
                </p>
                <p>3. &nbsp; &nbsp;Способы связи</p>
                <p>
                    <br />
                </p>
                <p>
                    3.1. &nbsp; &nbsp;Как вы можете связаться с нами. Для получения
                    дополнительной информации о FANTOM FX вы можете ознакомиться с информацией
                    на нашем Сайте. Если у вас есть вопросы, обратная связь или жалобы, вы
                    можете связаться с нами через нашу Службу поддержки по ссылке{" "}
                    <a data-fr-linked="true" href="https://fantom-fx.org/contacts">
                        https://fantom-fx.org/contacts
                    </a>
                    . В настоящих Условиях могут быть предоставлены контактные данные для
                    отдельных видов сообщений. Мы не принимаем такие сообщения по выше
                    указанному адресу.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    3.2. &nbsp; &nbsp;Как мы будем связываться с вами. Мы будем связываться с
                    вами, используя предоставленные вами данные. Способы связи могут включать
                    электронную почту, SMS или телефон. Убедитесь, что указали верные и
                    действующие контактные данные. Если ваши контактные данные изменились, вы
                    должны немедленно сообщить нам об этом. Если вы этого не сделаете, мы не
                    будем нести ответственность, если вы не получите от нас информацию,
                    уведомления или другую важную информацию.&nbsp;
                </p>
                <p>
                    <br />
                </p>
                <p>4. &nbsp; &nbsp;Услуги FANTOM FX</p>
                <p>
                    <br />
                </p>
                <p>
                    4.1. &nbsp; &nbsp;Условия конкретного продукта. После создания Аккаунта
                    FANTOM FX вы сможете пользоваться Услугами FANTOM FX в соответствии с
                    настоящими Условиями и Условиями продукта, которые регулируют использование
                    вами каждой конкретной Услуги FANTOM FX.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    4.2. &nbsp; &nbsp;Внутригрупповые услуги. Вы подтверждаете и принимаете, что
                    некоторые Услуги FANTOM FX могут предоставляться Партнерами FANTOM FX.&nbsp;
                </p>
                <p>
                    <br />
                </p>
                <p>5. &nbsp; &nbsp;Услуга чата</p>
                <p>
                    <br />
                </p>
                <p>
                    5.1. &nbsp; &nbsp;Доступность Услуги чата. Мы можем в любое время сделать
                    доступной для вас нашу интерактивную услугу онлайн-чата («Услуга чата») в
                    связи с использованием вами любой из Услуг FANTOM FX. Используя Услугу чата,
                    вы можете взаимодействовать с ботом, чат-ботом или иной функцией, не
                    являющейся человеком. Мы будем раскрывать информацию об использовании
                    чат-бота или иной функции, не являющейся человеком, в той степени, в которой
                    это требуется в соответствии с Применимым законодательством. Взаимодействуя
                    с нами посредством использования Услуги чата, вы уполномочиваете нас
                    отслеживать и сохранять ваши чаты.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    5.2. &nbsp; &nbsp;Важная информация. Услуга чата предоставляется для
                    удобства, часто для того, чтобы вам было проще разобраться в Услугах FANTOM
                    FX. Наша Услуга чата приложит разумные усилия, чтобы предоставить вам точную
                    и актуальную информацию в соответствии с вашим вопросом или потребностью.
                    Ничто из того, что мы сообщаем в Услуге чата, не может рассматриваться как
                    юридическое соглашение, заверение или гарантия в отношении Услуг FANTOM FX,
                    процессов, решений или времени ответа. На любые персональные данные,
                    предоставленные нам при использовании Услуги чата, распространяются
                    применимые политики и уведомления, связанные с конфиденциальностью и
                    описанные в нашем Уведомлении о конфиденциальности.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    5.3. &nbsp; &nbsp;Чаты пользователей. Кроме того, мы можем сделать
                    доступными для вас чаты, которые позволят вам напрямую взаимодействовать с
                    другими пользователями Платформы («Чат пользователей»).
                </p>
                <p>
                    <br />
                </p>
                <p>
                    5.4. &nbsp; &nbsp;Запрещенные действия. Запрещается использовать Услугу чата
                    или любой Чат пользователей для отправки оскорбительных, клеветнических,
                    недобросовестных или непристойных сообщений; сообщений, направленных на
                    манипулирование рынком или распространение ложной или вводящей в заблуждение
                    информации; сообщений, которые иным образом противоречат Применимому
                    законодательству. Такие действия могут привести к прекращению сеанса Услуги
                    чата и ограничению вашего доступа к Услугам FANTOM FX.
                </p>
                <p>
                    <br />
                </p>
                <p>6. &nbsp; &nbsp;Комиссия</p>
                <p>
                    <br />
                </p>
                <p>
                    6.1. &nbsp; &nbsp;Оплата комиссий. Вы соглашаетесь оплачивать все применимые
                    комиссии в связи с использованием вами Услуг FANTOM FX.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    Вы уполномочиваете нас вычитать все применимые комиссии, сборы, проценты,
                    платежи и другие суммы, которые вы должны, из вашего Аккаунта FANTOM FX в
                    соответствии с настоящими Условиями или любыми Условиями продукта, используя
                    метод расчета. Если вы должны нам сумму в одном Цифровом активе, но у вас
                    недостаточно средств в этом Цифровом активе, мы можем вычесть сумму
                    задолженности в другом Цифровом активе для осуществления платежа (в этом
                    случае мы конвертируем имеющийся у вас Цифровой актив в причитающийся нам
                    Цифровой актив (или эквивалент в Фиатной валюте) по актуальному курсу на
                    Платформе или по другому коммерчески обоснованному курсу по нашему
                    усмотрению). Если в вашем Аккаунте FANTOM FX недостаточно Цифровых активов,
                    вы принимаете, что любая сумма, которую вы должны выплатить в соответствии с
                    настоящим пунктом, является долгом, и вы обязуетесь немедленно выплатить его
                    нам коммерчески обоснованным образом в размере и форме (в виде Цифровых
                    активов или иных активов), определяемых по нашему усмотрению.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    6.2. &nbsp; &nbsp;Изменение наших комиссий. Мы можем периодически
                    корректировать наши комиссии в соответствии с п. ‎18.3 настоящих Условий.
                </p>
                <p>
                    <br />
                </p>
                <p>ИНФОРМАЦИЯ О ВАШЕМ АККАУНТЕ FANTOM FX</p>
                <p>
                    <br />
                </p>
                <p>7. &nbsp; &nbsp;Создание Аккаунта FANTOM FX</p>
                <p>
                    <br />
                </p>
                <p>
                    7.1. &nbsp; &nbsp;Создание Аккаунта. Для получения доступа к Услугам FANTOM
                    FX и Платформы вы должны создать и сохранять Аккаунт FANTOM FX. Это может
                    быть Аккаунт FANTOM FX для физического лица или Корпоративный аккаунт FANTOM
                    FX для корпорации, юридического лица или иной организации.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    Все Аккаунты FANTOM FX предоставляются по нашему абсолютному усмотрению. Мы
                    оставляем за собой право без причины отклонить любую заявку на создание
                    Аккаунта FANTOM FX или ограничить количество Аккаунтов FANTOM FX, которое вы
                    можете иметь.&nbsp;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    7.2. &nbsp; &nbsp;Один бенефициар. Создавая Аккаунт FANTOM FX, вы признаете,
                    что:
                </p>
                <p>
                    <br />
                </p>
                <p>
                    a. &nbsp; &nbsp;если вы являетесь индивидуальным пользователем, вы будете
                    использовать свой Аккаунт FANTOM FX только для себя, а не от имени третьих
                    лиц, если только вы не получили наше предварительное письменное
                    согласие;&nbsp;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    b. &nbsp; &nbsp;если вы являетесь корпоративным пользователем, ваши
                    Разрешенные пользователи будут использовать Корпоративный аккаунт FANTOM FX
                    только в ваших интересах, а не от имени третьих лиц, если только вы не
                    получили наше предварительное письменное согласие.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    Вы несете полную ответственность за все действия, которые происходят в вашем
                    Аккаунте FANTOM FX.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    7.3. &nbsp; &nbsp;Верификация личности. Прежде чем вы получите разрешение
                    создать Аккаунт FANTOM FX, получить доступ к Услугам FANTOM FX и
                    пользоваться ими, вы должны будете пройти процедуру верификации личности,
                    предоставив нам определенную информацию о себе и, в соответствующих случаях,
                    о своих Разрешенных пользователях. Вся предоставленная вами информация
                    должна быть полной, точной и правдивой. Если эта информация изменится, вы
                    должны будете незамедлительно ее обновить.&nbsp;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    Вы уполномочиваете нас делать запросы, напрямую или через третьих лиц,
                    которые мы считаем необходимыми для верификации вашей личности и личности
                    любых Разрешенных пользователей или для защиты вас и/или нас от
                    мошенничества, отмывания денег, финансирования терроризма или других
                    финансовых преступлений, а также предпринимать любые действия, которые мы
                    сочтем необходимыми на основании результатов таких запросов.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    Когда мы делаем запросы, вы подтверждаете и принимаете, что ваши
                    персональные данные и персональные данные любых Разрешенных пользователей
                    могут быть раскрыты агентствам по верификации личности, регистрации данных о
                    соответствии, кредитным историям, предотвращению мошенничества или борьбе с
                    финансовыми преступлениями, и что эти агентства могут отвечать на наши
                    запросы в полном объеме.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    <br />
                </p>
                <p>
                    7.4. &nbsp; &nbsp;Расширенная проверка. Мы также можем потребовать от вас
                    прохождения расширенной проверки путем указания дополнительной информации о
                    вас, вашем бизнесе или ваших Разрешенных пользователях, предоставления
                    дополнительных записей или документов или проведения личных встреч с
                    представителями FANTOM FX.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    7.5. &nbsp; &nbsp;Записи. Мы храним ваши персональные данные для того, чтобы
                    вы могли продолжать пользоваться Услугами FANTOM FX. Данные хранятся до тех
                    пор, пока они необходимы для выполнения соответствующих целей, описанных в
                    настоящем Уведомлении о конфиденциальности, а также в соответствии с
                    требованиями закона, например для целей налогообложения и бухгалтерского
                    учета, соблюдения законов по борьбе с отмыванием денег или других целей,
                    сообщенных вам.
                </p>
                <p>
                    7.6. &nbsp; &nbsp;Субаккаунты. По нашему усмотрению вы можете создать
                    Субаккаунт FANTOM FX и получить к нему доступ. На каждое физическое лицо,
                    связанное с Субаккаунтом FANTOM FX, распространяются требования по
                    верификации личности, изложенные в данном п. ‎7. Один Субаккаунт FANTOM FX
                    может быть связан только с одним физическим лицом или корпорацией.&nbsp;
                </p>
                <p>
                    <br />
                </p>
                <p>8. &nbsp; &nbsp;Запросы информации</p>
                <p>
                    <br />
                </p>
                <p>
                    8.1. &nbsp; &nbsp;Когда мы можем запросить информацию. Мы можем потребовать
                    от вас информацию в любое время в целях соблюдения любого Применимого
                    законодательства или требований верификации личности, или в связи с
                    выявлением отмывания денег, финансирования терроризма, мошенничества или
                    любого другого финансового преступления, или по любой другой уважительной
                    причине. Вы соглашаетесь предоставить нам любую запрашиваемую нами
                    информацию и разрешить нам хранить записи об этой информации в течение всего
                    срока существования вашего Аккаунта FANTOM FX и до тех пор, пока это
                    необходимо для выполнения поставленных целей, или в течение другого срока,
                    предусмотренного Применимым законодательством.&nbsp;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    8.2. &nbsp; &nbsp;Что происходит, когда вы предоставляете информацию. Ваш
                    доступ к Аккаунту FANTOM FX и лимиты Транзакций, которые применяются к
                    использованию вами Услуг FANTOM FX, могут быть изменены в результате
                    получения информации, собираемой о вас на постоянной основе. Если есть
                    обоснованные подозрения, что какая-либо предоставленная вами информация
                    неверная, неправдивая, устаревшая или неполная, мы можем направить вам
                    уведомление с просьбой внести исправления, удалить соответствующую
                    информацию или совершить иные действия, которые мы считаем необходимыми для
                    получения от вас достоверной и точной информации.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    8.3. &nbsp; &nbsp;Если вы не предоставите запрашиваемую информацию. Вы
                    обязаны предоставить нам любую информацию по нашему запросу. Если вы
                    откажетесь предоставить запрашиваемую информацию или иным образом не
                    выполните требования своевременно, мы оставляем за собой право немедленно
                    приостановить или прекратить ваш доступ к Аккаунту FANTOM FX или ко всем или
                    части Услуг FANTOM FX без уведомления.
                </p>
                <p>
                    <br />
                </p>
                <p>9. &nbsp; &nbsp;Доступ к вашему Аккаунту FANTOM FX</p>
                <p>
                    <br />
                </p>
                <p>
                    9.1. &nbsp; &nbsp;Доступ. Для доступа к вашему Аккаунту FANTOM FX вы или, в
                    соответствующих случаях, ваши Разрешенные пользователи должны иметь
                    необходимое оборудование (например, компьютер или смартфон) и доступ к
                    интернету. Доступ к вашему Аккаунту FANTOM FX можно получить непосредственно
                    на Платформе или с помощью другого средства доступа (включая API), которое
                    мы можем предоставить. Вы имеете право войти и использовать ваш Аккаунт
                    FANTOM FX только с помощью Идентификаторов доступа, которые мы предоставляем
                    вам или вашим Разрешенным пользователям для этих целей. Для обеспечения
                    безопасности вашего Аккаунта FANTOM FX мы можем потребовать подключить
                    многофакторную аутентификацию.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    На использование Платформы и других средств доступа могут распространяться
                    дополнительные условия, о которых мы вам сообщим.&nbsp;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    9.2. &nbsp; &nbsp;Ограничение доступа третьих лиц. Вы должны гарантировать,
                    чтобы любой(-ые) Аккаунт(ы) FANTOM FX, зарегистрированный(-ые) на ваше имя,
                    будет(-ут) использоваться только вами или, в случае Корпоративных аккаунтов
                    FANTOM FX, вашими Разрешенными пользователями, если иное не предусмотрено
                    настоящими Условиями.
                </p>
                <p>
                    <br />
                </p>
                <p>10. &nbsp; &nbsp;Информация об Аккаунте и записи о Транзакциях</p>
                <p>
                    <br />
                </p>
                <p>
                    10.1. &nbsp; &nbsp;Ваша История аккаунта. Вы сможете получить доступ к вашей
                    Истории аккаунта на Платформе. Вы должны внимательно изучить вашу Историю
                    аккаунта и сообщить нам, если увидите какие-либо записи или Транзакции,
                    которые вы не помните или считаете неправильными, в течение четырнадцати
                    (14) календарных дней с даты предоставления или обеспечения вам доступа к
                    вашей Истории аккаунта.&nbsp;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    10.2. &nbsp; &nbsp;Ошибки. Мы можем исправить любую ошибку в вашей Истории
                    аккаунта в любое время и оставляем за собой право аннулировать или отменить
                    любую Транзакцию, включающую или вытекающую из Явной ошибки, или изменить
                    детали такой Транзакции, чтобы отразить то, что мы разумно считаем
                    правильными или справедливыми деталями такой транзакции без Явной ошибки.
                    Без ущерба для общего характера других положений настоящих Условий и при
                    отсутствии мошеннических или недобросовестных действий, FANTOM FX ни при
                    каких обстоятельствах не несет ответственности перед вами за любые убытки
                    или требования любого характера, которые вы можете понести или с которыми
                    можете столкнуться в связи с любой Явной ошибкой. Они могут иметь любую
                    причину, быть прямыми или косвенными, специальными или последовательными,
                    включая, помимо прочего, потерю прибыли или потерю возможностей, даже если
                    FANTOM FX была предупреждена о возможности возникновения таких убытков или
                    их можно было разумно предвидеть.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    10.3. &nbsp; &nbsp;Передача информации. В соответствии с Применимым
                    законодательством мы можем быть обязаны передавать информацию о вашем
                    Аккаунте FANTOM FX и Истории аккаунта третьим лицам. Вы подтверждаете и
                    принимаете, что мы имеем право раскрывать такую информацию.&nbsp;
                </p>
                <p>
                    <br />
                </p>
                <p>ИСПОЛЬЗОВАНИЕ ВАШЕГО АККАУНТА</p>
                <p>
                    <br />
                </p>
                <p>11. &nbsp; &nbsp;Инструкции</p>
                <p>
                    <br />
                </p>
                <p>
                    11.1. &nbsp; &nbsp;Отправка Указаний. Вы должны обеспечить полноту и
                    точность любого отправляемого Указания. Мы не обязаны проверять точность,
                    подлинность или действительность любого Указания и не будем отслеживать или
                    отклонять Указания на основании того, что они являются или кажутся
                    дубликатами. Однако если у нас есть сомнения в точности, подлинности или
                    действительности Указания, мы можем отказать в исполнении или отложить
                    исполнение любого Указания, или запросить дополнительную информацию в
                    отношении Указания.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    Указания являются безотзывными, поэтому после отправки Указания вы или ваши
                    Разрешенные пользователи не имеют права отменить или отозвать его без нашего
                    письменного согласия. Ваше Указание не считается полученным нами до тех пор,
                    пока оно не будет получено нашим сервером. Наша запись всех Указаний будет
                    окончательной и наложит на вас обязательства для всех целей.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    11.2. &nbsp; &nbsp;Действия по вашим Указаниям. Отправляя Указание, вы или
                    ваши Разрешенные пользователи уполномочиваете нас инициировать Транзакцию в
                    вашем Аккаунте FANTOM FX. Таким образом, мы уполномочены зачислить или
                    списать (или предоставить информацию о расчетах третьим лицам для зачисления
                    или списания третьими лицами) ваши Цифровые активы на или из вашего Аккаунта
                    FANTOM FX в соответствии с вашим Указанием. Если в вашем Аккаунте FANTOM FX
                    недостаточно Цифровых активов или Фиатной валюты для осуществления
                    Транзакции (т. е. меньше суммы, необходимой для расчета по Транзакции и
                    оплаты всех комиссий, связанных с Транзакцией), мы имеем право отказать в
                    осуществлении Транзакции. Вы несете ответственность за хранение достаточного
                    количества Цифровых активов или Фиатной валюты в Аккаунте FANTOM FX.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    11.3. &nbsp; &nbsp;Защита Указаний. Вы осознаете, что Указания и информация,
                    передаваемые на Платформе или по электронной почте, обычно передаются через
                    интернет и могут быть направлены через публичные, транснациональные сети,
                    которые не имеют специальной защиты. Мы не можем гарантировать, что
                    передаваемые Указания и информация будут полностью защищены от
                    несанкционированного доступа, и вы принимаете связанные с этим риски.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    11.4. &nbsp; &nbsp;Вывод средств. В соответствии с настоящими Условиями и
                    любыми применимыми Условиями продукта, а также при условии, что на Аккаунте
                    FANTOM FX имеется достаточный баланс и соответствующие Цифровые активы не
                    удерживаются на вашем Аккаунте FANTOM FX в связи с использованием какой-либо
                    услуги FANTOM FX, вы можете дать инструкции FANTOM FX по переводу Цифровых
                    активов на адрес внешнего кошелька, отправив запрос на вывод средств на
                    Платформе. После получения запроса на вывод средств FANTOM FX: вычтет
                    остаток средств с вашего Аккаунта FANTOM FX; и инициирует ончейн-перевод на
                    указанный вами внешний кошелек. FANTOM FX может не обработать запрос на
                    вывод средств, если, по нашему обоснованному мнению, Применимое
                    законодательство препятствует выполнению соответствующего вывода средств.
                    FANTOM FX также может приостановить вывод средств в то время, которое сочтет
                    необходимым для устранения любых инцидентов на Платформе. Как только такие
                    инциденты будут устранены, FANTOM FX возобновит вывод средств.
                </p>
                <p>
                    <br />
                </p>
                <p>12. &nbsp; &nbsp;Транзакции</p>
                <p>
                    <br />
                </p>
                <p>
                    12.1. &nbsp; &nbsp;Заключение Транзакций. Вы можете заключать Транзакции
                    напрямую с нами или напрямую с другими пользователями, при нашем содействии
                    или без него.&nbsp;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    Мы не заявляем и не гарантируем, что любая Транзакция будет завершена
                    успешно или в течение определенного периода времени.&nbsp;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    12.2. &nbsp; &nbsp;Несанкционированные Транзакции. Вы несете ответственность
                    за контроль и использование вашего Аккаунта FANTOM FX. Таким образом, мы
                    будем считать, что вы или Разрешенный пользователь санкционировали любое
                    Указание, отправленное с вашего Аккаунта FANTOM FX, если нас не уведомят об
                    обратном. Вам необходимо отслеживать свою Историю аккаунта, чтобы как можно
                    скорее выявлять и сообщать нам о любых несанкционированных или
                    подозрительных действиях в вашем Аккаунте FANTOM FX. Мы не несем
                    ответственности за любые претензии или убытки, возникшие в результате
                    Транзакции, совершенной в результате несанкционированного Указания, если вы
                    не уведомили нас в соответствии с данным пунктом.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    12.3. &nbsp; &nbsp;Хранение информации о Транзакциях. Для обеспечения
                    соответствия мировым отраслевым стандартам хранения данных вы соглашаетесь
                    разрешить нам (но не требовать от нас) хранить запись всей информации о
                    Транзакциях в течение всего срока действия вашего Аккаунта FANTOM FX, пока
                    это необходимо для выполнения их предполагаемых целей, или в течение другого
                    периода, предусмотренного Применимым законодательством.
                </p>
                <p>13. &nbsp; &nbsp;Материальные интересы и конфликты</p>
                <p>
                    <br />
                </p>
                <p>
                    13.1. &nbsp; &nbsp;Группа FANTOM FX. Вы понимаете, что FANTOM FX является
                    членом группы компаний, которая занимается деятельностью, связанной с
                    Цифровыми активами.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    13.2. &nbsp; &nbsp;Характер наших обязанностей. Вы понимаете и принимаете,
                    что ни ваши отношения с нами, ни любая Услуга FANTOM FX, предоставляемая
                    нами вам, ни любая другая причина не приведут к возникновению каких-либо
                    обязанностей с нашей стороны или со стороны любого Партнера FANTOM FX, будь
                    то юридические, основанные на справедливости или фидуциарные обязанности,
                    кроме тех, которые прямо указаны в настоящих условиях. В частности, мы и
                    Партнеры FANTOM FX можем периодически выступать более чем в одном качестве,
                    и в этих качествах мы можем получать комиссии или сборы от более чем одного
                    пользователя (включая вас). Вы принимаете, что мы можем выступать в таких
                    качествах и предоставлять любые другие Услуги FANTOM FX или вести любые дела
                    с вами, любым Партнером FANTOM FX или любым другим пользователем.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    13.3. &nbsp; &nbsp;Материальные интересы. Вы понимаете и принимаете, что ни
                    мы, ни Партнеры FANTOM FX не обязаны: (1) учитывать какую-либо известную нам
                    или Партнеру FANTOM FX информацию, которая представляет собой материальный
                    интерес; (2) раскрывать вам такую информацию; или (3) использовать такую
                    информацию в ваших интересах. Вы также признаете, что в ходе предоставления
                    вам Услуг FANTOM FX мы можем периодически получать общую информацию о рынке,
                    которую мы можем использовать в ходе нашей обычной деятельности.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    13.4. &nbsp; &nbsp;Конфликты интересов. Мы создали и поддерживаем
                    эффективные организационные и административные механизмы с целью принятия
                    всех необходимых мер по выявлению и урегулированию конфликтов интересов
                    между нами, нашими пользователями и соответствующими третьими лицами, чтобы
                    предотвратить возникновение конфликтов интересов, негативно влияющих на
                    интересы наших пользователей. В тех случаях, когда таких организационных и
                    административных мер недостаточно для предотвращения рисков нанесения ущерба
                    вашим интересам, мы проинформируем вас о характере и/или источниках
                    соответствующих конфликтов интересов и мерах, принятых для снижения этих
                    рисков, чтобы вы могли принять обоснованное решение о том, продолжать ли вам
                    совершать сделки с нами. Мы оставляем за собой право в любое время
                    отказаться действовать в ваших интересах, если мы не можем урегулировать
                    конфликт интересов каким-либо другим способом.&nbsp;
                </p>
                <p>
                    <br />
                </p>
                <p>14. &nbsp; &nbsp;Лимиты Транзакций&nbsp;</p>
                <p>
                    <br />
                </p>
                <p>
                    14.1. &nbsp; &nbsp;Ваши лимиты Транзакции. На ваш Аккаунт FANTOM FX может
                    быть наложен лимит на:
                </p>
                <p>
                    <br />
                </p>
                <p>
                    a. &nbsp; &nbsp;количество или объем Транзакций, которые вы можете совершать
                    в связи с вашим Аккаунтом FANTOM FX; и/или
                </p>
                <p>
                    <br />
                </p>
                <p>
                    b. &nbsp; &nbsp;количество или стоимость Фиатной валюты или Цифровых
                    активов, которые вы можете перевести на свой Аккаунт FANTOM FX или из
                    него,&nbsp;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    в каждом случае за определенный период (например, за день). Все применимые
                    лимиты отображаются в вашем Аккаунте FANTOM FX.&nbsp;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    14.2. &nbsp; &nbsp;Изменения в лимитах Транзакций. Мы оставляем за собой
                    право изменить любой лимит Транзакций, применяемый к вашему Аккаунту FANTOM
                    FX, в любое время по своему абсолютному усмотрению. Вы также можете
                    запросить изменение ваших лимитов. Любое изменение будет произведено по
                    нашему абсолютному усмотрению и будет зависеть от любых дополнительных
                    условий, которые мы сочтем необходимыми.
                </p>
                <p>
                    <br />
                </p>
                <p>15. &nbsp; &nbsp;Поддерживаемые цифровые активы</p>
                <p>
                    <br />
                </p>
                <p>
                    15.1. &nbsp; &nbsp;Поддерживаемые цифровые активы. Услуги FANTOM FX доступны
                    только в связи с Поддерживаемыми цифровыми активами, которые могут
                    периодически меняться. Список Поддерживаемых цифровых активов публикуется на
                    нашем Сайте. Мы можем удалить или приостановить действие одного или
                    нескольких Цифровых активов из списка Поддерживаемых цифровых активов. Мы
                    предпримем разумные коммерческие усилия, чтобы уведомить вас об этом
                    заранее. После удаления у вас больше не будет доступа к таким Цифровым
                    активам в рамках Услуг FANTOM FX, и вы сможете только вывести Цифровые
                    активы со своего Аккаунта FANTOM FX. Если Цифровые активы, которые больше не
                    являются Поддерживаемыми цифровыми активами, остаются на вашем Аккаунте
                    FANTOM FX после указанного срока, о котором мы вам сообщили, FANTOM FX может
                    по своему разумному усмотрению конвертировать такие Цифровые активы в другой
                    тип Цифровых активов — стейблкоины. FANTOM FX заблаговременно отправит
                    уведомление о такой конвертации, и вы сможете вывести Цифровые активы в
                    течение разумного срока, указанного FANTOM FX, до их конвертации в
                    стейблкоины.&nbsp;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    Мы не несем никакой ответственности в связи с любыми попытками использовать
                    ваш Аккаунт FANTOM FX для неподдерживаемых Цифровых активов, а также в связи
                    с конвертацией в другой тип цифровых активов, как описано в настоящем
                    пункте.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    15.2. &nbsp; &nbsp;Форки. Мы можем временно приостановить любые Услуги
                    FANTOM FX в отношении конкретного Цифрового актива, пока мы принимаем
                    решение о поддержке Форка. Мы не обязаны поддерживать Форк цифрового актива,
                    который вы храните в Аккаунте FANTOM FX, независимо от того, станет ли
                    Прошедший форк цифровой актив Доминирующим цифровым активом или нет. Если мы
                    решим поддержать Форк цифрового актива, мы сделаем публичное заявление на
                    Сайте или другими способами, которые сочтем необходимыми.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    Вы принимаете, что мы не контролируем и не имеем возможности влиять на
                    создание или внедрение Форка. Мы не можем предоставить никаких гарантий
                    относительно безопасности, функциональности или предложения любого Цифрового
                    актива, включая как новый Доминирующий цифровой актив, так и другие Цифровые
                    активы, связанные с соответствующим Форком. В некоторых случаях вы не
                    сможете торговать на Платформе Прошедшими форк цифровыми активами и можете
                    потерять любую стоимость соответствующих Цифровых активов.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    15.3. &nbsp; &nbsp;Обеспеченные цифровые активы. Мы можем периодически
                    поддерживать Цифровые активы, которые предположительно обеспечены или иным
                    образом привязаны по стоимости к другому активу, включая Цифровые активы,
                    Фиатную валюту или товары, такие как серебро или золото («Обеспеченные
                    цифровые активы»). Вы соглашаетесь, что прочитали, поняли и приняли все
                    условия и риски, связанные с каждым конкретным Обеспеченным цифровым
                    активом, до заключения любой Транзакции, связанной с таким Обеспеченным
                    цифровым активом. Мы не обязаны приобретать, повторно приобретать,
                    осуществлять или содействовать погашению ваших Обеспеченных цифровых
                    активов. Мы оставляем за собой право изменять, приостанавливать или
                    прекращать любую услугу в отношении любого Обеспеченного цифрового актива.
                    Мы не делаем никаких заявлений относительно того, сохранит ли какой-либо
                    конкретный Обеспеченный цифровой актив свою стоимость по отношению к
                    какому-либо активу, а также относительно объема или качества резервов или
                    залога, имеющихся у каждого эмитента или третьей стороны в отношении любого
                    Обеспеченного цифрового актива.
                </p>
                <p>
                    <br />
                </p>
                <p>16. &nbsp; &nbsp;Безопасность аккаунта</p>
                <p>
                    <br />
                </p>
                <p>
                    16.1. &nbsp; &nbsp;Ваша ответственность. Вы несете ответственность за
                    принятие соответствующих мер по защите вашего оборудования и данных от
                    вирусов и вредоносных программ, а также любых неприемлемых материалов. За
                    исключением случаев, предусмотренных Применимым законодательством, вы несете
                    ответственность за резервное копирование и поддержание дубликатов любой
                    информации, которую вы храните или передаете через Услуги FANTOM FX. Мы не
                    несем ответственности за любые претензии или убытки, возникшие в результате
                    несоблюдения вами данного пункта.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    16.2. &nbsp; &nbsp;Меры безопасности. В любое время вы и любые Разрешенные
                    пользователи должны обеспечивать надлежащую безопасность и контроль всех
                    ваших Идентификаторов доступа. Вы несете ответственность за принятие
                    необходимых мер безопасности (или обеспечение принятия таких мер вашими
                    Разрешенными пользователями) для защиты вашего Аккаунта FANTOM FX и
                    обеспечения безопасности вашего Идентификатора доступа, в том числе
                    следующими способами:
                </p>
                <p>
                    <br />
                </p>
                <p>a. &nbsp; &nbsp;строго соблюдайте все наши механизмы и процедуры;</p>
                <p>
                    <br />
                </p>
                <p>
                    b. &nbsp; &nbsp;создайте надежный пароль и обеспечивайте безопасность и
                    контроль ваших Идентификаторов доступа;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    c. &nbsp; &nbsp;предоставляйте нам актуальную Электронную почту и номер
                    телефона для получения любых уведомлений или предупреждений, которые мы
                    можем вам отправлять;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    d. &nbsp; &nbsp;никогда не разрешайте удаленный доступ или совместное
                    использование вашего компьютера и/или экрана компьютера с кем-либо другим,
                    когда выполнен вход в ваш Аккаунт FANTOM FX;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    e. &nbsp; &nbsp;помните, что мы ни при каких обстоятельствах не попросим вас
                    поделиться своими паролями или кодами двухфакторной аутентификации; и
                </p>
                <p>
                    <br />
                </p>
                <p>
                    f. &nbsp; &nbsp;выходите из Сайтов или Платформы в конце каждого посещения.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    Вы должны защищать Электронную почту и Идентификаторы доступа от любых атак
                    и несанкционированного доступа. Вы должны немедленно уведомить нас, если вам
                    стало известно или у вас есть основания подозревать, что ваша Электронная
                    почта или Электронная почта Разрешенного пользователя была взломана, или
                    если вашу Электронную почту или Электронную почту Разрешенного пользователя
                    использовали несанкционированно.&nbsp;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    16.3. &nbsp; &nbsp;Отслеживание Истории аккаунта. Вам необходимо отслеживать
                    свою Историю аккаунта, чтобы как можно скорее выявлять и сообщать нам о
                    любых несанкционированных или подозрительных действиях в вашем Аккаунте
                    FANTOM FX. Вы принимаете, что любое Нарушение безопасности может привести к
                    несанкционированному доступу третьих лиц к вашему Аккаунту FANTOM FX и
                    потере или краже любых Цифровых активов и/или средств, хранящихся в вашем
                    Аккаунте FANTOM FX и любых связанных с ним аккаунтах, включая банковские
                    счета и кредитные карты.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    16.4. &nbsp; &nbsp;Если вы подозреваете Нарушение безопасности. Если вы
                    подозреваете Нарушение безопасности, вы должны убедиться, что:
                </p>
                <p>
                    <br />
                </p>
                <p>
                    a. &nbsp; &nbsp;мы немедленно уведомлены в соответствии со способами связи,
                    указанными в п. ‎3.1, и продолжаем получать точную и актуальную информацию в
                    течение всего периода Нарушения безопасности;&nbsp;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    b. &nbsp; &nbsp;ваш Аккаунт FANTOM FX немедленно заблокирован с помощью
                    функции отключения аккаунта на Платформе или любым другим способом, который
                    мы периодически предписываем; и
                </p>
                <p>
                    <br />
                </p>
                <p>
                    c. &nbsp; &nbsp;вы предпринимаете любые другие шаги, которые мы можем
                    разумно потребовать для уменьшения, контроля или уведомления о любом
                    Нарушении безопасности.&nbsp;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    Мы оставляем за собой право запрашивать, а вы соглашаетесь предоставлять всю
                    информацию и документы, которые мы считаем уместными или необходимыми в
                    связи с фактическим или предполагаемым Нарушением безопасности, и можем
                    предоставлять такую информацию любой третьей стороне, которую мы считаем
                    необходимой для разрешения любого Нарушения безопасности.&nbsp;
                </p>
                <p>
                    <br />
                </p>
                <p>ДРУГАЯ ВАЖНАЯ ИНФОРМАЦИЯ</p>
                <p>
                    <br />
                </p>
                <p>17. &nbsp; &nbsp;Конфиденциальность</p>
                <p>
                    <br />
                </p>
                <p>
                    17.1. &nbsp; &nbsp;Уведомление о конфиденциальности. Сбор и использование
                    нами персональных данных в связи с настоящими Условиями, Услугами FANTOM FX,
                    Платформой и любым Сайтом осуществляются в соответствии с нашим Уведомлением
                    о конфиденциальности (с периодическими обновлениями). Вы принимаете, что мы
                    можем обрабатывать ваши персональные данные, которые вы предоставили нам или
                    мы собрали у вас в связи с настоящими Условиями и в соответствии с
                    Уведомлением о конфиденциальности. Ваши персональные данные будут
                    обрабатываться в соответствии с Уведомлением о конфиденциальности, которое
                    является частью настоящих Условий.&nbsp;
                </p>
                <p>
                    <br />
                </p>
                <p>Вы заявляете и гарантируете, что:&nbsp;</p>
                <p>
                    <br />
                </p>
                <p>
                    <br />
                </p>
                <p>
                    a. вы подтверждаете, что прочитали и поняли наше Уведомление о
                    конфиденциальности.&nbsp;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    b. наш бизнес регулярно меняется, и наше Уведомление о конфиденциальности
                    также будет меняться. Поэтому если мы периодически будем предоставлять вам
                    заменяющую версию Уведомления о конфиденциальности, вы должны
                    незамедлительно ознакомиться с Уведомлением о конфиденциальности.
                    &nbsp;&nbsp;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    18. &nbsp; &nbsp;Внесение изменений в настоящие Условия и другие положения
                </p>
                <p>
                    <br />
                </p>
                <p>
                    18.1. &nbsp; &nbsp;Как и когда мы можем вносить изменения. Мы можем вносить
                    изменения в настоящие Условия и любые упоминаемые положения и условия
                    (включая любые Условия продукта) в любое время, и ваше принятие настоящих
                    Условий означает ваше прямое согласие. Мы сообщим вам о любых изменениях по
                    электронной почте или через наш Сайт.&nbsp;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    О большинстве изменений мы сообщим заранее до вступления изменений в силу.
                    Однако иногда нам может потребоваться внести изменения без предварительного
                    уведомления. Это может произойти в следующих случаях:
                </p>
                <p>
                    <br />
                </p>
                <p>
                    a. &nbsp; &nbsp;мы вносим изменения в результате законодательных и/или
                    нормативных изменений;
                </p>
                <p>
                    <br />
                </p>
                <p>b. &nbsp; &nbsp;вносимые изменения отвечают вашим интересам;</p>
                <p>
                    <br />
                </p>
                <p>
                    c. &nbsp; &nbsp;изменения направлены на то, чтобы сделать настоящие Условия
                    более понятными для вас; и/или
                </p>
                <p>
                    <br />
                </p>
                <p>
                    d. &nbsp; &nbsp;существует любая другая уважительная причина, в рамках
                    которой у нас нет времени на уведомление.&nbsp;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    Если мы не можем уведомить вас заранее, мы сообщим вам об изменениях как
                    можно скорее после их внесения.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    18.2. &nbsp; &nbsp;Когда изменения вступают в силу. За исключением случаев,
                    когда изменения вступают в силу немедленно, обновленные Условия вступают в
                    силу после того, как мы отправили вам уведомление. Если вы не желаете
                    принимать изменения, вы вправе закрыть ваш Аккаунт FANTOM FX в соответствии
                    с п. ‎19.1 настоящих Условий. Если мы не получим от вас ответа, то будем
                    считать, что вы приняли изменения, и ваш дальнейший доступ или использование
                    Услуг FANTOM FX будет считаться принятием обновленных Условий.
                </p>
                <p>
                    <br />
                </p>
                <p>19. &nbsp; &nbsp;Закрытие Аккаунта FANTOM FX&nbsp;</p>
                <p>
                    <br />
                </p>
                <p>
                    19.1. &nbsp; &nbsp;Ваше право закрыть свой Аккаунт FANTOM FX. Вы можете
                    закрыть свой Аккаунт FANTOM FX в любое время, следуя процедуре прекращения
                    работы аккаунта, которую мы периодически предписываем. С вас не будет
                    взиматься плата за закрытие вашего Аккаунта FANTOM FX, однако вы должны
                    будете выплатить все непогашенные суммы, причитающиеся нам. Вы
                    уполномочиваете нас отменить или приостановить любые незавершенные
                    транзакции на момент закрытия, а также вычесть любые непогашенные суммы,
                    которые вы должны нам, из вашего Аккаунта FANTOM FX.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    В некоторых случаях вы не сможете закрыть свой Аккаунт FANTOM FX, в том
                    числе если:
                </p>
                <p>
                    <br />
                </p>
                <p>
                    a. &nbsp; &nbsp;вы пытаетесь уклониться от расследования соответствующих
                    органов;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    b. &nbsp; &nbsp;у вас есть незавершенная Транзакция или открытая Претензия;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    c. &nbsp; &nbsp;в вашем Аккаунте FANTOM FX есть какие-либо непогашенные
                    суммы, причитающиеся нам; или
                </p>
                <p>
                    <br />
                </p>
                <p>
                    d. &nbsp; &nbsp;на ваш Аккаунт FANTOM FX накладывается заморозка, удержание,
                    ограничение или резервирование.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    19.2. &nbsp; &nbsp;Что происходит, когда ваш Аккаунт FANTOM FX закрывается.
                    Если ваш аккаунт FANTOM FX закрывается, вы должны будете вывести все
                    Цифровые активы, находящиеся на вашем Аккаунте FANTOM FX. Если вы не
                    выведете свои цифровые активы или не будете входить в аккаунт FANTOM FX в
                    течение 90 дней, мы направим вам уведомление о нашем намерении рассматривать
                    ваш аккаунт как неактивный.&nbsp;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    19.3. &nbsp; &nbsp;Что происходит, когда ваш аккаунт становится неактивным.
                    Если вы не ответите на уведомление в п. 19.2 в течение 30 дней, мы можем:
                </p>
                <p>
                    <br />
                </p>
                <p>a. &nbsp; &nbsp;пометить ваш Аккаунт FANTOM FX как неактивный;</p>
                <p>
                    <br />
                </p>
                <p>
                    b. &nbsp; &nbsp;конвертировать Цифровые активы в другой тип Цифровых
                    активов. При этом мы не несем ответственности за упущенную прибыль,
                    налоговые обязательства или любые другие убытки, ущерб или расходы,
                    понесенные вами в результате такой конвертации;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    c. &nbsp; &nbsp;перевести такой неактивный аккаунт и/или любые Цифровые
                    активы, находящиеся на этом аккаунте, третьей стороне (включая, помимо
                    прочего, другого Партнера FANTOM FX, любого стороннего кастодиала или
                    изолированный кошелек), если &nbsp;мы сочтем это разумно необходимым. Если
                    это произойдет, вы имеете право получить свои цифровые активы при условии
                    выполнения разумных требований по верификации (наших или соответствующей
                    третьей стороны) &nbsp;и любых других применимых условий и положений;&nbsp;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    d. &nbsp; &nbsp;скорректировать ваш неактивный аккаунт таким образом, чтобы
                    вы получили договорное требование на количество и тип Цифровых активов,
                    которые находились на вашем Аккаунте FANTOM FX до его перевода в неактивный
                    статус;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    e. &nbsp; &nbsp;взимать комиссию за неактивный аккаунт, которая может
                    покрывать расходы на поддержание Цифровых активов &nbsp;любым Партнером
                    FANTOM FX или любой третьей стороной, при этом такая комиссия должна
                    ежемесячно взиматься непосредственно с неактивного аккаунта; и
                </p>
                <p>
                    <br />
                </p>
                <p>f. &nbsp; &nbsp;закрыть неактивный аккаунт в любое время.</p>
                <p>
                    <br />
                </p>
                <p>
                    После закрытия неактивного аккаунта такой аккаунт нельзя восстановить. Если
                    вы хотите продолжать пользоваться Услугами FANTOM FX, вам необходимо
                    зарегистрировать новый Аккаунт FANTOM FX в соответствии с настоящими
                    условиями. Вы принимаете, что, в соответствии с применимыми Условиями
                    продукта, мы не обязаны выплачивать вознаграждение, поощрение или проценты,
                    которые мы могли бы согласиться выплатить в противном случае, на ваш
                    неактивный аккаунт в отношении зачисленных на него Цифровых активов.
                </p>
                <p>
                    <br />
                </p>
                <p>20. &nbsp; &nbsp;Прекращение, приостановление, удержание и ограничения</p>
                <p>
                    <br />
                </p>
                <p>
                    20.1. &nbsp; &nbsp;Наше право. Мы можем в любое время изменять или
                    прекращать предоставлять, временно или постоянно, любую часть или функцию
                    Услуг FANTOM FX. В частности, мы можем: (1) отказать в завершении или
                    заблокировать, отменить или, если это разрешено Применимым
                    законодательством, вернуть любую Транзакцию, которую вы запустили; (2)
                    прекратить, приостановить или ограничить ваш доступ к любой или всем Услугам
                    FANTOM FX; (3) прекратить, приостановить, закрыть, удержать или ограничить
                    ваш доступ к любым или всем вашим Аккаунтам FANTOM FX; (4) отказать в
                    передаче информации или Указаний третьим лицам (включая, но не
                    ограничиваясь, операторов кошельков третьих лиц); и /или (5) предпринять
                    любые действия, которые мы считаем необходимыми, в каждом случае с
                    немедленным вступлением в силу и по любой причине, включая, помимо прочего,
                    следующие случаи:
                </p>
                <p>
                    <br />
                </p>
                <p>
                    a. &nbsp; &nbsp;вы не имеете или более не имеете права пользоваться одной
                    или несколькими Услугами FANTOM FX;
                </p>
                <p>
                    <br />
                </p>
                <p>b. &nbsp; &nbsp;мы разумно подозреваем, что:</p>
                <p>
                    <br />
                </p>
                <p>
                    I. &nbsp; &nbsp;в ваш Аккаунт FANTOM FX вошли не вы или ваш Аккаунт FANTOM
                    FX был или будет использован для каких-либо незаконных, мошеннических или
                    несанкционированных целей;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    II. &nbsp; &nbsp;в ваш Корпоративный аккаунт FANTOM FX вошел не Разрешенный
                    пользователь или ваш Корпоративный аккаунт FANTOM FX был или будет
                    использован для каких-либо незаконных, мошеннических или несанкционированных
                    целей;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    III. &nbsp; &nbsp;более одного физического лица имеют доступ к одному
                    Аккаунту FANTOM FX и/или совершают операции в одном Аккаунте FANTOM FX или
                    Аккаунт FANTOM FX был или будет использован для каких-либо незаконных,
                    мошеннических или несанкционированных целей;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    IV. &nbsp; &nbsp;вы предоставили неверную, неправдивую, устаревшую или
                    неполную информацию;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    c. &nbsp; &nbsp;у нас есть разумные опасения в отношении вашей
                    кредитоспособности или финансового положения, включая:
                </p>
                <p>
                    <br />
                </p>
                <p>
                    I. &nbsp; &nbsp;если вы являетесь физическим лицом и становитесь банкротом,
                    страдаете психическим расстройством, инициируете процедуру банкротства, или
                    против вас возбуждено дело о банкротстве;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    II. &nbsp; &nbsp;если вы действуете от имени партнерства и любой из
                    партнеров умирает, становится банкротом, страдает психическим расстройством
                    или инициирует процедуру банкротства, или против любого из партнеров
                    возбуждено дело о банкротстве, или начат процесс роспуска и/или изменения
                    партнеров или устава партнерства;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    III. &nbsp; &nbsp;если вы действуете от имени корпорации и корпорация не в
                    состоянии оплатить свои долги в установленные сроки, или корпорация
                    участвует в процессе неплатежеспособности, судебного управления, внешнего
                    управления, административного управления или любой подобной или аналогичной
                    процедуры;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    IV. &nbsp; &nbsp;вы созываете собрание своих кредиторов или предлагаете или
                    заключаете какой-либо компромисс или соглашение с кредиторами или какое-либо
                    поручение в пользу своих кредиторов;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    d. &nbsp; &nbsp;ожидается предоставление информации и документов в
                    соответствии с п. ‎8;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    e. &nbsp; &nbsp;ожидается прохождение расширенной &nbsp;проверки в
                    соответствии с п. ‎7.4;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    f. &nbsp; &nbsp;мы разумно считаем, что этого от нас требует Применимое
                    законодательство или любой суд или орган, которому мы подчиняемся в любой
                    юрисдикции;
                </p>
                <p>
                    <br />
                </p>
                <p>g. &nbsp; &nbsp;мы выявили или подозреваем, что:</p>
                <p>
                    <br />
                </p>
                <p>
                    I. &nbsp; &nbsp;вы нарушили настоящие Условия или любые Условия продукта;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    II. &nbsp; &nbsp;вы нарушили какие-либо явные или подразумеваемые гарантии,
                    содержащиеся в настоящих Условиях, или какие-либо сделанные вами заявления;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    III. &nbsp; &nbsp;какая-либо Транзакция является несанкционированной,
                    ошибочной, мошеннической или незаконной, или мы выявили или подозреваем, что
                    ваш Аккаунт FANTOM FX или Услуги FANTOM FX используются мошенническим,
                    несанкционированным или незаконным образом;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    IV. &nbsp; &nbsp;произошло отмывание денег, финансирование терроризма,
                    мошенничество или любое другое преступление в связи с вашим Аккаунтом FANTOM
                    FX или использованием вами Услуг FANTOM FX;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    h. &nbsp; &nbsp;использование вашего Аккаунта FANTOM FX является предметом
                    какого-либо предстоящего, текущего или потенциального судебного
                    разбирательства, расследования или судебного, правительственного или
                    нормативного разбирательства и/или мы подозреваем повышенный риск
                    несоблюдения правовых или нормативных требований, связанных с действиями в
                    вашем Аккаунте FANTOM FX;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    i. &nbsp; &nbsp;вы должны FANTOM FX непогашенные суммы, будь то в связи с
                    возвратом платежа или на любом другом основании;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    j. &nbsp; &nbsp;любое сообщение электронной почты, направленное на вашу
                    Электронную почту, возвращается как недоставленное;
                </p>
                <p>
                    <br />
                </p>
                <p>k. &nbsp; &nbsp;возникла проблема с верификацией вашей личности;</p>
                <p>
                    <br />
                </p>
                <p>
                    l. &nbsp; &nbsp;вы предприняли какие-либо действия, которые могут обойти наш
                    контроль, например открытие нескольких Аккаунтов FANTOM FX без нашего
                    письменного согласия или злоупотребление акциями, которые мы можем
                    периодически предлагать; или
                </p>
                <p>
                    <br />
                </p>
                <p>
                    m. &nbsp; &nbsp;существует любая другая уважительная причина, в рамках
                    которой нам необходимо это сделать.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    Мы предпримем разумные шаги, чтобы отправить вам соответствующее
                    уведомление. Однако могут быть случаи, когда мы обязаны не делать этого в
                    соответствии с Применимым законодательством.
                </p>
                <p>
                    <br />
                </p>
                <p>20.2. &nbsp; &nbsp;Ваше принятие. Вы подтверждаете и принимаете, что:</p>
                <p>
                    <br />
                </p>
                <p>
                    a. &nbsp; &nbsp;в п. ‎20.1 перечислен неполный перечень случаев, когда мы
                    можем предпринять действия по прекращению, приостановке, закрытию или
                    ограничению вашего доступа к Аккаунту FANTOM FX и/или Услугам FANTOM FX; и
                </p>
                <p>
                    <br />
                </p>
                <p>
                    b. &nbsp; &nbsp;наше решение предпринять определенные действия, включая,
                    помимо прочего, прекращение, приостановку или ограничение вашего доступа к
                    вашему Аккаунту FANTOM FX или Услугам FANTOM FX, может быть основано на
                    конфиденциальных критериях, которые важны для наших протоколов управления
                    рисками и безопасности. Вы соглашаетесь с тем, что мы не обязаны раскрывать
                    вам подробности наших процедур управления рисками и мерами по безопасности.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    20.3. &nbsp; &nbsp;Что происходит, когда мы используем наше право. Когда мы
                    прекращаем, приостанавливаем, удерживаем или ограничиваем ваш доступ к одной
                    или нескольким Услугам FANTOM FX:
                </p>
                <p>
                    <br />
                </p>
                <p>
                    a. &nbsp; &nbsp;если у вас есть открытые Указания, сделки, позиции или
                    Транзакции, они могут быть закрыты вами или нами в зависимости от
                    обстоятельств прекращения, приостановки, удержания, ограничения доступа или
                    других действий, которые мы предпринимаем;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    b. &nbsp; &nbsp;любой возврат платежа, возникший в результате использования
                    вашего Аккаунта FANTOM FX или Услуг FANTOM FX, может привести к немедленному
                    приостановлению и/или ограничению вашего Аккаунта FANTOM FX и Услуг FANTOM
                    FX;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    c. &nbsp; &nbsp;для восстановления приостановленного и/или ограниченного
                    Аккаунта FANTOM FX или Услуг FANTOM FX от вас может потребоваться возместить
                    нам полную стоимость возврата платежа, включая любые суммы, которые нам
                    причитаются, в том числе любые применимые Комиссии; и
                </p>
                <p>
                    <br />
                </p>
                <p>
                    d. &nbsp; &nbsp;вы несете ответственность за любые зачисленные суммы в
                    случае возврата платежа, и вы уполномочиваете нас и предоставляете нам право
                    вычитать расходы и комиссии непосредственно из любых активов в вашем
                    Аккаунте FANTOM FX без уведомления.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    20.4. &nbsp; &nbsp;Незаконное владение. Если мы проинформированы и разумно
                    полагаем, что какие-либо Цифровые активы или Фиатные валюты, хранящиеся в
                    вашем Аккаунте FANTOM FX, украдены или иным образом не находятся в вашем
                    законном владении (по ошибке или иным образом), мы можем, но не обязаны,
                    удержать соответствующие средства и ваш Аккаунт FANTOM FX. Если мы
                    удерживаем некоторые или все Цифровые активы или Фиатные валюты, хранящиеся
                    в вашем Аккаунте FANTOM FX, или &nbsp;весь ваш Аккаунт FANTOM FX, мы можем
                    продолжать удерживать до тех пор, пока приемлемые для нас доказательства не
                    подтвердят, что вы имеете право на владение Цифровыми активами и/или
                    Фиатными валютами, хранящимися в вашем Аккаунте FANTOM FX. Мы не будем
                    принимать участие в Спорах или в разрешении Споров, связанных с любыми
                    Цифровыми активами и/или Фиатными валютами, хранящимися в вашем Аккаунте
                    FANTOM FX.&nbsp;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    20.5. &nbsp; &nbsp;Доступ к Услугам FANTOM FX в других юрисдикциях. Жители
                    некоторых стран могут иметь доступ только к некоторым, но не ко всем Услугам
                    FANTOM FX. Мы можем периодически изменять доступные вам Услуги FANTOM FX.
                    &nbsp;Услуги FANTOM FX могут быть недоступны, а ваш доступ к Услугам FANTOM
                    FX может быть заблокирован. Вы принимаете, что это может повлиять на вашу
                    способность торговать на Платформе и/или отслеживать любые существующие
                    ордера или открытые позиции или иным образом использовать Услуги FANTOM FX.
                    Запрещается пытаться каким-либо образом обойти любое такое ограничение, в
                    том числе путем использования любой виртуальной частной сети для изменения
                    вашего адреса интернет-протокола.
                </p>
                <p>
                    <br />
                </p>
                <p>ИНТЕЛЛЕКТУАЛЬНАЯ СОБСТВЕННОСТЬ</p>
                <p>
                    <br />
                </p>
                <p>21. &nbsp; &nbsp;Предшествующая ИС</p>
                <p>
                    <br />
                </p>
                <p>ИС FANTOM FX принадлежит FANTOM FX.&nbsp;</p>
                <p>
                    <br />
                </p>
                <p>22. &nbsp; &nbsp;Лицензия ИС FANTOM FX</p>
                <p>
                    <br />
                </p>
                <p>
                    Мы предоставляем вам неисключительную лицензию на срок действия настоящих
                    Условий или до приостановления или прекращения вашего доступа к Услугам
                    FANTOM FX, в зависимости от того, что произойдет раньше, на использование ИС
                    FANTOM FX, за исключением Товарных знаков, исключительно в тех случаях,
                    когда это необходимо для получения вами Услуг FANTOM FX для некоммерческого
                    личного или внутреннего делового использования в соответствии с настоящими
                    Условиями.
                </p>
                <p>
                    <br />
                </p>
                <p>23. &nbsp; &nbsp;Лицензия ИС пользователя</p>
                <p>
                    <br />
                </p>
                <p>
                    23.1. &nbsp; &nbsp; Ваше предоставление лицензии. Вы предоставляете нам
                    бессрочную, безотзывную, безвозмездную, всемирную и неисключительную
                    лицензию на использование ИС пользователя в той мере, в какой она:&nbsp;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    a. &nbsp; &nbsp;является частью или необходима для использования любой
                    Созданной ИС;&nbsp;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    b. &nbsp; &nbsp;периодически необходима для того, чтобы мы могли
                    предоставлять вам Услуги FANTOM FX.&nbsp;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    23.2. &nbsp; &nbsp; Наше право на сублицензию. Лицензия, предоставляемая вам
                    в соответствии с настоящим пунктом, ‎включает наше право на сублицензию
                    третьим лицам в объеме, необходимом для того, чтобы мы и Партнеры FANTOM FX
                    могли предоставлять вам Услуги FANTOM FX или любую их часть.
                </p>
                <p>
                    <br />
                </p>
                <p>24. &nbsp; &nbsp;Созданная ИС</p>
                <p>
                    <br />
                </p>
                <p>
                    24.1 &nbsp; &nbsp;Созданная ИС. Созданная ИС автоматически принадлежит нам с
                    даты ее создания.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    24.2 &nbsp; &nbsp;Передача. Настоящим вы передаете нам (и соглашаетесь
                    обеспечить передачу любыми агентами, представителями или подрядчиками), с
                    полной гарантией собственности, право собственности на все настоящие и
                    будущие права и интересы в отношении Созданной ИС.&nbsp;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    Если вас об этом попросят, вы должны (и соглашаетесь обеспечить
                    соответствующее выполнение любыми агентами, представителями или
                    подрядчиками), безвозмездно для нас, подписать и/или оформить все документы
                    и совершить действия, которые мы можем потребовать для оформления передачи в
                    соответствии с настоящим пунктом.
                </p>
                <p>
                    <br />
                </p>
                <p>25. &nbsp; &nbsp;Общие вопросы</p>
                <p>
                    <br />
                </p>
                <p>
                    25.1 &nbsp; &nbsp;Мы не несем ответственности. Вы подтверждаете и
                    принимаете, что: (1) мы не несем ответственности за любые Пользовательские
                    материалы (предоставленные вами или третьими лицами), которые могут быть
                    доступны на Платформе или Сайтах; и (2) использование любых таких
                    Пользовательских материалов осуществляется на ваш риск, и мы не
                    предоставляем никаких гарантий в отношении них.&nbsp;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    25.2 &nbsp; &nbsp;Наши права. Мы имеем право по нашему абсолютному
                    усмотрению удалять, изменять или отклонять любой контент, который вы
                    отправляете, размещаете или демонстрируете на Платформе или Сайтах (включая
                    любой Пользовательский материал) по любой причине. Мы оставляем за собой
                    право предпринять любые подходящие действия по нашему усмотрению, включая
                    письменное предупреждение, удаление любого Пользовательского материала,
                    взыскание с вас убытков или другой денежной компенсации, приостановление или
                    прекращение действия вашего Аккаунта FANTOM FX (если таковой имеется),
                    приостановление вашего доступа к Платформе и/или Сайтам. Мы также имеем
                    право ограничить или запретить вам использование любых Услуг FANTOM FX.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    25.3 &nbsp; &nbsp;Запись. Вы подтверждаете, что мы можем записывать любые
                    сообщения, электронные, телефонные, по видеозвонкам или иные, которые
                    происходят между вами и нами в связи с настоящими Условиями, и что любые
                    записи, которые мы сохраняем, будут являться доказательствами общения между
                    вами и нами. Вы подтверждаете, что телефонные разговоры и видеозвонки могут
                    быть записаны, чтобы мы могли отвечать на запросы, обеспечивать соблюдение
                    применимого законодательства, улучшать наши услуги и предоставлять поддержку
                    клиентам.
                </p>
                <p>
                    <br />
                </p>
                <p>ВАШИ ОБЯЗАТЕЛЬСТВА И ОТВЕТСТВЕННОСТЬ</p>
                <p>
                    <br />
                </p>
                <p>26. &nbsp; &nbsp;ЗАПРЕЩЕННОЕ ИСПОЛЬЗОВАНИЕ</p>
                <p>
                    <br />
                </p>
                <p>
                    Создавая Аккаунт FANTOM FX, вы подтверждаете, без ущерба для любых других
                    ограничений или лимитов, изложенных в настоящих условиях, что вы и любой
                    Разрешенный пользователь не будете:
                </p>
                <p>
                    <br />
                </p>
                <p>
                    a. &nbsp; &nbsp;нарушать настоящие Условия или любое соглашение, заключенное
                    в соответствии или в связи с настоящими Условиями, включая, помимо прочего,
                    любые Условия продукта;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    b. &nbsp; &nbsp;использовать Услуги FANTOM FX таким образом, который
                    нарушает общественные интересы, общественную мораль или законные интересы
                    других лиц, включая любые действия, которые могут затронуть, испортить,
                    негативно повлиять на Услуги FANTOM FX или помешать другим пользователям
                    использовать Услуги FANTOM FX;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    c. &nbsp; &nbsp;использовать услуги FANTOM FX для перепродажи и в
                    коммерческих целях, включая транзакции от имени третьих лиц и организаций,
                    если это не разрешено нами в письменном виде;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    d. &nbsp; &nbsp;использовать Услуги FANTOM FX для действий, которые, по
                    мнению FANTOM FX, направлены на контроль или искусственное влияние на цену
                    любого Цифрового актива (манипулирование рынком, включая, помимо прочего,
                    схемы пампа и дампа, вош-трейдинг, совершение сделок с собой, фронтраннинг,
                    намеренные манипуляции котировками, спуфинг и лееринг), независимо от того,
                    запрещено ли это Применимым законодательством;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    e. &nbsp; &nbsp;совершать мошеннические действия или вызывать у нас
                    подозрения, что вы или любой Разрешенный пользователь совершаете
                    мошеннические действия и/или Транзакции;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    f. &nbsp; &nbsp;использовать Услуги FANTOM FX для проведения лотерей;
                    аукционов с комиссиями; прогнозов на спорт и составления коэффициентов; лиг
                    фэнтези-спорта с денежными призами; онлайн-игр; конкурсов; розыгрышей; или
                    азартных игр;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    g. &nbsp; &nbsp;(1) получать или пытаться получить средства как от нас, так
                    и от другого пользователя за одну и ту же Транзакцию в ходе Претензии; (2)
                    вести ваш бизнес или использовать Услуги FANTOM FX таким образом, который
                    приводит или может привести к жалобам, спорам, претензиям, отменам,
                    возвратам платежа, комиссиям, штрафам, пеням или другим обязательствам перед
                    нами, другими пользователями, третьими лицами или вами; и (3) допускать,
                    чтобы ваш Аккаунт FANTOM FX имел отрицательную стоимость или количество
                    Цифровых активов;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    h. &nbsp; &nbsp;предоставлять ложную, неточную или вводящую в заблуждение
                    информацию в связи с использованием вами Услуг FANTOM FX, в общении с нами
                    или иным образом в связи с настоящими Условиями;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    i. &nbsp; &nbsp;(1) использовать любые глубокие ссылки, поисковые роботы,
                    боты, пауки или другие автоматические устройства, программы, скрипты,
                    алгоритмы или методы, или любые аналогичные или эквивалентные ручные
                    процессы для доступа, получения, копирования или отслеживания любой части
                    Платформы, или копировать или обходить навигационную структуру или
                    представление Услуг FANTOM FX любым способом, чтобы получить или попытаться
                    получить любые материалы, документы или информацию любым способом, не
                    предоставленным намеренно через Услуги FANTOM FX; (2) пытаться получить
                    доступ к любой части или функции Платформы без авторизации, подключиться к
                    Услугам FANTOM FX, любому из наших серверов или любым другим системам или
                    сетям любых Услуг FANTOM FX, предоставляемых через Платформу, путем взлома,
                    подбора паролей или любых других незаконных или запрещенных способов; (3)
                    исследовать, сканировать или проверять уязвимости Услуг FANTOM FX или любой
                    сети, подключенной к Платформе, или нарушать любые меры безопасности или
                    аутентификации Услуг FANTOM FX или любой сети, подключенной к Услугам FANTOM
                    FX; (4) осуществлять обратный поиск, отслеживать или пытаться отслеживать
                    любую информацию любых других пользователей или посетителей Услуг FANTOM FX;
                    (5) предпринимать любые действия, которые создают необоснованную или
                    непропорционально большую нагрузку на инфраструктуру систем или сетей Услуг
                    FANTOM FX или FANTOM FX или инфраструктуру любых систем или сетей,
                    подключенных к Услугам FANTOM FX; (6) использовать любые устройства,
                    программное обеспечение или обычные программы для вмешательства в нормальную
                    работу Услуг FANTOM FX, любые транзакции в Услугах FANTOM FX или
                    использование Услуг FANTOM FX любым другим лицом; или (7) подделывать
                    заголовки, выдавать себя за другое лицо или иным образом манипулировать
                    идентификацией, чтобы скрыть вашу личность или происхождение любых сообщений
                    или передач, которые вы отправляете нам;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    j. &nbsp; &nbsp;изменять или адаптировать всю или некоторую часть Платформы
                    или объединять или включать Платформу в другую программу или приложение;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    k. &nbsp; &nbsp;разбирать, декомпилировать, проводить обратный инжиниринг
                    или иным образом пытаться извлечь исходный код, объектный код в основе
                    концепций, идей и алгоритмов Платформы или любых ее компонентов;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    l. &nbsp; &nbsp;изменять, воспроизводить, дублировать, копировать,
                    скачивать, хранить, передавать, распространять, пересылать, разбирать,
                    транслировать, публиковать, удалять или изменять любой знак или заявление об
                    авторском праве, лицензировать, сублицензировать, продавать, повторять,
                    разрабатывать, сдавать в аренду, сдавать в лизинг, создавать собственную
                    торговую марку, создавать обеспечение в отношении такой ИС FANTOM FX или
                    любой части интеллектуальной собственности, создавать производные работы или
                    иным образом использовать любую часть ИС FANTOM FX;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    m. &nbsp; &nbsp;способствовать распространению любых вирусов, троянских
                    программ, червей или других компьютерных программ, которые могут повредить,
                    скрытно перехватить, присвоить или пагубно повлиять на любую систему, данные
                    или информацию в связи с Услугами FANTOM FX;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    n. &nbsp; &nbsp;(1) использовать анонимный прокси; (2) использовать любую
                    временную, одноразовую, самоуничтожающуюся или аналогичную электронную почту
                    при создании Аккаунта FANTOM FX и/или использовании Услуг FANTOM FX; (3)
                    использовать любое устройство, программное обеспечение или процедуру для
                    обхода наших заголовков исключения роботов, или вмешиваться или пытаться
                    вмешиваться в работу наших Сайтов или Услуг FANTOM FX; и (4) предпринимать
                    любые действия, которые могут привести к потере нами каких-либо услуг от
                    наших интернет-провайдеров или других поставщиков;&nbsp;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    o. &nbsp; &nbsp;создавать или предположительно создавать какое-либо
                    обеспечение в отношении вашей Фиатной валюты или Цифровых активов,
                    хранящихся в любом из ваших Аккаунтов FANTOM FX, без нашего предварительного
                    письменного согласия;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    p. &nbsp; &nbsp;нарушать или пытаться нарушить (1) любое Применимое
                    законодательство; или (2) принадлежащие нам или любой третьей стороне
                    авторские права, патенты, торговые марки, коммерческие тайны или другие
                    права интеллектуальной собственности, или права на публичность или
                    конфиденциальность; и/или
                </p>
                <p>
                    <br />
                </p>
                <p>
                    q. &nbsp; &nbsp;получать доступ, использовать или пытаться получить доступ
                    или использовать Услуги FANTOM FX прямо или косвенно (1) в юрисдикциях,
                    которые, по мнению FANTOM FX, представляют высокий риск, включая, помимо
                    прочего, Кубу, Иран, Северную Корею, Сирию, или (2) лицами, которые, по
                    мнению FANTOM FX, представляют высокий риск, включая, помимо прочего,
                    физических или юридических лиц с ограниченным доступом или стороны в любом
                    списке Соединенных Штатов Америки, Великобритании, Европейского союза или
                    Организации Объединенных Наций, включая санкционные списки Управления по
                    контролю за иностранными активами США и список запрещенных лиц и организаций
                    Министерства торговли США.
                </p>
                <p>
                    <br />
                </p>
                <p>27. &nbsp; &nbsp;Заявления и гарантии</p>
                <p>
                    <br />
                </p>
                <p>Настоящим вы заявляете и гарантируете нам в любое время следующее:</p>
                <p>
                    <br />
                </p>
                <p>
                    a. &nbsp; &nbsp;все документы и информация, которые вы предоставляете нам,
                    являются правдивыми, точными, полными и актуальными во всех отношениях, и мы
                    можем полагаться на них при определении того, имеете ли вы право доступа к
                    Платформе или пользования Услугами FANTOM FX;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    b. &nbsp; &nbsp;все решения, принятые в связи с настоящими Условиями, были
                    приняты исключительно по вашему усмотрению и после вашей независимой оценки
                    ваших финансовых ресурсов, способности и готовности принимать
                    соответствующие риски, финансовых целей;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    c. &nbsp; &nbsp;у вас есть все полномочия, права и возможности для (1)
                    доступа и использования Платформы и/или Услуг FANTOM FX; и (2) заключения,
                    поставки и выполнения ваших обязательств по настоящим Условиям и любому
                    соглашению, заключенному в соответствии или в связи с настоящими Условиями,
                    включая, помимо прочего, любые Условия продукта;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    d. &nbsp; &nbsp;если вы являетесь корпорацией, партнером в партнерстве или
                    доверенным лицом траста:
                </p>
                <p>
                    <br />
                </p>
                <p>
                    I. &nbsp; &nbsp;корпорация, партнерство или траст действовали и будут
                    действовать в соответствии с Применимым законодательством, а также любыми
                    договорами о партнерстве или трасте (или другими подобными документами);
                </p>
                <p>
                    <br />
                </p>
                <p>
                    II. &nbsp; &nbsp;вы немедленно сообщите нам о любых изменениях, связанных с
                    отставкой, смещением, назначением или смертью любого из директоров,
                    партнеров, доверенных лиц, учредителей, конечных бенефициарных владельцев
                    или любого лица, уполномоченного управлять вашим Аккаунтом FANTOM FX;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    III. &nbsp; &nbsp;вы немедленно сообщите нам, если корпорация, партнерство
                    или траст будут распущены на добровольной или принудительной основе;&nbsp;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    e. &nbsp; &nbsp;все согласия, разрешения, авторизации, одобрения и договоры
                    третьих лиц и все авторизации, одобрения, разрешения, согласия, регистрации,
                    декларации, подачи документов в любой Регулирующий орган, правительственный
                    департамент, комиссию, агентство или другую организацию, имеющую юрисдикцию
                    над вами, которые необходимо или желательно получить для того, чтобы (1)
                    получить доступ и использовать Платформу и/или Услуги FANTOM FX; и (2)
                    заключать, поставлять и выполнять Транзакции, предусмотренные настоящими
                    Условиями и любым соглашением, заключенным в соответствии или в связи с
                    настоящими Условиями, были безоговорочно получены в письменной форме,
                    предоставлены нам в письменной форме и не были отозваны или изменены;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    f. &nbsp; &nbsp;настоящие Условия и любое соглашение, заключенное в
                    соответствии или в связи с настоящими Условиями, представляют собой
                    действительные обязательства с юридической силой, подлежащие принудительному
                    исполнению в отношении вас согласно их соответствующим условиям;
                </p>
                <p>
                    <br />
                </p>
                <p>g. &nbsp; &nbsp;вы не являетесь Лицом с ограниченным доступом;</p>
                <p>
                    <br />
                </p>
                <p>
                    h. &nbsp; &nbsp;если вы являетесь юридическим лицом, вы должным образом
                    зарегистрированы, должным образом организованы, законно существуете в
                    соответствии с законодательством вашей юрисдикции и обладаете всеми
                    полномочиями для ведения вашего бизнеса. Если вы являетесь физическим лицом,
                    вы не моложе 18 лет;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    i. &nbsp; &nbsp;ваш доступ и использование Платформы и/или Услуг FANTOM FX,
                    ваше исполнение и поставка, а также выполнение ваших обязательств по
                    настоящим Условиям и любому соглашению, заключенному в соответствии или в
                    связи с настоящими Условиями:
                </p>
                <p>
                    <br />
                </p>
                <p>
                    I. &nbsp; &nbsp;если вы являетесь юридическим лицом, партнером в партнерстве
                    или доверенным лицом траста, не приведет к нарушению или не будет
                    противоречить любому положению вашей конституции, устава, соглашения о
                    партнерстве, трастового договора или эквивалентных учредительных документов;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    II. &nbsp; &nbsp;не приведет к нарушению или невыполнению обязательств по
                    любому инструменту, соглашению, документу или положению, стороной которого
                    вы являетесь или действие которого распространяется на вас или любую вашу
                    собственность;&nbsp;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    III. &nbsp; &nbsp;не приведет к нарушению вами, нами или любой третьей
                    стороной любого Применимого законодательства, постановления или решения
                    любого суда, любого арбитражного решения или решения любого
                    правительственного или Регулирующего органа в любой юрисдикции.
                </p>
                <p>
                    <br />
                </p>
                <p>28. &nbsp; &nbsp;Отказ от ответственности за технологии</p>
                <p>
                    <br />
                </p>
                <p>
                    28.1. &nbsp; &nbsp;Никаких заверений или гарантий. Услуги FANTOM FX и любая
                    информация на Сайтах и Платформе, включая Услуги чата, предоставляются по
                    принципу «как есть» и «как доступно» без каких-либо заверений или гарантий,
                    явных или подразумеваемых, в максимальной степени, разрешенной Применимым
                    законодательством. В частности, мы отказываемся от любых подразумеваемых
                    гарантий права собственности, соответствия ожиданиям покупателя, пригодности
                    для конкретной цели и/или отсутствия нарушений прав. Мы не делаем никаких
                    заявлений и не даем никаких гарантий, что доступ к Сайтам, Платформе, любому
                    из ваших Аккаунтов FANTOM FX, Услугам FANTOM FX или любым содержащимся в них
                    материалам будет постоянным, непрерывным, своевременным или безошибочным.
                    Это может привести к невозможности торговать на Платформе в течение
                    определенного периода времени, а также к задержкам.&nbsp;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    28.2. &nbsp; &nbsp;Приостановка доступа. Мы можем периодически
                    приостанавливать доступ к вашему Аккаунту FANTOM FX и/или Услугам FANTOM FX
                    как для планового, так и для аварийного обслуживания. Мы приложим разумные
                    усилия для обеспечения своевременной обработки Транзакций на Платформе,
                    однако мы не делаем никаких заявлений и не даем гарантий в отношении времени
                    обработки, поскольку оно зависит от многих факторов, находящихся вне нашего
                    контроля.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    28.3. &nbsp; &nbsp;Контент. Хотя мы прилагаем разумные усилия для обновления
                    информации на Сайтах и Платформе, мы не делаем никаких заявлений, гарантий
                    или заверений, явных или подразумеваемых, что контент Сайтов и Платформы,
                    включая информацию об Услугах FANTOM FX, является точным, полным или
                    актуальным.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    28.4. &nbsp; &nbsp;Сайты третьих лиц. Мы можем предоставить ссылки на сайты
                    третьих лиц (включая, помимо прочего, контент, материалы и/или информацию на
                    сайтах третьих лиц) для удобства, но мы их не контролируем. Вы подтверждаете
                    и принимаете, что мы не несем ответственности за любой аспект контента,
                    материалов, информации или услуг, содержащихся на любых сайтах третьих лиц,
                    доступных или упоминаемых на Платформе или Сайтах.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    28.5. &nbsp; &nbsp;Доступ к сети и совместимость. Вы несете ответственность
                    за получение доступа к сети передачи данных, необходимого для использования
                    Услуг FANTOM FX. Вы несете ответственность за приобретение и обновление
                    совместимого оборудования или устройств, необходимых для доступа и
                    использования Услуг FANTOM FX и Сайтов, а также любых обновлений к ним.
                    FANTOM FX не гарантирует, что Услуги FANTOM FX или любая их часть будут
                    функционировать на каком-либо конкретном оборудовании или устройствах.
                    Услуги FANTOM FX могут быть подвержены сбоям и задержкам, связанным с
                    использованием интернета и электронных средств связи.
                </p>
                <p>
                    <br />
                </p>
                <p>29. &nbsp; &nbsp;Возмещение убытков</p>
                <p>
                    <br />
                </p>
                <p>
                    29.1. &nbsp; &nbsp;Претензии третьих лиц. Вы должны возмещать убытки и
                    ограждать нас от любых претензий, исков, действий, требований, споров,
                    обвинений или расследований любой третьей стороны, государственного органа
                    или отраслевого органа, а также от всех претензий, обязательств, ущерба
                    (фактического и последующего), убытков (включая любые прямые, косвенные или
                    последующие убытки, упущенную прибыль, потерю репутации), затрат и расходов,
                    включая, помимо прочего, все проценты, штрафы, судебные и другие разумные
                    гонорары адвокатов, другие профессиональные затраты и расходы («Убытки»),
                    возникающие в результате или в связи с:&nbsp;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    a. &nbsp; &nbsp;вашим доступом или использованием вашего Аккаунта FANTOM FX
                    и/или Услуг FANTOM FX;&nbsp;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    b. &nbsp; &nbsp;нарушением или предполагаемым нарушением вами настоящих
                    Условий или совершением вами действий, противоречащих любому другому пункту
                    или подпункту настоящих Условий, включая любые Условия продукта и любые
                    другие упоминаемые условия и положения;&nbsp;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    c. &nbsp; &nbsp;совершением вами действий, противоречащих Применимому
                    законодательству;
                </p>
                <p>
                    <br />
                </p>
                <p>d. &nbsp; &nbsp;нарушением вами прав любой третьей стороны.&nbsp;</p>
                <p>
                    <br />
                </p>
                <p>
                    29.2. &nbsp; &nbsp;Контроль. Мы оставляем за собой право контролировать
                    защиту в любой претензии третьей стороны, в рамках которой вы должны
                    возместить убытки, и в случае нашего контроля вы должны сотрудничать в
                    полном объеме, определяемом нами при отстаивании любой доступной защиты. Вы
                    не будете урегулировать любые претензии или Убытки без нашего
                    предварительного письменного согласия.&nbsp;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    29.3. &nbsp; &nbsp;Освобождение. Настоящим вы соглашаетесь освободить нас от
                    всех претензий и требований (и отказываетесь от любых прав, которые вы
                    можете иметь против нас в отношении любых возможных Убытков), возникающих
                    прямо или косвенно в результате или в связи с любым вашим спором с любым
                    другим пользователем или другой третьей стороной в связи с Услугами FANTOM
                    FX (включая любые Транзакции с Цифровыми активами) или предметом настоящих
                    Условий.
                </p>
                <p>
                    <br />
                </p>
                <p>30. &nbsp; &nbsp;Ответственность</p>
                <p>
                    <br />
                </p>
                <p>
                    30.1 &nbsp; &nbsp;Наша ответственность. Наша ответственность и
                    ответственность Партнеров FANTOM FX перед вами или любыми третьими лицами
                    при любых обстоятельствах ограничивается фактической суммой ущерба или
                    убытков, которые вызваны прямо и разумно предсказуемы в результате нарушения
                    нами настоящих условий, и ни в коем случае не должна превышать сумму
                    комиссий, уплаченных вами FANTOM FX за 12 месяцев до события, которое
                    привело к возникновению Убытка. Такая сумма должна быть выплачена нами вам в
                    качестве полного и окончательного расчета и полностью удовлетворит нашу
                    ответственность и ответственность любого Партнера FANTOM FX за все убытки и
                    претензии, связанные с соответствующим нарушением, независимо от причин их
                    возникновения. Вы подтверждаете и принимаете, что ни FANTOM FX, ни Партнеры
                    FANTOM FX не знают о каких-либо особых обстоятельствах, относящихся к вам,
                    что возмещение убытков является достаточным средством правовой защиты и что
                    вы не имеете права на какие-либо другие претензии или средства правовой
                    защиты по закону или по праву справедливости, включая, помимо прочего, любые
                    вещные иски, судебные постановления и/или конкретное исполнение.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    30.2 &nbsp; &nbsp;Ограничения ответственности. Вне зависимости от любых
                    других пунктов настоящих Условий, мы или любой Партнер FANTOM FX ни при
                    каких обстоятельствах не несем ответственности перед вами или любым другим
                    физическим или юридическим лицом за:
                </p>
                <p>
                    <br />
                </p>
                <p>
                    a. &nbsp; &nbsp;любые прямые или косвенные убытки (включая упущенную
                    прибыль, потерю бизнеса или возможностей), ущерб или расходы, возникающие в
                    результате или в связи с настоящими Условиями, включая, помимо прочего:
                </p>
                <p>
                    <br />
                </p>
                <p>
                    I. &nbsp; &nbsp;работу протоколов в основе любого Цифрового актива, их
                    функции, безопасность и доступность;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    II. &nbsp; &nbsp;сохраняют ли Обеспеченные цифровые активы свою стоимость по
                    отношению к какому-либо активу, или имеет ли эмитент Обеспеченного цифрового
                    актива достаточные резервы в отношении любого Обеспеченного цифрового
                    актива;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    III. &nbsp; &nbsp;любое действие или бездействие в соответствии с настоящими
                    Условиями;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    IV. &nbsp; &nbsp;любые неточности, дефекты или упущения в данных о цене
                    Цифровых активов, любые ошибки или задержки в передаче таких данных, а также
                    перерывы в передаче любых таких данных;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    V. &nbsp; &nbsp;регулярное или внеплановое обслуживание, которое мы
                    проводим, включая любые изменения и перерывы в предоставлении услуг,
                    вызванные таким обслуживанием;&nbsp;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    VI. &nbsp; &nbsp;кражу устройства, с помощью которого осуществляется доступ
                    и использование Услуг FANTOM FX;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    VII. &nbsp; &nbsp;действия, бездействие или нарушение настоящих Условий
                    другими пользователями, а также любой ущерб, причиненный действиями любых
                    других третьих лиц;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    VIII. &nbsp; &nbsp;(1) любой ущерб или перерывы в работе, вызванные любыми
                    компьютерными вирусами, шпионскими программами или другими вредоносными
                    программами, которые могут повлиять на ваш компьютер или другое
                    оборудование, или любым фишингом, спуфингом или другими атаками; (2) выход
                    из строя, повреждение или уничтожение вашего оборудования, повреждение или
                    потерю любых записей или данных на вашем оборудовании по любой причине; или
                    (3) использование вами интернета для подключения к Услугам FANTOM FX или
                    любые технические проблемы, системные сбои, неисправности, отказ линий
                    связи, высокую нагрузку или спрос на интернет-трафик, сопутствующие
                    проблемы, нарушения безопасности или любые подобные технические проблемы или
                    дефекты;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    IX. &nbsp; &nbsp;наше решение отклонить вашу заявку на создание
                    Аккаунта(-ов) FANTOM FX в соответствии с п. ‎7.1;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    X. &nbsp; &nbsp;любое прекращение, приостановление, удержание или
                    ограничение доступа к любому Аккаунту FANTOM FX или Услугам FANTOM FX,
                    включая вашу неспособность выводить Цифровые активы, отправлять Указания или
                    заключать Транзакции в период любого приостановления, удержания или
                    ограничения в соответствии с настоящими Условиями или любыми Условиями
                    продукта;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    XI. &nbsp; &nbsp;любые лимиты Транзакций, применяемые к вашему Аккаунту
                    FANTOM FX в соответствии с п. ‎14.1;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    XII. &nbsp; &nbsp;любое наше решение поддерживать или не поддерживать
                    Цифровые активы в соответствии с п. ‎15.1;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    XIII. &nbsp; &nbsp;нашу невозможность связаться с вами, используя
                    предоставленную вами контактную информацию в соответствии с п. ‎3.2;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    XIV. &nbsp; &nbsp;закрытие нами неактивного аккаунта в соответствии с п.
                    ‎19.3;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    XV. &nbsp; &nbsp;неисполнение Транзакции или несоответствие времени,
                    необходимого для завершения любой Транзакции, в соответствии с п. ‎12.1;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    XVI. &nbsp; &nbsp;деятельность по маркет-мейкингу в соответствии с п. ‎13.2;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    XVII. &nbsp; &nbsp;наше доверие к любому Указанию, отправленному с вашего
                    Аккаунта FANTOM FX или Электронной почты;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    XVIII. &nbsp; &nbsp;наш отказ или задержку при выполнении любого Указания в
                    соответствии с п. ‎11.1;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    XIX. &nbsp; &nbsp;любое нарушение защиты вашей Электронной почты или
                    Нарушение безопасности;&nbsp;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    XX. &nbsp; &nbsp;убытки, причиненные вам в результате мошенничества или афер
                    третьих лиц, в которых FANTOM FX участвует только как получатель вашей
                    Фиатной валюты или Цифровых активов, и/или осуществляет конвертацию Фиатной
                    валюты в Цифровые активы, и/или осуществляет перевод Цифровых активов с
                    нашей платформы по вашему запросу;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    XXI. &nbsp; &nbsp;любые убытки, возникающие в результате или в связи с
                    новыми предложениями Цифровых активов, первичными предложениями монет (ICO)
                    или решением о добавлении или недобавлении Цифровых активов на Платформу;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    XXII. &nbsp; &nbsp;правильность, качество, точность, безопасность, полноту,
                    надежность, эффективность, своевременность, цену или постоянную доступность
                    Услуг FANTOM FX или задержки или упущения в Услугах FANTOM FX, или
                    неспособность любой службы соединения или связи обеспечить или поддерживать
                    ваш доступ к Услугам FANTOM FX, или любой перерыв или нарушение вашего
                    доступа, или любые ошибочные сообщения между нами, независимо от причины;
                    и&nbsp;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    XXIII. &nbsp; &nbsp;любые Транзакции, Указания или операции, осуществленные
                    вами или предположительно осуществленные вами через вашу Электронную почту
                    или Аккаунт FANTOM FX;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    b. &nbsp; &nbsp;любую упущенную прибыль, потерю бизнеса, ожидаемых
                    сбережений или возможностей, или любые специальные, штрафные, отягчающие,
                    случайные, косвенные или последующие убытки или ущерб, возникающие в
                    результате или в связи с нашими Сайтами, Платформой, вашим Аккаунтом FANTOM
                    FX, Услугами FANTOM FX, настоящими Условиями, Условиями продукта,
                    Уведомлением о конфиденциальности и/или любым соглашением, заключенным в
                    результате или в связи с настоящими Условиями или иным образом; и/или
                </p>
                <p>
                    <br />
                </p>
                <p>
                    c. &nbsp; &nbsp;любые убытки, являющиеся частью Претензии, которая не была
                    начата путем официального судебного разбирательства В ТЕЧЕНИЕ ОДНОГО
                    КАЛЕНДАРНОГО ГОДА после события, которое привело к возникновению Претензии.
                    ВЫ ПОДТВЕРЖДАЕТЕ И ПРИНИМАЕТЕ, ЧТО ДАННЫЙ ПУНКТ ИЗМЕНЯЕТ ЛЮБОЙ СРОК ИСКОВОЙ
                    ДАВНОСТИ, ПРИ ДРУГИХ ОБСТОЯТЕЛЬСТВАХ ПРИМЕНИМЫЙ ПО ЗАКОНОДАТЕЛЬСТВУ, И ЧТО
                    ЕСЛИ ПРИМЕНИМОЕ ЗАКОНОДАТЕЛЬСТВО ЗАПРЕЩАЕТ ЭТО, ТО ДАННЫЙ ПУНКТ БУДЕТ
                    РАССМАТРИВАТЬСЯ КАК СРОК ИСКОВОЙ ДАВНОСТИ МИНИМАЛЬНО ДОПУСТИМОЙ
                    ПРОДОЛЖИТЕЛЬНОСТИ. БЕЗ УЩЕРБА ДЛЯ ОБЩЕГО ХАРАКТЕРА ВЫШЕСКАЗАННОГО, ОБРАЩАЕМ
                    ВНИМАНИЕ НА П. 32, СОГЛАСНО КОТОРОМУ УРЕГУЛИРОВАНИЕ ПРЕТЕНЗИЙ ВЫПОЛНЯЕТСЯ
                    ИСКЛЮЧИТЕЛЬНО ПУТЕМ ОБЯЗАТЕЛЬНОГО АРБИТРАЖА.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    30.3 &nbsp; &nbsp;Повреждение или перерывы. Мы не несем ответственности за
                    любой ущерб или перерывы в работе, вызванные любыми компьютерными вирусами,
                    шпионскими программами, поддельными антивирусами, троянскими программами,
                    червями или другими вредоносными программами, которые могут повлиять на ваш
                    компьютер или другое оборудование, или любым фишингом, спуфингом или другими
                    атаками. Мы рекомендуем регулярно использовать надежное и легкодоступное
                    программное обеспечение для выявления и защиты от вирусов. Учитывайте, что
                    службы SMS и электронной почты подвержены спуфингу и фишингу, и будьте
                    осторожны при получении сообщений, якобы исходящих от нас. Вы и, в
                    соответствующих случаях, ваши Разрешенные пользователи несете
                    ответственность за все учетные данные для входа в систему, включая имена
                    пользователей и пароли, и должны всегда сохранять их в безопасности.
                </p>
                <p>
                    <br />
                </p>
                <p>РАЗРЕШЕНИЕ СПОРОВ: СУДЫ, АРБИТРАЖ, ОТКАЗ ОТ КОЛЛЕКТИВНЫХ ИСКОВ</p>
                <p>
                    <br />
                </p>
                <p>
                    Во избежание неверного толкования: ничто в данном разделе не лишает вас
                    какого-либо обязательного юридического права, которое принадлежит вам в
                    соответствии с Применимым законодательством.
                </p>
                <p>
                    <br />
                </p>
                <p>31. &nbsp; &nbsp;Уведомление о претензии и Период разрешения спора</p>
                <p>
                    <br />
                </p>
                <p>
                    31.1. &nbsp; &nbsp;Если у вас возникли какие-либо проблемы с Услугами,
                    пожалуйста, сначала свяжитесь с FANTOM FX. FANTOM FX хочет решить ваши
                    проблемы без официального судебного разбирательства, если это возможно. Если
                    вы не будете удовлетворены решением ваших проблем при первом обращении, вам
                    будет присвоен номер билета. При создании номера билета начинается процедура
                    внутреннего разрешения спора. FANTOM FX попытается разрешить спор за счет
                    внутренних процедур как можно скорее. Стороны соглашаются добросовестно
                    вести переговоры для разрешения спора (эти переговоры должны оставаться
                    конфиденциальными и подчиняться применимым правилам, защищающим переговоры
                    по урегулированию от использования в качестве доказательства в любом
                    судебном процессе).
                </p>
                <p>
                    <br />
                </p>
                <p>
                    31.2. &nbsp; &nbsp;Если спор не может быть разрешен удовлетворительно и вы
                    хотите выдвинуть Претензию против FANTOM FX, то вы соглашаетесь изложить
                    основания такой Претензии в письменном «Уведомлении о претензии» в качестве
                    предварительного уведомления FANTOM FX. Уведомление о претензии должно (1)
                    описывать характер и основание претензии или спора, (2) содержать конкретное
                    средство судебной защиты, (3) номер изначального билета и (4) электронную
                    почту вашего кастодиального аккаунта. Уведомление о претензии следует
                    отправить на по следующему адресу:{" "}
                    <a data-fr-linked="true" href="https://fantom-fx.org/contacts">
                        https://fantom-fx.org/contacts
                    </a>
                    . После того как вы направите Уведомление о претензии в FANTOM FX, спор,
                    указанный в Уведомлении о претензии, может быть передан либо FANTOM FX, либо
                    вами в арбитраж в соответствии с приведенным ниже пунктом (Соглашение об
                    арбитраже).&nbsp;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    31.3. &nbsp; &nbsp;Во избежание недопониманий передача спора в FANTOM FX для
                    разрешения путем внутренних процедур и отправка Уведомления о претензии в
                    FANTOM FX являются необходимыми условиями для начала арбитражного
                    разбирательства (или любого другого судебного разбирательства).
                </p>
                <p>
                    <br />
                </p>
                <p>
                    31.4. &nbsp; &nbsp;Во время арбитража запрещается раскрывать арбитру сумму
                    любого предложения об урегулировании, сделанного вами или FANTOM FX.
                </p>
                <p>
                    <br />
                </p>
                <p>32. &nbsp; &nbsp;Соглашение об арбитраже</p>
                <p>
                    <br />
                </p>
                <p>
                    32.1. &nbsp; &nbsp;За исключением случаев, когда Применимое законодательство
                    требует или предоставляет вам иной выбор, вы и FANTOM FX, учитывая
                    предыдущий пункт выше (Уведомление о претензии и Период разрешения спора),
                    соглашаетесь с тем, что любая претензия должна быть рассмотрена в рамках
                    обязательного, окончательного и индивидуального (не коллективного)
                    арбитражного процесса под управлением Гонконгского международного
                    арбитражного центра (HKIAC) и в соответствии с действующими правилами HKIAC,
                    которые считаются включенными в настоящий пункт путем добавления ссылки.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    32.2. &nbsp; &nbsp;Место проведения арбитражного судебного процесса —
                    Гонконг.&nbsp;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    32.3. &nbsp; &nbsp;Судейская коллегия состоит из одного (1) третейского
                    судьи, назначаемого в соответствии с действующим Регламентом HKIAC.&nbsp;
                </p>
                <p>
                    <br />
                </p>
                <p>32.4. &nbsp; &nbsp;Языком арбитражного суда является английский.</p>
                <p>
                    <br />
                </p>
                <p>
                    32.5. &nbsp; &nbsp;Вы и FANTOM FX также соглашаетесь с тем, что третейский
                    судья будет обладать исключительными полномочиями выносить решения по
                    вопросам собственной юрисдикции, включая, помимо прочего, любые возражения в
                    отношении существования, объема или действительности Соглашения об
                    арбитраже, а также возможности разрешения какой-либо Претензии в арбитражном
                    суде.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    32.6. &nbsp; &nbsp;Положения об арбитраже, изложенные в настоящем пункте,
                    сохраняют силу после прекращения действия настоящих Условий.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    32.7. &nbsp; &nbsp;Срок исковой давности. Любой арбитраж против FANTOM FX
                    должен быть начат путем подачи и вручения Уведомления об арбитраже в
                    соответствии с Регламентом HKIAC в течение одного (1) года после даты, когда
                    пользователь, предъявляющий Претензию, впервые узнал или разумно должен был
                    узнать о предполагаемом действии, бездействии или невыполнении обязательств,
                    дающим основание для Претензии («Срок исковой давности»). Во избежание
                    недопониманий Срок исковой давности включает Период разрешения спора,
                    указанный в п. 31.1. Пользователь не имеет права на какое-либо средство
                    правовой защиты или средство судебной защиты на основании любой Претензии
                    пользователя, если Уведомление об арбитраже в отношении этой Претензии не
                    было подано и вручено FANTOM FX в течение Срока исковой давности. Если Срок
                    исковой давности противоречит применимому законодательству, пользователь
                    обязан предъявить любую Претензию к FANTOM FX в течение кратчайшего периода
                    времени, разрешенного применимым законодательством. Уведомление об арбитраже
                    может быть направлено FANTOM FX в соответствии с Применимым
                    законодательством и правилами вручения.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    32.8. &nbsp; &nbsp;Уведомление: если FANTOM FX начнет арбитражное
                    разбирательство против вас, FANTOM FX отправит вам уведомление на указанную
                    вами электронную почту или почтовый адрес. Вы подтверждаете, что любое
                    уведомление, отправленное на эту электронную почту или почтовый адрес, будет
                    считаться действительным для всех целей, включая, помимо прочего,
                    определение соответствия услуги. Вы обязаны убедиться в том, что электронная
                    почта и/или почтовый адрес, предоставленные FANTOM FX, являются актуальными
                    и точными.
                </p>
                <p>
                    <br />
                </p>
                <p>33. &nbsp; &nbsp;Конфиденциальность</p>
                <p>
                    <br />
                </p>
                <p>
                    33.1. &nbsp; &nbsp;Стороны согласны с тем, что арбитраж должен быть
                    конфиденциальным. Существование арбитража, существование или содержание
                    Претензии, все документы и информация, которые стороны предоставили или
                    которыми обменялись в связи с арбитражем, а также любые заявления,
                    постановления или решения, принятые в арбитраже, должны оставаться
                    конфиденциальными, и ни одна из сторон не должна раскрывать что-либо из
                    вышеизложенного какой-либо третьей стороне за исключением арбитражного
                    трибунала, HKIAC, сторон, их адвокатов, экспертов, свидетелей, бухгалтеров и
                    аудиторов, страховщиков и перестраховщиков, а также любых других лиц,
                    необходимых для проведения арбитража. Вне зависимости от вышесказанного,
                    сторона может раскрыть такую конфиденциальную информацию:
                </p>
                <p>
                    <br />
                </p>
                <p>a. &nbsp; &nbsp;если получено письменное согласие другой стороны;</p>
                <p>
                    <br />
                </p>
                <p>
                    b. &nbsp; &nbsp;в той мере, в какой это требуется применимым
                    законодательством или постановлениями любого регулирующего или надзорного
                    органа компетентной юрисдикции, которая распространяется или может
                    распространяться на сторону, или в соответствии с любым постановлением суда
                    или другого компетентного органа или трибунала компетентной юрисдикции;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    c. &nbsp; &nbsp;в связи с началом, ведением или защитой стороной любого
                    добросовестного судебного разбирательства по исполнению или оспариванию
                    любого решения, вынесенного в арбитраже; и
                </p>
                <p>
                    <br />
                </p>
                <p>
                    d. &nbsp; &nbsp;в той мере, в какой соответствующая конфиденциальная
                    информация является общественным достоянием и не нарушает настоящее
                    соглашение.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    Условие конфиденциальности, изложенное в настоящем пункте, сохраняет силу
                    после прекращения действия настоящих Условий и завершения или
                    приостановления любого арбитража, возбужденного в соответствии с настоящими
                    Условиями.
                </p>
                <p>
                    <br />
                </p>
                <p>34. &nbsp; &nbsp;Регулирующее законодательство</p>
                <p>
                    <br />
                </p>
                <p>
                    За исключением случаев, когда Применимое законодательство требует или
                    предоставляет вам иной выбор, настоящие Условия (включая настоящее
                    соглашение о арбитраже) регулируются и толкуются в соответствии с
                    законодательством Гонконга.
                </p>
                <p>
                    <br />
                </p>
                <p>35. &nbsp; &nbsp;Отказ от коллективных исков</p>
                <p>
                    <br />
                </p>
                <p>
                    Вы и FANTOM FX соглашаетесь, что любые должны быть предъявлены FANTOM FX в
                    арбитражном суде только на индивидуальной основе, а не в качестве истца или
                    участника в предполагаемом коллективном или представительском иске. Вы также
                    соглашаетесь отказаться от любого права на предъявление, рассмотрение или
                    арбитраж таких Претензий в качестве группового, коллективного,
                    представительского или частного иска, насколько это допустимо применимым
                    законодательством. Объединение или консолидация отдельных арбитражей в один
                    арбитраж не допускается без согласия FANTOM FX.&nbsp;
                </p>
                <p>
                    <br />
                </p>
                <p>ОБЩИЕ ПОЛОЖЕНИЯ&nbsp;</p>
                <p>
                    <br />
                </p>
                <p>36. &nbsp; &nbsp;Общие положения</p>
                <p>
                    <br />
                </p>
                <p>
                    36.1. &nbsp; &nbsp;Применимое законодательство. Вы и любой Разрешенный
                    пользователь должны соблюдать Применимое законодательство, лицензионные
                    требования и права третьих лиц (включая законы о конфиденциальности данных и
                    законы о борьбе с отмыванием денег и финансированием терроризма) при
                    использовании вами Услуг FANTOM FX, вашего Аккаунта FANTOM FX и Платформы.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    36.2. &nbsp; &nbsp;Уведомления. Мы можем отправлять уведомления на вашу
                    Электронную почту. Вы несете ответственность за указание актуальной и верной
                    Электронной почты. Мы можем отправлять уведомления на вашу Электронную
                    почту, в случае чего уведомления будут считаться полученными, даже если
                    получено уведомление об ошибке доставки.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    Вы можете отправлять нам уведомления только в соответствии с нашими
                    указаниями, которые могут периодически меняться.&nbsp;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    Любые уведомления, согласия или другие сообщения, предоставляемые в
                    соответствии с настоящими Условиями, должны быть отправлены в письменной
                    форме на английском языке и подписаны или иным образом уполномочены
                    стороной, предоставляющей их.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    36.3. &nbsp; &nbsp;Анонсы. Все официальные анонсы, новости, промоакции,
                    конкурсы и аирдропы будут размещаться на Сайте. Эти анонсы важны и могут
                    содержать информацию о факторах, которые могут влиять на стоимость или
                    безопасность ваших Цифровых активов. Вы несете ответственность за
                    отслеживание Сайта, чтение и рассмотрение этих анонсов.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    36.4. &nbsp; &nbsp;Полное соглашение. Настоящие Условия вместе с любыми
                    применимыми Условиями продукта составляют полное соглашение между вами и
                    нами в отношении Услуг FANTOM FX. Каждая сторона признает, что она не
                    полагалась на содержание и не имеет никаких прав или средств правовой защиты
                    в отношении любого заявления, утверждения, заверения или гарантии (сделанной
                    по небрежности или невиновно), кроме тех, которые прямо изложены в Условиях
                    или Условиях продукта.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    36.5. &nbsp; &nbsp;Передача. Вы не можете передавать или переносить
                    какие-либо ваши права или обязательства по Условиям без нашего
                    предварительного письменного согласия. Однако мы можем передать или
                    перенести любые наши права или обязательства по Условиям в любое время
                    любому другому лицу, включая, помимо прочего, в связи с любым слиянием,
                    приобретением или другой корпоративной реорганизацией с участием FANTOM FX.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    36.6. &nbsp; &nbsp;Недействительность. Если в любое время какой-либо пункт
                    или подпункт Условий является или становится незаконным, недействительным
                    или не имеющим юридической силы в какой-либо степени, это никоим образом не
                    повлияет на законность, действительность или юридическую силу остальных
                    пунктов или подпунктов.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    36.7. &nbsp; &nbsp;Записи. Вы соглашаетесь с тем, что мы можем записывать
                    любые разговоры с вами по телефону, электронной почте и в чате, а также
                    любые другие формы связи, включая коммуникации для отправки Указаний или
                    осуществления Транзакций, между вами и нами, и что эти записи могут быть
                    использованы в качестве доказательств в ходе любых разбирательств, связанных
                    с любым соглашением с вами. Эти записи будут являться нашей исключительной
                    собственностью.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    36.8. &nbsp; &nbsp;Язык. Настоящие Условия могут быть переведены на язык,
                    отличный от английского. Любой такой перевод предоставляется исключительно
                    для вашего удобства. В случае расхождений или неопределенности текст на
                    английском языке будет иметь преимущественную силу.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    36.9. &nbsp; &nbsp;Права третьих лиц. За исключением случаев, касающихся
                    Партнеров FANTOM FX, ничто выраженное или упомянутое в настоящих Условиях не
                    должно быть истолковано как предоставление любому лицу, кроме сторон
                    настоящих Условий, какого-либо юридического или основанного на
                    справедливости права, средства правовой защиты или претензии в соответствии
                    или в связи с настоящими Условиями или любым пунктом или подпунктом
                    настоящих Условий. Настоящие Условия и все их пункты и подпункты
                    предназначены только для единственной и исключительной выгоды сторон
                    настоящих Условий и их преемников и разрешенных цессионариев.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    36.10. &nbsp; &nbsp;Сохранение силы. Все пункты и подпункты настоящих
                    Условий, которые по своей природе распространяются на период после истечения
                    срока или прекращения действия настоящих Условий, продолжают быть
                    обязательными и действовать после истечения срока или прекращения действия
                    настоящих Условий.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    36.11. &nbsp; &nbsp;Отношения сторон. FANTOM FX не является вашим агентом
                    при выполнении настоящих Условий. Настоящие Условия не должны толковаться
                    как факты или свидетельства ассоциации, совместного предприятия, партнерства
                    или франшизы между сторонами.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    36.12. &nbsp; &nbsp;Цифровые активы. Мы ведем в вашем Аккаунте FANTOM FX
                    запись количества и типа любых цифровых активов, которые хранятся в вашем
                    Аккаунте. FANTOM FX не является доверенным лицом и не несет никаких
                    обязанностей доверенного лица в отношении любых Цифровых активов, хранящихся
                    в вашем Аккаунте. Когда вы отправляете FANTOM FX Указание перевести Цифровые
                    активы или иным образом поступить с ними, FANTOM FX не будет использовать
                    какие-либо конкретные Цифровые активы, как бы они ни были идентифицированы,
                    для выполнения ваших Указаний. В соответствии с настоящими Условиями FANTOM
                    FX будет использовать Цифровые активы в том же количестве и того же типа,
                    которые были зачислены в ваш Аккаунт FANTOM FX при выполнении ваших
                    Указаний.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    36.13. &nbsp; &nbsp;Форс-мажор. Мы не несем ответственности за любую
                    задержку или невыполнение требований настоящих Условий в той мере, в какой
                    задержка или невыполнение вызваны форс-мажорными обстоятельствами.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    36.14. &nbsp; &nbsp;Сохранение прав. Никакая задержка или бездействие с
                    нашей стороны в осуществлении любого права или применении любого средства
                    правовой защиты в соответствии с настоящими Условиями не может расцениваться
                    как отказ от осуществления такого права или применения такого средства
                    правовой защиты либо любых других прав или средств правовой защиты в будущем
                    в соответствии с Условиями. Права и средства правовой защиты,
                    предусмотренные Условиями, являются совокупными и не исключают любые права
                    или средства правовой защиты, предусмотренные Применимым законодательством.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    36.15. &nbsp; &nbsp;Зачет. В дополнение к любым юридическим или иным
                    средствам правовой защиты, доступным в соответствии с Условиями или по
                    закону, мы можем зачесть любые суммы, которые вы должны нам в соответствии с
                    Условиями или иным образом. Вы должны выплачивать все суммы, которые вы
                    должны нам, без зачетов, встречных претензий, вычетов или удержаний любого
                    рода, кроме случаев, предусмотренных применимым законодательством.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    36.16. &nbsp; &nbsp;Конфиденциальность третьих лиц. Если вы получаете
                    информацию о другом пользователе через Платформу или в результате
                    использования Услуг FANTOM FX, вы должны сохранять конфиденциальность
                    информации и использовать ее только в связи с Услугами FANTOM FX и всегда в
                    соответствии с Применимым законодательством. Вы не должны раскрывать или
                    распространять любую информацию о пользователе третьим лицам или
                    использовать ее каким-либо образом, за исключением случаев, когда это
                    разумно необходимо для совершения Транзакции.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    36.17. &nbsp; &nbsp;Публикация нарушений. Если вы нарушили настоящие
                    Условия, FANTOM FX может опубликовать или иным образом предоставить своим
                    пользователям сведения о нарушении, включая любую информацию, которую вы
                    предоставили FANTOM FX. FANTOM FX может это сделать только при необходимости
                    для защиты других пользователей, и если это разрешено Применимым
                    законодательством.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    36.18. &nbsp; &nbsp;Смерть владельца Аккаунта FANTOM FX. В случае вашей
                    смерти или недееспособности администратор(ы) вашего наследства или
                    оставшийся(-иеся) в живых наследник(и) должен (должны) уведомить нас в
                    письменном виде как можно скорее. Если у нас есть основания полагать, что вы
                    умерли, мы можем приостановить действие вашего Аккаунта FANTOM FX. Ваш
                    Аккаунт FANTOM FX будет приостановлен до тех пор, пока не будут выполнены
                    следующие условия:&nbsp;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    a. &nbsp; &nbsp;администратор вашего наследства или уполномоченный
                    бенефициар либо (1) создаст Аккаунт FANTOM FX в соответствии с настоящими
                    Условиями; либо (2) предоставит указания по банковскому переводу; и
                </p>
                <p>
                    <br />
                </p>
                <p>
                    b. &nbsp; &nbsp;предоставит достаточные юридические документы,
                    подтверждающие, что он имеет право на получение активов в вашем Аккаунте
                    FANTOM FX; или
                </p>
                <p>
                    <br />
                </p>
                <p>
                    c. &nbsp; &nbsp;вы предоставите удовлетворительное доказательство того, что
                    вы не умерли.&nbsp;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    Бенефициары, получающие банковский перевод, получат ликвидационную стоимость
                    активов в Аккаунте FANTOM FX за вычетом любых комиссий и расходов, связанных
                    с переводом. Наша способность предоставить вашему(-им) представителю(-ям)
                    активы в вашем Аккаунте FANTOM FX зависит от ограничений, налагаемых
                    Применимым законодательством и настоящими Условиями. Мы не обязуемся
                    соблюдать какие-либо конкретные сроки перевода активов, хранящихся в вашем
                    Аккаунте FANTOM FX.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    36.19. &nbsp; &nbsp;Налог. Вы несете ответственность за определение того,
                    какие налоги, если таковые имеются, применяются к платежам, которые вы
                    совершаете или получаете, а также за сбор, декларацию и перечисление
                    правильного налога в соответствующий налоговый орган. Вы соглашаетесь, что
                    мы не несем ответственности за определение того, применяются ли какие-либо
                    налоги к вашему использованию Услуг FANTOM FX, или за сбор, декларацию или
                    перечисление любых налогов, возникающих в результате любой Транзакции или
                    использования Услуг FANTOM FX.&nbsp;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    Вы признаете, что мы можем предоставлять определенные отчеты налоговым
                    органам в отношении Транзакций, совершенных на Платформе, и что мы можем по
                    нашему усмотрению или в соответствии с Применимым законодательством
                    предоставлять вам дополнительную документацию или записи, необходимые вам
                    для расчета любых налоговых обязательств. Мы также можем по своему
                    усмотрению удерживать и вычитать у источника любые налоги, причитающиеся в
                    соответствии с Применимым законодательством.
                </p>
                <p>
                    <br />
                </p>
                <p>37. &nbsp; &nbsp;Определения и интерпретация</p>
                <p>
                    <br />
                </p>
                <p>&nbsp; &nbsp; В настоящих Условиях:</p>
                <p>
                    <br />
                </p>
                <p>
                    a. &nbsp; &nbsp;заголовки и нумерация пунктов приведены исключительно для
                    удобства и не влияют на значение, приоритет или толкование любого пункта или
                    подпункта настоящих Условий;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    b. &nbsp; &nbsp;слова «включает» или «включая» означают «включает без
                    ограничений» и «включая без ограничений» соответственно;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    c. &nbsp; &nbsp;любое обязательство совершить или не совершать какое-либо
                    действие подразумевает обязательство не допускать совершения или не
                    совершения этого действия или вещи в отношении себя или других лиц;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    d. &nbsp; &nbsp;слова, обозначающие единственное число, включают
                    множественное число и наоборот, а слова, обозначающие пол, включают любой
                    пол;&nbsp;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    e. &nbsp; &nbsp;в любой упоминаемый документ периодически вносятся поправки,
                    изменения или дополнения, за исключением случаев, нарушающих настоящие
                    Условия или условия такого документа;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    f. &nbsp; &nbsp;в случае расхождений между настоящими Условиями (включая
                    любые документы, упомянутые в настоящих Условиях) такие положения и
                    документы имеют преимущественную силу в следующем порядке:
                </p>
                <p>
                    <br />
                </p>
                <p>I. &nbsp; &nbsp;Условия продукта;</p>
                <p>
                    <br />
                </p>
                <p>II. &nbsp; &nbsp;Уведомление о конфиденциальности;</p>
                <p>
                    <br />
                </p>
                <p>III. &nbsp; &nbsp;настоящие Условия.</p>
                <p>
                    <br />
                </p>
                <p>
                    g. &nbsp; &nbsp;за исключением случаев, когда контекст требует иного,
                    следующие термины имеют следующие значения:
                </p>
                <p>
                    <br />
                </p>
                <p>
                    Идентификаторы доступа означает данные вашего Аккаунта FANTOM FX, имя
                    пользователя, пароли, персональные идентификационные номера, ключи API,
                    секретные ключи API или любые другие коды или формы аутентификации, которые
                    вы используете для доступа к вашему Аккаунту FANTOM FX или Услугам FANTOM FX
                    или для отправки Указаний.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    История аккаунта означает письменные записи (включая электронные записи) о
                    ваших Транзакциях и вашем Аккаунте FANTOM FX.
                </p>
                <p>
                    <br />
                </p>
                <p>API означает программный интерфейс приложения.</p>
                <p>
                    <br />
                </p>
                <p>
                    Применимое законодательство означает все соответствующие или применимые
                    статуты, законы (включая нормы общего права), принципы справедливости,
                    правила, положения, нормативные принципы и требования, уведомления, приказы,
                    предписания, судебные запреты, судебные решения, подзаконные правовые акты,
                    постановления, директивы, официальные объявления, циркуляры, обязательные
                    кодексы поведения, руководства, практические руководства и толкования (будь
                    то от правительственного органа, регулирующего или иного органа или
                    саморегулируемой организации, членом которой является FANTOM FX), которые
                    применимы к предоставлению, получению или использованию Услуг FANTOM FX или
                    любых других продуктов или результатов, предоставляемых, используемых или
                    получаемых в связи с Услугами FANTOM FX, настоящими Условиями или любыми
                    Условиями продукта.
                </p>
                <p>
                    <br />
                </p>
                <p>Обеспеченные цифровые активы имеет значение, указанное в п. ‎15.3.</p>
                <p>
                    <br />
                </p>
                <p>
                    FANTOM FX, мы, наш или нам означает Nest Services Limited (компания,
                    зарегистрированная в соответствии с законодательством Республики Сейшельские
                    Острова с регистрационным номером 238045 и юридическим адресом: House of
                    Francis, Room 303, Ile Du Port, Mahe, Seychelles).
                </p>
                <p>
                    <br />
                </p>
                <p>
                    Аккаунт FANTOM FX означает любые аккаунты (включая Корпоративные аккаунты
                    FANTOM FX) или субаккаунты (включая любой Субаккаунт FANTOM FX), которые
                    FANTOM FX открыла для вас с целью учета использования вами Услуг FANTOM
                    FX.&nbsp;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    Партнеры FANTOM FX означает в отношении Nest Services Limited: любое другое
                    лицо, которое прямо или косвенно Контролирует, Контролируется или находится
                    под общим Контролем вместе с Nest Services Limited.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    API FANTOM FX означает API, предоставляемый вам Партнером FANTOM FX в
                    качестве услуги, или приложения третьих лиц с таким API.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    ИС FANTOM FX означает Созданную ИС и все другие Права интеллектуальной
                    собственности, принадлежащие нам или лицензированные нами на сублицензионной
                    основе на дату Условий, а также любые другие Права интеллектуальной
                    собственности, принадлежащие нам, или приобретенные нами, или
                    лицензированные нами на сублицензионной основе после даты настоящих Условий,
                    предоставляемые нами вам в ходе предоставления вам Услуг FANTOM FX.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    Услуги FANTOM FX означает услуги, предлагаемые нами вам через Платформу.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    Субаккаунт FANTOM FX означает субаккаунт, созданный в рамках основного
                    Аккаунта FANTOM FX.
                </p>
                <p>
                    <br />
                </p>
                <p>Услуга чата имеет значение, указанное в п. ‎5.</p>
                <p>
                    <br />
                </p>
                <p>
                    Претензия означает любые споры, требования, различия или разногласия между
                    вами и FANTOM FX (и/или любыми Партнерами FANTOM FX), возникающие из, в
                    связи с или каким-либо образом относящиеся к:
                </p>
                <p>
                    <br />
                </p>
                <p>
                    (a) &nbsp; &nbsp;настоящим Условиям или каким-либо Условиям продукта,
                    включая существование, действительность, предмет, толкование, исполнение,
                    нарушение, обсуждение, прекращение, возможность принудительного исполнения
                    или последствия недействительности таких условий;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    (b) &nbsp; &nbsp;вашим отношениям с FANTOM FX (и/или Партнерами FANTOM FX) в
                    качестве пользователя (независимо от того, основаны ли они на договоре,
                    правонарушении, законе, мошенничестве, введении в заблуждение или любых
                    других юридических нормах, и независимо от того, возникли ли претензии в
                    период или после прекращения действия настоящих Условий); или
                </p>
                <p>
                    <br />
                </p>
                <p>
                    (c) &nbsp; &nbsp;любым внедоговорным обязательствам, возникающим из или
                    относящимся к настоящим Условия, любым Условиям продуктов или вашим
                    отношеним с FANTOM FX (и/или любыми Партнерами FANTOM FX).
                </p>
                <p>
                    <br />
                </p>
                <p>
                    Контроль означает полномочия лица обеспечивать ведение дел другого лица в
                    соответствии с желаниями первого лица, будь то посредством:
                </p>
                <p>
                    <br />
                </p>
                <p>
                    (a) &nbsp; &nbsp;в случае компании, являясь бенефициарным владельцем более
                    пятидесяти процентов (50%) выпущенного акционерного капитала или прав голоса
                    в этой компании, или имея право назначать и смещать большинство директоров
                    или иным образом контролировать голоса на заседаниях совета директоров этой
                    компании в силу любых полномочий, предоставленных организационными
                    документами, соглашением акционеров, большинством голосов совета директоров
                    или любым другим документом, регулирующим деятельность этой компании, или
                    любым другим способом; или
                </p>
                <p>
                    <br />
                </p>
                <p>
                    (b) &nbsp; &nbsp;в случае партнерства, являясь бенефициарным владельцем
                    более пятидесяти процентов (50%) капитала этого партнерства, или имея право
                    контролировать состав или голоса большинства участников руководства этого
                    партнерства в силу любых полномочий, предоставленных соглашением о
                    партнерстве или любым другим документом, регулирующим дела этого
                    партнерства, или любым другим способом.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    Корпоративный аккаунт FANTOM FX означает Аккаунт FANTOM FX, который
                    предоставляет корпорации, юридическому лицу или иной организации доступ к
                    Услугам FANTOM FX.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    Созданная ИС означает любые права интеллектуальной собственности, созданные
                    вами в соответствии с настоящими Условиями, включая Пользовательские
                    материалы, но исключая любую другую ИС пользователя.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    Цифровые активы означает цифровое представление стоимости или прав, которые
                    могут передаваться и храниться в электронном виде с использованием
                    технологии распределенного реестра или схожей технологии, включая, помимо
                    прочего, криптовалюты, стейблкоины, невзаимозаменяемые токены и
                    токенизированные деривативы любых других цифровых активов.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    Доминирующий цифровой актив означает Прошедший форк цифровой актив, который
                    доминирует над одной или несколькими другими версиями Цифрового актива,
                    связанного с соответствующим Форком, что определяется нами по нашему
                    усмотрению.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    Электронная почта означает электронную(-ые) почту(-ы), связанную(-ые) с
                    вашим(и) Аккаунтом(-ами) FANTOM FX, как периодически согласовывается с
                    FANTOM FX, в соответствии с любыми процессами, определенными FANTOM FX при
                    использовании Платформы.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    Фиатная валюта означает любую выпущенную правительством или центральным
                    банком национальную или наднациональную валюту или другое денежное
                    обязательство, выраженное в такой валюте и не являющееся Цифровым активом.
                </p>
                <p>
                    <br />
                </p>
                <p>Форс-мажорные обстоятельства означает:&nbsp;</p>
                <p>
                    <br />
                </p>
                <p>
                    a. &nbsp; &nbsp;любой пожар, забастовку, бунт, гражданские беспорядки,
                    террористический акт, войну или промышленный конфликт;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    b. &nbsp; &nbsp;любые стихийные бедствия, такие как наводнения, торнадо,
                    землетрясения и ураганы;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    c. &nbsp; &nbsp;любую эпидемию, пандемию или чрезвычайную ситуацию в области
                    общественного здравоохранения, имеющую национальное или международное
                    значение;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    d. &nbsp; &nbsp;любой акт или постановление правительства, наднационального
                    органа или учреждения, который, по нашему мнению, препятствует
                    предоставлению нами Услуг FANTOM FX на Платформе;
                </p>
                <p>
                    <br />
                </p>
                <p>e. &nbsp; &nbsp;приостановление или закрытие любого Партнера FANTOM FX;</p>
                <p>
                    <br />
                </p>
                <p>f. &nbsp; &nbsp;национализация любого Партнера FANTOM FX;</p>
                <p>
                    <br />
                </p>
                <p>
                    g. &nbsp; &nbsp;введение правительством ограничений или необычных условий
                    для любых Цифровых активов, торгуемых на нашей Платформе;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    h. &nbsp; &nbsp;чрезмерные изменения цены, предложения или спроса на любой
                    Цифровой актив;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    i. &nbsp; &nbsp;технические сбои в средствах передачи информации, средствах
                    связи или компьютерном оборудовании, включая сбои в подаче электроэнергии и
                    сбои в работе электроники или оборудования;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    j. &nbsp; &nbsp;неспособность любого поставщика, брокера-посредника, агента,
                    основного кастодиала, вспомогательного кастодиала, дилера, биржи, платформы
                    стейкинга, пула ликвидности, провайдера моста, эмитента Обеспеченного
                    цифрового актива, маркет-мейкера, клиринговой палаты или регулирующей
                    организации выполнить свои обязательства перед нами;&nbsp;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    k. &nbsp; &nbsp;поставщики ликвидности не предоставляют или не могут
                    предоставить нам ликвидность;&nbsp;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    l. &nbsp; &nbsp;любые трудовые или торговые споры, забастовки, промышленные
                    конфликты или локауты (кроме случаев, когда это инициирует FANTOM FX или
                    Партнеры FANTOM FX); и/или
                </p>
                <p>
                    <br />
                </p>
                <p>
                    m. &nbsp; &nbsp;событие, которое существенно влияет на рынок Цифровых
                    активов, включая чрезмерное изменение цены, предложения или спроса на
                    Цифровой актив, как регулируемое, так и нерегулируемое.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    Форк означает любые запланированные, незапланированные, внезапные,
                    намеченные, ожидаемые, неожиданные, обнародованные, малоизвестные,
                    согласованные и/или спорные изменения основных правил эксплуатации
                    определенных Цифровых активов, которые могут периодически происходить таким
                    образом, чтобы привести к созданию одной или нескольких связанных версий
                    существующего Цифрового актива.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    Прошедший форк цифровой актив означает Цифровой актив, возникший в
                    результате Форка.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    Указание означает любое указание, запрос или приказ, переданный вами или
                    Разрешенным пользователем компании FANTOM FX в связи с работой вашего
                    Аккаунта FANTOM FX или для исполнения любой Транзакции, через такую среду, в
                    такой форме и таким способом, которые могут потребоваться компании FANTOM
                    FX.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    Права интеллектуальной собственности означает: (a) авторские права, патенты,
                    права на базы данных и права на торговые марки, дизайн, ноу-хау и
                    конфиденциальную информацию (как зарегистрированные, так и
                    незарегистрированные); (b) заявки на регистрацию и права на подачу заявки на
                    регистрацию любого из вышеперечисленных прав; и (c) все другие права
                    интеллектуальной собственности и эквивалентные или аналогичные формы защиты,
                    существующие в любой точке мира.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    Местные условия означает условия, регулирующие использование вами Услуг
                    FANTOM FX, предоставляемых в любой конкретной юрисдикции.
                </p>
                <p>
                    <br />
                </p>
                <p>Убытки имеет значение, указанное в п. ‎29.1.</p>
                <p>
                    <br />
                </p>
                <p>
                    Явная ошибка означает любую ошибку, упущение или неправильное цитирование
                    (будь то ошибка FANTOM FX или третьей стороны), которое является явным или
                    ощутимым, включая неправильное цитирование любым представителем FANTOM FX с
                    учетом текущих рыночных условий, или любую ошибку любой информации,
                    источника, официального лица, официального результата или произношения.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    Разрешенный пользователь означает любое лицо, указанное вами и сообщенное
                    нам в соответствии с настоящими Условиями, которое уполномочено действовать
                    от имени пользователя в отношении любого Корпоративного аккаунта FANTOM FX.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    Платформа означает цифровую платформу, которую мы или любой из Партнеров
                    FANTOM FX можем сделать доступной для вас через Сайты, Мобильное приложение,
                    API FANTOM FX или другими способами, которые Партнеры FANTOM FX могут
                    периодически предписывать для использования Услуг FANTOM FX.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    Уведомление о конфиденциальности означает уведомление о конфиденциальности
                    по адресу{" "}
                    <a data-fr-linked="true" href="https://www.FANTOM">
                        https://www.FANTOM
                    </a>{" "}
                    FX.com/en/privacy.&nbsp;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    Условия продукта означает условия и положения, относящиеся к конкретному
                    продукту, которые применяются к использованию Услуги FANTOM FX в дополнение
                    к настоящим Условиям.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    Регулирующий орган означает любой иностранный, национальный,
                    государственный, федеральный, кантональный, муниципальный или местный
                    правительственный, исполнительный, законодательный, судебный,
                    административный, надзорный или регулирующий орган, агентство,
                    квазиправительственный орган, суд, комиссию, правительственную организацию,
                    саморегулируемую организацию с регулирующими полномочиями, трибунал,
                    арбитражный трибунал, арбитражную комиссию или наднациональную организацию
                    или любое их подразделение или орган, включая любой налоговый орган.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    Лицо с ограниченным доступом означает физическое или юридическое лицо,
                    которое (a) включено в любое торговое эмбарго или экономические санкции,
                    список террористов или коррумпированных иностранных должностных лиц
                    (например, Санкционный перечень Совета Безопасности ООН, изданный
                    правительственным агентством, включая Список лиц особых категорий и
                    запрещенных лиц Управления по контролю за иностранными активами (OFAC)
                    Министерства финансов США или список запрещенных лиц и организаций
                    Министерства торговли США, а также списки Великобритании, Европейского союза
                    и Канады)
                </p>
                <p>
                    Предупреждение о рисках означает общее предупреждение о рисках,
                    опубликованное на Сайте.
                </p>
                <p>
                    <br />
                </p>
                <p>Нарушение безопасности означает&nbsp;</p>
                <p>
                    <br />
                </p>
                <p>
                    a. &nbsp; &nbsp;взлом вашего(-их) Аккаунта(-ов) FANTOM FX или любого из
                    ваших Идентификаторов доступа;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    b. &nbsp; &nbsp;потерю, кражу, несанкционированное использование любого из
                    ваших Идентификаторов доступа или любой несанкционированный доступ и
                    использование вашего Аккаунта FANTOM FX или Услуг FANTOM FX от вашего имени;
                    или
                </p>
                <p>
                    <br />
                </p>
                <p>
                    c. &nbsp; &nbsp;любой другой инцидент в системе безопасности (включая
                    кибератаку), затрагивающий вас и/или FANTOM FX.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    Сайты означает наш Сайт и любые другие сайты, страницы, функции или контент,
                    которыми мы владеем или управляем.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    Поддерживаемые цифровые активы означает те Цифровые активы, которые доступны
                    в связи с использованием вами Услуг FANTOM FX.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    Условия означает (при каждом упоминании, а также периодических изменениях
                    или дополнениях) настоящие условия использования, а также любые другие явным
                    образом упоминаемые документы, включая Условия продукта.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    Торговые марки означает Права интеллектуальной собственности на торговые
                    марки, знаки обслуживания и логотипы, используемые и отображаемые на
                    Платформе, Сайтах и/или Услугах FANTOM FX или через них.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    Транзакция означает продажу, покупку или заключение любого другого типа
                    транзакций либо согласие на продажу, покупку или заключение любого другого
                    типа транзакций с Цифровым(и) активом(-ами), их деривативами, другим(и)
                    активом(-ами) или продуктом(-ами), которые FANTOM FX может периодически
                    разрешать осуществлять на Платформе, а также ввод или вывод Цифровых активов
                    на ваш Аккаунт FANTOM FX или с него.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    ИС пользователя означает Права интеллектуальной собственности, принадлежащие
                    вам или лицензированные вами на дату настоящих Условий, и любые другие Права
                    интеллектуальной собственности, принадлежащие вам или приобретенные вами или
                    лицензированные вами после даты настоящих Условий, за исключением ИС FANTOM
                    FX.&nbsp;
                </p>
                <p>
                    <br />
                </p>
                <p>
                    Пользовательские материалы означает права Интеллектуальной собственности на
                    любые обзоры, публикации, информацию, данные и комментарии, которые вы или
                    другие пользователи предоставляете нам на Сайтах (через наши страницы
                    «Свяжитесь с нами» или иным образом), Платформе, посредством использования
                    Услуг FANTOM FX или иным образом.
                </p>
                <p>
                    <br />
                </p>
                <p>Сайт означает сайт по адресу www.FANTOM FX.com.</p>
                <p>
                    <br />
                </p>
                <p>
                    <br />
                </p>
            </div>







    {/*        <div className="comps__content">*/}
    {/*            <p>*/}
    {/*<span style={{ color: "#f1c40f" }}>*/}
    {/*  <span style={{ fontSize: 20 }}>Политика конфиденциальности</span>*/}
    {/*</span>*/}
    {/*            </p>*/}
    {/*            <p>*/}
    {/*                <br />*/}
    {/*                <span style={{ fontSize: 14 }}>*/}
    {/*  Мы придерживаемся высоких стандартов защиты личной информации наших*/}
    {/*  пользователей.*/}
    {/*</span>*/}
    {/*            </p>*/}
    {/*            <p>*/}
    {/*<span style={{ fontSize: 14 }}>*/}
    {/*  Эта Политика конфиденциальности объясняет, как мы собираем, используем,*/}
    {/*  раскрываем и защищаем информацию, которую вы предоставляете нам при*/}
    {/*  использовании нашей платформы и сервисов. Просим Вас внимательно*/}
    {/*  ознакомиться с этой политикой, чтобы понимать, как мы обрабатываем вашу*/}
    {/*  информацию.*/}
    {/*</span>*/}
    {/*            </p>*/}
    {/*            <p>*/}
    {/*                <br />*/}
    {/*                <span style={{ fontSize: 14 }}>1. Собираемая информация</span>*/}
    {/*            </p>*/}
    {/*            <ul>*/}
    {/*                <li>*/}
    {/*                    - Личная информация: При регистрации на платформе, мы можем собрать Ваше*/}
    {/*                    имя, электронную почту, номер телефона и другие данные, которые Вы*/}
    {/*                    предоставите добровольно.*/}
    {/*                </li>*/}
    {/*                <li>*/}
    {/*  <span style={{ fontSize: 14 }}>*/}
    {/*    - Информация о транзакциях: Мы собираем информацию о транзакциях, которые*/}
    {/*    Вы проводите на нашей платформе, включая историю операций и балансы*/}
    {/*    аккаунта.*/}
    {/*  </span>*/}
    {/*                </li>*/}
    {/*                <li>*/}
    {/*  <span style={{ fontSize: 14 }}>*/}
    {/*    - Техническая информация: Мы автоматически собираем информацию о Вашем*/}
    {/*    устройстве, IP-адресе, браузере, операционной системе и других*/}
    {/*    технических данных при использовании нашей платформы.*/}
    {/*  </span>*/}
    {/*                </li>*/}
    {/*                <li>*/}
    {/*  <span style={{ fontSize: 14 }}>*/}
    {/*    - Cookies и аналитика: Мы используем файлы cookies и аналитические*/}
    {/*    инструменты для сбора информации о Вашем взаимодействии с платформой,*/}
    {/*    чтобы улучшать наши услуги и адаптировать их под ваши потребности.*/}
    {/*  </span>*/}
    {/*                </li>*/}
    {/*            </ul>*/}
    {/*            <p>&nbsp;</p>*/}
    {/*            <p>*/}
    {/*<span style={{ fontSize: 14 }}>*/}
    {/*  2. Использование информации*/}
    {/*  <br />*/}
    {/*  Мы используем собранную информацию для следующих целей:*/}
    {/*</span>*/}
    {/*            </p>*/}
    {/*            <ul>*/}
    {/*                <li>*/}
    {/*                   - Предоставление услуг: Мы используем Вашу информацию для обеспечения*/}
    {/*                    доступа к функциям нашей платформы, выполнения транзакций и управления*/}
    {/*                    аккаунтом.*/}
    {/*                </li>*/}
    {/*                <li>*/}
    {/*  <span style={{ fontSize: 14 }}>*/}
    {/*    - Улучшение сервисов: Мы анализируем данные, чтобы понимать, как*/}
    {/*    пользователи взаимодействуют с платформой и как мы можем сделать её*/}
    {/*    более удобной и эффективной.*/}
    {/*  </span>*/}
    {/*                </li>*/}
    {/*                <li>*/}
    {/*  <span style={{ fontSize: 14 }}>*/}
    {/*    - Обеспечение безопасности: Мы используем информацию для защиты от*/}
    {/*    мошенничества, взломов и других потенциальных угроз.*/}
    {/*  </span>*/}
    {/*                </li>*/}
    {/*                <li>*/}
    {/*  <span style={{ fontSize: 14 }}>*/}
    {/*    - Коммуникация: Мы можем использовать Вашу электронную почту для отправки*/}
    {/*    важных уведомлений, обновлений и информации о Вашем аккаунте.*/}
    {/*  </span>*/}
    {/*                </li>*/}
    {/*            </ul>*/}
    {/*            <p>*/}
    {/*                <span style={{ fontSize: 14 }}>3. Раскрытие информации</span>*/}
    {/*                <br />*/}
    {/*                <span style={{ fontSize: 14 }}>*/}
    {/*  Мы не продаем, не обмениваем и не арендуем Вашу личную информацию третьим*/}
    {/*  лицам без Вашего согласия, за исключением случаев, предусмотренных*/}
    {/*  законом.*/}
    {/*</span>*/}
    {/*                <br />*/}
    {/*                &nbsp;*/}
    {/*            </p>*/}
    {/*            <p>*/}
    {/*                <span style={{ fontSize: 14 }}>4. Безопасность</span>*/}
    {/*                <br />*/}
    {/*                <span style={{ fontSize: 14 }}>*/}
    {/*  Мы принимаем меры для обеспечения безопасности Вашей информации, используя*/}
    {/*  технические и организационные средства, чтобы защитить её от*/}
    {/*  несанкционированного доступа и раскрытия.*/}
    {/*</span>*/}
    {/*                <br />*/}
    {/*                &nbsp;*/}
    {/*            </p>*/}
    {/*            <p>*/}
    {/*                <span style={{ fontSize: 14 }}>5. Ваши права</span>*/}
    {/*                <br />*/}
    {/*                <span style={{ fontSize: 14 }}>*/}
    {/*  Вы имеете право на доступ, коррекцию и удаление вашей личной информации.*/}
    {/*  Вы также можете отозвать свое согласие на обработку данных в любой момент.*/}
    {/*</span>*/}
    {/*                <br />*/}
    {/*                &nbsp;*/}
    {/*            </p>*/}
    {/*            <p>*/}
    {/*                <span style={{ fontSize: 14 }}>6. Изменения в политике</span>*/}
    {/*                <br />*/}
    {/*                <span style={{ fontSize: 14 }}>*/}
    {/*  Мы можем периодически обновлять нашу Политику конфиденциальности, и мы*/}
    {/*  оповестим Вас об этих изменениях.*/}
    {/*  <br />*/}
    {/*  Если у Вас есть вопросы или запросы относительно нашей Политики*/}
    {/*  конфиденциальности, свяжитесь с нами по адресу{" "}*/}
    {/*                    <span style={{ color: "#f1c40f" }}>info@fantomfx.net</span>*/}
    {/*</span>*/}
    {/*                <br />*/}
    {/*                &nbsp;*/}
    {/*            </p>*/}
    {/*            <p>*/}
    {/*<span style={{ fontSize: 14 }}>*/}
    {/*  Пользуясь нашей платформой, Вы соглашаетесь с этой Политикой*/}
    {/*  конфиденциальности.*/}
    {/*</span>*/}
    {/*            </p>*/}
    {/*        </div>*/}
        </div>
    );
};

export default Privacy;