import React, {useEffect, useState} from 'react';
import './landing.css'
import {NavLink} from "react-router-dom";
import create from '../images/create.svg'
import auth from '../images/auth.svg'
import manage from '../images/manage.svg'
import shield from '../images/fshield.svg'
import casef from '../images/case.svg'
import axios from "axios";


const Landing = () => {
    (function() {
        function decimalAdjust(type, value, exp) {
            if (typeof exp === 'undefined' || +exp === 0) {
                return Math[type](value);
            }
            value = +value;
            exp = +exp;
            if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
                return NaN;
            }
            value = value.toString().split('e');
            value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
            value = value.toString().split('e');
            return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
        }

        if (!Math.floor10) {
            Math.floor10 = function(value, exp) {
                return decimalAdjust('floor', value, exp);
            };
        }
    })();

    const [pair1, setPair1] = useState()
    const [pair2, setPair2] = useState()
    const [pair3, setPair3] = useState()
    const [pair4, setPair4] = useState()
    const [pair5, setPair5] = useState()
    const [isLoaded, setIsLoaded] = useState(false)
    const requireData = () => {
        axios.get(`https://api.binance.com/api/v3/ticker/24hr?symbol=BTCUSDT`)
            .then(res => {
                setPair1(res.data);
                axios.get(`https://api.binance.com/api/v3/ticker/24hr?symbol=ETHUSDT`)
                    .then(res => {
                        setPair2(res.data);
                        axios.get(`https://api.binance.com/api/v3/ticker/24hr?symbol=ADAUSDT`)
                            .then(res => {
                                setPair3(res.data);
                                axios.get(`https://api.binance.com/api/v3/ticker/24hr?symbol=DASHUSDT`)
                                    .then(res => {
                                        setPair4(res.data);
                                        axios.get(`https://api.binance.com/api/v3/ticker/24hr?symbol=DOTUSDT`)
                                            .then(res => {
                                                setPair5(res.data);
                                                setIsLoaded(true)
                                            })
                                    })

                            })

                    })

            })

    }


    useEffect(() => {
        requireData()
    }, [])
    const isLoggedIn = localStorage.getItem("isLoggedIn")
    return (
            <div className="l-body">
                <div className="l-block1">
                <h1 className="l-title">Инвестируйте в</h1>
                <h1 className="l-title">своё будущее</h1>
                    { isLoggedIn ?
                        ""
                        :
                    <NavLink to="/register" className="start-btn" >
                        Начать
                    </NavLink>
                    }
                </div>
                <div className="l-block2">
                    <div className="l-block2__item">
                        <img src={create} className="l-block2__image" alt=""/>
                        <h2 className="l-block2__title">Создайте</h2>
                        <p className="l-block2__subtitle">свой первый инвестиционный</p>
                        <p className="l-block2__subtitle">или фьючерсный счёт</p>
                        <p className="l-block2__subtitle">в считанные минуты</p>
                        <NavLink to="/register" className="l-block2__lg">Начать</NavLink>
                    </div>
                    <div className="l-block2__item">
                        <img src={auth} className="l-block2__image" alt=""/>
                        <h2 className="l-block2__title">Войдите</h2>
                        <p className="l-block2__subtitle">для получения доступа</p>
                        <p className="l-block2__subtitle">к уникальной торговой площадке</p>
                        <p className="l-block2__subtitle">со всеми преимуществами</p>
                        <NavLink to="/register" className="l-block2__lg">Начать</NavLink>
                    </div>
                    <div className="l-block2__item">
                        <img src={manage} className="l-block2__image" alt=""/>
                        <h2 className="l-block2__title">Управляйте</h2>
                        <p className="l-block2__subtitle">своими активами максимально</p>
                        <p className="l-block2__subtitle">быстро, безопасно и удобно</p>
                        <p className="l-block2__subtitle">через персональный кабинет</p>
                        <NavLink to="/register" className="l-block2__lg">Начать</NavLink>
                    </div>
                </div>
                <div className="l-block3">
                    <h1 className="l-block3__title">Платформа, которая</h1>
                    <h1 className="l-block3__title mb16">инвестирует в вас</h1>
                    <p className="l-block3__subtitle">Получайте щедрые проценты за хранение средств</p>
                    <p className="l-block3__subtitle">и прочие активности на нашей площадке</p>

                    <NavLink to="/register" className="start-btn" >
                        Начать
                    </NavLink>
                </div>
                <div className="l-block4">
                    <div className="l-block4__item aic">
                    <img className="shield-img" src={shield} alt=""/>
                    </div>
                    <div className="l-block4__item">
                        <h1 className="l-block4__title">24/7 доступ к</h1>
                        <h1 className="l-block4__title"> клиентской службе </h1>
                        <h1 className="l-block4__title mb32">поддержки</h1>
                        <p className="l-block__subtitle">Наши операторы с радостью помогут Вам открыть Вашу первую сделку </p>
                        <p className="l-block__subtitle">или решить любой технический вопрос</p>
                        <NavLink to="/register" className="start-btn mtsub" >
                            Начать
                        </NavLink>
                    </div>
                </div>
                <div className="l-block6">
                    <h1 className="l-block3__title">Торгуйте криптовалютой с</h1>
                    <h1 className="l-block3__title mb16">самой низкой комиссией</h1>
                    {isLoaded ? <div className="l-graph__container">

                            <div className="l-graph__item">
                                <div className="l-graph__title">Bitcoin</div>
                                <div className="l-graph__abr">BTC</div>
                                <div className="l-graph__coin">${Math.floor10( pair1.lastPrice, -1)}</div>
                                <div className={`l-graph__percent ${pair1.priceChangePercent > 0 ? "text-success" : "text-error"}`}>{Math.floor10(pair1.priceChangePercent, -2)}%</div>
                                <NavLink to="/market?symbol=ADAUSDT" className="l-graph__link">
                                    Торговать
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.9091 7L18 12M18 12L12.9091 17M18 12L6 12" className="l-graph-arrow" stroke="white" stroke-width="2" stroke-linecap="square"/>
                                    </svg>
                                </NavLink>
                            </div>

                            <div className="l-graph__item">
                                <div className="l-graph__title">Ethereum</div>
                                <div className="l-graph__abr">ETH</div>
                                <div className="l-graph__coin">${Math.floor10(pair2.lastPrice, -2)}</div>
                                <div className={`l-graph__percent ${pair2.priceChangePercent > 0 ? "text-success" : "text-error"}`}>{Math.floor10(pair2.priceChangePercent, -2)}%</div>
                                <NavLink to="/market?symbol=DASHUSDT" className="l-graph__link">
                                    Торговать
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.9091 7L18 12M18 12L12.9091 17M18 12L6 12" className="l-graph-arrow" stroke="white" stroke-width="2" stroke-linecap="square"/>
                                    </svg>
                                </NavLink>
                            </div>

                            <div className="l-graph__item">
                                <div className="l-graph__title">Cardano</div>
                                <div className="l-graph__abr">ADA</div>
                                <div className="l-graph__coin">${Math.floor10( pair3.lastPrice, -4)}</div>
                                <div className={`l-graph__percent ${pair3.priceChangePercent > 0 ? "text-success" : "text-error"}`}>{Math.floor10(pair3.priceChangePercent, -2)}%</div>
                                <NavLink to="/market?symbol=ADAUSDT" className="l-graph__link">
                                    Торговать
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.9091 7L18 12M18 12L12.9091 17M18 12L6 12" className="l-graph-arrow" stroke="white" stroke-width="2" stroke-linecap="square"/>
                                    </svg>
                                </NavLink>
                            </div>

                            <div className="l-graph__item">
                                <div className="l-graph__title">Dash</div>
                                <div className="l-graph__abr">DASH</div>
                                <div className="l-graph__coin">${Math.floor10(pair4.lastPrice, -3)}</div>
                                <div className={`l-graph__percent ${pair4.priceChangePercent > 0 ? "text-success" : "text-error"}`}>{Math.floor10(pair4.priceChangePercent, -2)}%</div>
                                <NavLink to="/market?symbol=DASHUSDT" className="l-graph__link">
                                    Торговать
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.9091 7L18 12M18 12L12.9091 17M18 12L6 12" className="l-graph-arrow" stroke="white" stroke-width="2" stroke-linecap="square"/>
                                    </svg>
                                </NavLink>
                            </div>

                            <div className="l-graph__item">
                                <div className="l-graph__title">Polkadot</div>
                                <div className="l-graph__abr">DOT</div>
                                <div className="l-graph__coin">${Math.floor10(pair5.lastPrice, -4)}</div>
                                <div className={`l-graph__percent ${pair5.priceChangePercent > 0 ? "text-success" : "text-error"}`}>{Math.floor10(pair5.priceChangePercent, -2)}%</div>
                                <NavLink to="/market?symbol=DOTUSDT" className="l-graph__link">
                                    Торговать
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.9091 7L18 12M18 12L12.9091 17M18 12L6 12" className="l-graph-arrow" stroke="white" stroke-width="2" stroke-linecap="square"/>
                                    </svg>
                                </NavLink>
                            </div>
                    </div>
                        : ""}
                </div>


                <div className="l-block5">
                    <div className="l-block4__item">
                        <h1 className="l-block4__title">Сделайте первый шаг</h1>
                        <h1 className="l-block4__title">в безопасное</h1>
                        <h1 className="l-block4__title mb32">инвестирование</h1>
                        <NavLink to="/register" className="start-btn mtsub" >
                            Начать
                        </NavLink>
                    </div>

                    <div className="l-block4__item aic">
                    <img src={casef} alt=""/>
                    </div>
                </div>
            </div>
    );
};

export default Landing;