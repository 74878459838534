import React, {useEffect, useState} from 'react';
import './App.css';
import Header from "./components/header";
import Privacy from "./components/footerComponents/privacy";
import About from "./components/footerComponents/about";
import Specials from "./components/footerComponents/specials";
import Contacts from "./components/footerComponents/contacts";
import Footer from "./components/footerComponents/footer";
import {Route, Routes} from "react-router";
import {BrowserRouter} from "react-router-dom";
import Login from "./components/lk_user/auth/login/login";
import Register from "./components/lk_user/auth/register/register";
import Withdraw from "./components/account/withdraw";
import Deposit from "./components/account/deposit";
import MarketNew from "./components/marketNew";
import Account from "./components/account/account";
import Me from "./components/account/me";
import Orders from "./components/account/orders";
import Wallet from "./components/account/wallet";
import { SnackbarProvider  } from 'notistack';
import Main from "./components/account/main";
import Kyc from "./components/account/kyc";
import Landing from "./components/landing";
import ProtectedRoute from "./components/protectedRoute";
import Recovery from "./components/lk_user/auth/recovery/recovery";
import Portfolio from "./components/account/portfolio";
import axios from "axios";
import MarketStocks from "./components/stocks/marketStocks";
import PortfolioStocks from "./components/account/portfolio-stocks";
const App = () => {
    const userToken = localStorage.getItem('token');


    const getUserid = () => {
        axios.get(`https://api.fantom-fx.org/api/api/users/id`, {headers: { "Authorization" : `Bearer ${userToken}`}})
            .then(res => {
                const socket = new WebSocket(`wss://api.fantom-fx.org/ws/ws/status/${res.data}`);

                socket.onopen = function() {
                    console.log('Соединение установлено');
                };

                socket.onmessage = function(event) {
                    console.log(`Получено сообщение: ${event.data}`);
                };

                socket.onclose = function(event) {
                    console.log('Соединение закрыто');
                };

                socket.onerror = function(error) {
                    console.log(`Ошибка: ${error.message}`);
                };

            })
    }

    useEffect(() => {
        getUserid()
    }, [])





    const [isLoggedIn, setisLoggedIn] = useState(null);

    const logIn = () => {
        setisLoggedIn("true");
        localStorage.setItem('isLoggedIn', "true");
    };
    const logOut = () => {
        setisLoggedIn(null);
        localStorage.clear();
        document.location.reload();
    };
    const Reload = () => {
        document.location.reload();
    };
  return (
      <BrowserRouter>
          <Header isLoggedIn={isLoggedIn} setisLoggedIn={setisLoggedIn} logOut={logOut}/>
          <Routes>
            <Route path="/main" element={<Landing/>} isLoggedIn={isLoggedIn} />
            <Route path="/" element={<Landing/>} />

              <Route path="/recovery" element={<SnackbarProvider  anchorOrigin={{ vertical: 'top', horizontal: 'right' }} autoHideDuration={4500} maxSnack={3}><Recovery/></SnackbarProvider>} />

            <Route path="/market" element={<SnackbarProvider  anchorOrigin={{ vertical: 'top', horizontal: 'right' }} autoHideDuration={4500} maxSnack={3}><MarketNew/></SnackbarProvider>} />
            <Route path="/market-stocks" element={<SnackbarProvider  anchorOrigin={{ vertical: 'top', horizontal: 'right' }} autoHideDuration={4500} maxSnack={3}><MarketStocks/></SnackbarProvider>} />

            <Route path="/privacy" element={<Privacy/>} />
            <Route path="/specialists" element={<Specials/>} />
            <Route path="/contacts" element={<Contacts/>} />
            <Route path="/about" element={<About/>} />

            <Route path="/account/" element={
                <ProtectedRoute>
                <SnackbarProvider  anchorOrigin={{ vertical: 'top', horizontal: 'right' }} autoHideDuration={4500} maxSnack={3}> <Account activeId="main" children={ <Main />}/> </SnackbarProvider>
                </ProtectedRoute>
            } />

            <Route path="/account/me"        element={<ProtectedRoute><SnackbarProvider  anchorOrigin={{ vertical: 'top', horizontal: 'right' }} autoHideDuration={4500} maxSnack={3}> <Account activeId="me" children={<Me/>}/> </SnackbarProvider> </ProtectedRoute>} />
            <Route path="/account/deposit"   element={<ProtectedRoute><SnackbarProvider  anchorOrigin={{ vertical: 'top', horizontal: 'right' }} autoHideDuration={4500} maxSnack={3}> <Account activeId="deposit" children={<Deposit/>}/> </SnackbarProvider> </ProtectedRoute>} />
            <Route path="/account/withdraw"  element={<ProtectedRoute><SnackbarProvider  anchorOrigin={{ vertical: 'top', horizontal: 'right' }} autoHideDuration={4500} maxSnack={3}> <Account activeId="withdraw" children={ <Withdraw/> }/> </SnackbarProvider> </ProtectedRoute>} />
            <Route path="/account/wallet"    element={<ProtectedRoute><SnackbarProvider  anchorOrigin={{ vertical: 'top', horizontal: 'right' }} autoHideDuration={4500} maxSnack={3}> <Account activeId="wallet" children={<Wallet/>}/> </SnackbarProvider> </ProtectedRoute>} />
            <Route path="/account/kyc"       element={<ProtectedRoute><SnackbarProvider  anchorOrigin={{ vertical: 'top', horizontal: 'right' }} autoHideDuration={4500} maxSnack={3}> <Account activeId="kyc" children={ <Kyc/> }/> </SnackbarProvider> </ProtectedRoute>} />
            <Route path="/account/orders"    element={<ProtectedRoute><SnackbarProvider  anchorOrigin={{ vertical: 'top', horizontal: 'right' }} autoHideDuration={4500} maxSnack={3}> <Account activeId="orders" children={<Orders/>}/> </SnackbarProvider> </ProtectedRoute>} />
            <Route path="/account/portfolio" element={<ProtectedRoute><SnackbarProvider  anchorOrigin={{ vertical: 'top', horizontal: 'right' }} autoHideDuration={4500} maxSnack={3}> <Account activeId="portfolio" children={<Portfolio/>}/> </SnackbarProvider> </ProtectedRoute>} />
            <Route path="/account/portfolio-stocks" element={<ProtectedRoute><SnackbarProvider  anchorOrigin={{ vertical: 'top', horizontal: 'right' }} autoHideDuration={4500} maxSnack={3}> <Account activeId="portfolioStocks" children={<PortfolioStocks/>}/> </SnackbarProvider> </ProtectedRoute>} />

            <Route path="/authorization"  element={<SnackbarProvider  anchorOrigin={{ vertical: 'top', horizontal: 'right' }} autoHideDuration={4500} maxSnack={3}><Login Reload={Reload} isLoggedIn={isLoggedIn} setisLoggedIn={setisLoggedIn} logIn={logIn} /></SnackbarProvider> }/>
              <Route path="/register" element={<SnackbarProvider  anchorOrigin={{ vertical: 'top', horizontal: 'right' }} autoHideDuration={4500} maxSnack={3}><Register isLoggedIn={isLoggedIn} setisLoggedIn={setisLoggedIn} logIn={logIn} /></SnackbarProvider> }/>

          </Routes>
          <Footer/>
      </BrowserRouter>

  );
};

export default App;
