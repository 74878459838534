import React, {useEffect, useState} from 'react';
import '../auth.sass'
import axios from "axios";
import {useNavigate} from "react-router";
import {NavLink} from "react-router-dom";
import { useSelector } from "react-redux";

import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {createTheme} from "@mui/material/styles";
import {ThemeProvider} from "@mui/material";
import {closeSnackbar, enqueueSnackbar} from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});

const action = snackbarId => (
    <button onClick={() => { closeSnackbar(snackbarId) }}>
        <CloseIcon/>
    </button>
);

const Register = (props) => {


    const url = new URL(document.location.href);
    let ref_link = (url.searchParams.get('ref_link'));

    localStorage.clear();

    const today2 = new Date();
    const minBirthDate2 = new Date(today2.getFullYear() - 18, today2.getMonth(), today2.getDate()); // Минимальная дата рождения для 18 лет

    let dateFormatted2 =
        pad(minBirthDate2.getDate(), 2, '0') +
        pad(minBirthDate2.getMonth() + 1, 2, '0') +
        minBirthDate2.getFullYear();

    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [name, setName] = useState();
    const [surname, setSurname] = useState();
    const [username, setUsername] = useState();
    const [country, setCountry] = useState();
    const [city, setCity] = useState();
    const [birthDate, setBirthDate] = useState(minBirthDate2);
    const [birthDateFormatted, setBirthDateFormated] = useState(dateFormatted2);

    const [emailError, setEmailError] = useState('e-mail cannot be empty');
    const [passwordError, setPasswordError] = useState('password cannot be empty');
    const [nameError, setNameError] = useState('name cannot be empty');
    const [surnameError, setSurnameError] = useState('surname cannot be empty');
    const [usernameError, setUsernameError] = useState('login cannot be empty');
    const [countryError, setCountryError] = useState('country cannot be empty');
    const [cityError, setCityError] = useState('city cannot be empty');
    const [birthDateError, setBirthDateError] = useState('');

    const [emailDirty, setEmailDirty] = useState(false);
    const [passwordDirty, setPasswordDirty] = useState(false);
    const [nameDirty, setNameDirty] = useState(false);
    const [surnameDirty, setSurnameDirty] = useState(false);
    const [usernameDirty, setUsernameDirty] = useState(false);
    const [countryDirty, setCountryDirty] = useState(false);
    const [cityDirty, setCityDirty] = useState(false);
    const [birthDateDirty, setBirthDateDirty] = useState(false);

    const [formValid, setFormValid] = useState(false);

    const emailFromState = useSelector(state => state);



    const [phone, setPhone] = useState('');
    const [phoneError, setPhoneError] = useState('phone number cannot be empty');
    const [phoneDirty, setPhoneDirty] = useState(false);

    const validatePhone = (value) => {
        // You can implement your own phone number validation logic here
        // For example, checking if it's a valid phone number format
        if (value.length < 10) {
            setPhoneError('incorrect phone number');
            if (!value) {
                setPhoneError('phone number cannot be empty');
            }
        } else {
            setPhoneError('');
        }
    };

    const phoneHandler = (e) => {
        setPhone(e.target.value);
        validatePhone(e.target.value);
    };

    function isEmpty(obj) {
        for (let key in obj) {
            return false;
        }
        return true;
    }
    useEffect(() => {
        if (isEmpty(emailFromState) === false)
        {
        setEmail(emailFromState.at(-1).storeEmail)
        }

    },[])


    useEffect(() => {
        if (emailError || passwordError || nameError || surnameError || usernameError || cityError || countryError || phoneError ) {
            setFormValid(false)
        } else {
            setFormValid(true)
        }
    },[emailError, passwordError, nameError, surnameError, usernameError, cityError, countryError, phoneError])


    const blurHandler = (e) =>  {
        switch (e.target.name){
            case 'email':
                setEmailDirty(true)
                break
            case 'phone':
                setPhoneDirty(true)
                break
            case 'password':
                setPasswordDirty(true)
                break
            case 'name':
                setNameDirty(true)
                break
            case 'surname':
                setSurnameDirty(true)
                break
            case 'username':
                setUsernameDirty(true)
                break
            case 'country':
                setCountryDirty(true)
                break
            case 'city':
                setCityDirty(true)
                break
            case 'birthDate':
                setBirthDateDirty(true)
                break
        }

    };
    const blurHandlerBD = (e) =>  {
                setBirthDateDirty(true)
    };

    const emailHandler = (e) => {
        setEmail(e.target.value)
        const re =
            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (!re.test(String(e.target.value).toLowerCase())) {
            setEmailError('incorrect e-mail')
            if(!e.target.value){
                setEmailError('e-mail cannot be empty')
            }
        }
        else{
            setEmailError("")
        }
    }
    const passwordHandler = (e) => {
        setPassword(e.target.value)
        if (e.target.value.length < 4) {
            setPasswordError('too short password!')
            if(!e.target.value){
                setPasswordError('password cannot be empty!')
            }
        }else{
            setPasswordError("")
        }
    };
    const nameHandler = (e) => {
        setName(e.target.value)
        if (e.target.value.length < 2) {
            setNameError('too short name!')
            if(!e.target.value){
                setNameError('name cannot be empty!')
            }
        }else{
            setNameError("")
        }
    };
    const surnameHandler = (e) => {
        setSurname(e.target.value)
        if (e.target.value.length < 2) {
            setSurnameError('too short surname!')
            if(!e.target.value){
                setSurnameError('surname cannot be empty!')
            }
        }else{
            setSurnameError("")
        }
    };
    const usernameHandler = (e) => {
        setUsername(e.target.value)
        if (e.target.value.length < 4) {
            setUsernameError('too short login!')
            if(!e.target.value){
                setUsernameError('login cannot be empty!')
            }
        }else{
            setUsernameError("")
        }
    };
    const birthDateHandler = (e) => {
        setBirthDate(e.target.value)
        if (e.target.value.length < 7) {
            setBirthDateError('incorrect birth date')
            if(!e.target.value){
                setBirthDateError('cannot be empty!')
            }
        }else{
            setBirthDateError("")
        }
    };
    const cityHandler = (e) => {
        setCity(e.target.value)
        if (e.target.value.length < 1) {
            setUsernameError('incorrect')
            if(!e.target.value){
                setCityError('cannot be empty!')
            }
        }else{
            setCityError("")
        }
    };
    const countryHandler = (e) => {
        setCountry(e.target.value)
        if (e.target.value.length < 1) {
            setUsernameError('incorrect')
            if(!e.target.value){
                setCountryError('cannot be empty!')
            }
        }else{
            setCountryError("")
        }
    };

    const handleChangeName = event => {
        setName(event.target.value);
    };
    const handleChangeSurname = event => {
        setSurname(event.target.value);
    };
    const handleChangeUsername = event => {
        setUsername(event.target.value);
    };
    const handleChangeEmail = event => {
        setEmail(event.target.value);
    };
    const handleChangePassword = event => {
        setPassword(event.target.value);
    };
    const handleChangeBirthDate = event => {
        setBirthDate(event.target.value);
    };
    const handleChangeCity = event => {
        setCity(event.target.value);
    };
    const handleChangeCountry = event => {
        setCountry(event.target.value);
    };

    let navigate = useNavigate();


    const handleSubmit = (event) => {
        event.preventDefault();

        axios.post(`https://api.fantom-fx.org/api/api/users${ref_link ? `?ref_link=${ref_link}` : ""}`, {email, username, country, city, "phone_number": phone , name, surname, password })
            .then(res => {
                console.log(res);
                console.log(res.data);
                setEmail('');
                setUsername('');
                setCountry('');
                setCity('');
                setBirthDate('');
                setName('');
                setSurname('');
                setPassword('');
                setPhone('');

                localStorage.setItem('username', username);
                localStorage.setItem('userEmail', email);
                localStorage.setItem('userPassword', password);
                localStorage.setItem('userName', name);
                localStorage.setItem('userSurname', surname);
                localStorage.setItem('token', res.data);
                props.logIn()
                navigate(`../market?symbol=BTCUSDT`, { replace: true });
            })
            .catch( error => {

                enqueueSnackbar(`Возникла ошибка: ${error.response.data.detail}`, {action, 'variant': 'error', 'className': "snackerror"});


            });
    };
    function pad(s, width, character) {
        return new Array(width - s.toString().length + 1).join(character) + s;
    }

    let maxDate = new Date(1407267771429);
    let maxDateFormatted = pad(maxDate.getDate(), 2, '0') + pad(maxDate.getMonth() + 1, 2, '0') + maxDate.getFullYear();
    console.log(maxDateFormatted)
    const [passwordShown, setPasswordShown] = useState(false);
    return (
        <div className="flex-center-reg">

            <form className="loginform for-reg">
                <h1 className="loginform__title">Регистрация</h1>


                {(usernameDirty && usernameError) && <div className="error" style={{color: "red"}}>{usernameError}</div>}
                <input placeholder="Логин" onChange={e => usernameHandler(e) && handleChangeUsername} value={username} onBlur={e => blurHandler(e)} name="username" className="loginform__input" type="text"/>

                {(passwordDirty && passwordError) &&
                    <div className="error" style={{color: "red"}}>{passwordError}</div>}
                <div className="loginform__input-container">
                    <input type={passwordShown ? "" : "password"}
                           onChange={e => passwordHandler(e) && handleChangePassword} value={password}
                           onBlur={e => blurHandler(e)} name="password" className="loginform__input-password"
                           placeholder="Пароль"/>
                    {passwordShown ? <VisibilityOffIcon onClick={() => setPasswordShown(!passwordShown)}
                                                        className="visibilityIcon"/> :
                        <VisibilityIcon onClick={() => setPasswordShown(!passwordShown)} className="visibilityIcon"/>}
                </div>
                {(emailDirty && emailError) && <div className="error" style={{color: "red"}}>{emailError}</div>}
                <input onChange={e => emailHandler(e) && handleChangeEmail} value={email} onBlur={e => blurHandler(e)} name="email" type="email" className="loginform__input" placeholder="Почта"/>

                {(phoneDirty && phoneError) && <div className="error" style={{ color: 'red' }}>{phoneError}</div>}
                <input
                    placeholder="Номер телефона"
                    onChange={(e) => phoneHandler(e)}
                    value={phone}
                    onBlur={(e) => blurHandler(e)}
                    name="phone"
                    className="loginform__input"
                    type="text"
                />

                {(nameDirty && nameError) && <div className="error" style={{color: "red"}}>{nameError}</div>}
                <input placeholder="Имя" onChange={e => nameHandler(e) && handleChangeName} value={name} onBlur={e => blurHandler(e)} name="name" className="loginform__input" type="text"/>

                {(surnameDirty && surnameError) && <div className="error" style={{color: "red"}}>{surnameError}</div>}
                <input placeholder="Фамилия" onChange={e => surnameHandler(e) && handleChangeSurname} value={surname} onBlur={e => blurHandler(e)} name="surname" className="loginform__input" type="text"/>

                {/*{(birthDateError) && <div className="error" style={{color: "red"}}>{birthDateError}</div>}*/}
                {/*<ThemeProvider theme={darkTheme} >*/}
                {/*    <LocalizationProvider dateAdapter={AdapterDayjs}>*/}
                {/*        <DatePicker*/}
                {/*            onBlur={e => blurHandlerBD(e)}*/}
                {/*            name="birthDate"*/}
                {/*            className="loginform__input"*/}
                {/*            label="Дата рождения"*/}
                {/*            value={birthDate}*/}
                {/*            onChange={(date) => {*/}
                {/*                const today = new Date();*/}
                {/*                const minBirthDate = new Date(today.getFullYear() - 17, today.getMonth() - 11, today.getDate()); // Минимальная дата рождения для 18 лет*/}

                {/*                if (date < minBirthDate) {*/}
                {/*                    let dateRaw = new Date(date);*/}
                {/*                    let dateFormatted =*/}
                {/*                        pad(dateRaw.getDate(), 2, '0') +*/}
                {/*                        pad(dateRaw.getMonth() + 1, 2, '0') +*/}
                {/*                        dateRaw.getFullYear();*/}

                {/*                    setBirthDateFormated(dateFormatted);*/}
                {/*                    setBirthDate(date);*/}
                {/*                    console.log(`data ${date}`);*/}
                {/*                    setBirthDateError("")*/}
                {/*                } else {*/}
                {/*                    setBirthDateError("Вы должны быть старше 18 лет")*/}
                {/*                    // Здесь вы можете предпринять нужные действия, например, показать сообщение об ошибке*/}
                {/*                }*/}
                {/*            }}*/}
                {/*            renderInput={(params) => <TextField {...params} />}*/}
                {/*        />*/}

                {/*    </LocalizationProvider>*/}
                {/*</ThemeProvider>*/}

                {(countryDirty && countryError) && <div className="error" style={{color: "red"}}>{countryError}</div>}
                <input placeholder="Страна" onChange={e => countryHandler(e) && handleChangeCountry} value={country} onBlur={e => blurHandler(e)} name="country" className="loginform__input" type="text"/>

                {(cityDirty && cityError) && <div className="error" style={{color: "red"}}>{cityError}</div>}
                <input placeholder="Город" onChange={e => cityHandler(e) && handleChangeCity} value={city} onBlur={e => blurHandler(e)} name="city" className="loginform__input" type="text"/>



                <div className="loginform__forgot-container"> <a href="/recovery" className="loginform__forgot">забыли пароль?</a> </div>
                <button className="loginform__button" disabled={!formValid} onClick={handleSubmit}>Регистрация</button>
                <div className="logonform__iydha-container"><p className="loginform__iydha">Уже есть аккаунт? <NavLink className="loginform__link" to="/register">Войдите</NavLink></p></div>
            </form>
        </div>)
};

export default Register;
