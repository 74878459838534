import React from 'react';
import './footer.css'
import {NavLink} from "react-router-dom";
import logo from '../../images/fantomfx logo.svg'
const Footer = () => {
    return (
        <footer className="footer-m">
            <div className="footer-section">
                <NavLink to="/main"><img src={logo} alt=""/></NavLink>
                <div className="rights">FantomFX © 2021-2023</div>
            </div>

            <div className="footer-section">
                <NavLink to='/about' className="footer-link">О нас</NavLink>
                <NavLink to='/specialists' className="footer-link">Команда аналитиков</NavLink>
            </div>

            <div className="footer-section">

                <NavLink to='/privacy' className="footer-link">Политика конфиденциальности</NavLink>
                <NavLink to='/contacts' className="footer-link">Контакты</NavLink>
            </div>

        </footer>
    );
};

export default Footer;