import React from 'react';
import './comps.css'
import Bar from "./bar";
const Specials = () => {
    return (
        <div className="comps__body">
            <Bar active={2}/>
<div className="comps__content">
            <p>
                <span style={{ fontSize: 20, color: "#fcd535" }}>Наши специалисты</span>
            </p>
            <p>
                <br />
                <span style={{ fontSize: 14, color: "white" }}>
      Криптовалюты продолжают оставаться одним из наиболее захватывающих и
      динамичных рынков в мире финансов. В таком сложном и быстро меняющемся
      окружении информация - это ключевой актив. И здесь на сцену выходит отдел
      аналитики <span style={{ color: "#fcd535", fontSize: 13 }}>FantomFX</span>, объединяющий
      40 ярких умов, каждый из которых вкладывает свои знания и опыт в понимание
      и прогнозирование динамики рынка криптовалют.
    </span>
            </p>
            <p>
                <strong>
      <span style={{ fontSize: 14, color: "white" }}>
        Лидер среди лидеров: Владислав Романовский
      </span>
                </strong>
                <br />
                <span style={{ fontSize: 14, color: "white" }}>
      Отдел аналитики <span style={{ color: "#fcd535", fontSize: 13 }}>FantomFX</span>{" "}
                    возглавляет Владислав Романовский, чье имя стало синонимом
      профессионализма и глубокого понимания криптовалютных трендов. С
      многолетним опытом в анализе рынка и инвестициях в цифровые активы,
      Владислав является не только экспертом в сфере, но и наставником для всей
      команды аналитиков.
    </span>
            </p>
            <p>
                <strong>
      <span style={{ fontSize: 14, color: "white" }}>
        Элитные аналитики: Профессиональное мастерство в действии
      </span>
                </strong>
                <br />
                <span style={{ fontSize: 14, color: "white" }}>
      В состав отдела входит пять топовых аналитиков, чьи умения и аналитические
      способности выходят далеко за рамки обычных. Эти профессионалы
      представляют кремниевую долину криптоанализа и являются ключевыми фигурами
      в формировании стратегий торговли и инвестирования на платформе{" "}
                    <span style={{ color: "#fcd535", fontSize: 13 }}>FantomFX</span>.
    </span>
            </p>
            <p>
                <span style={{ fontSize: 14, color: "white" }} />
                <br />
                <strong>
      <span style={{ fontSize: 14, color: "white" }}>
        Сила в численности: 34 младших аналитика
      </span>
                </strong>
                <br />
                <span style={{ fontSize: 14, color: "white" }}>
      Но кроме топ-специалистов, отдел аналитики несет на себе и большую команду
      младших аналитиков - 34 усердных и целеустремленных эксперта. Их работа
      заключается в анализе массовых данных, выявлении микротрендов и
      обеспечении оперативного реагирования на изменения рынка.
    </span>
            </p>
            <p>
                <strong>
      <span style={{ fontSize: 14, color: "white" }}>
        Цель и миссия отдела: Понимание и обогащение
      </span>
                </strong>
                <br />
                <span style={{ fontSize: 14, color: "white" }}>
      Целью отдела аналитики <span style={{ color: "#fcd535", fontSize: 13 }}>FantomFX</span>{" "}
                    является обеспечение клиентов и трейдеров платформы актуальной, точной и
      всесторонней информацией. Взвешенные анализы, проведенные командой,
      позволяют пользователям принимать обоснованные решения и успешно
      участвовать в рыночных движениях.
    </span>
            </p>
            <p>
                <strong>
                    <span style={{ fontSize: 14, color: "white" }}>Будущее аналитики: Инновации и рост</span>
                </strong>
                <br />
                <span style={{ fontSize: 14, color: "white" }}>
      В мире криптовалют нельзя останавливаться на достигнутом. Отдел аналитики{" "}
                    <span style={{ color: "#fcd535", fontSize: 13 }}>FantomFX</span> стремится к постоянному
      совершенствованию и развитию, интегрируя новейшие инструменты и методики
      анализа. Мы верим, что в будущем отдел аналитики станет еще более ценным
      источником информации и знаний для всех, кто интересуется криптовалютами.
    </span>
                <br />
                <br />
                <span style={{ fontSize: 14, color: "white" }}>
      Отдел аналитики криптовалютной биржи{" "}
                    <span style={{ color: "#fcd535", fontSize: 13 }}>FantomFX</span> - это мозаика умов,
      объединенных общей целью: дать Вам полный контроль над Вашими инвестициями
      и сделать опыт торговли на криптовалютном рынке более уверенным и
      успешным. Мы гордимся своей командой и с уверенностью смотрим в будущее,
      предвкушая новые вызовы и достижения в мире криптоанализа.
    </span>
            </p>


        </div></div>
    );
};

export default Specials;