import React from 'react';
import './comps.css'
import Bar from "./bar";
const Contacts = () => {
    return (
        <div className="comps__body comps__contact">
            <Bar active={4}/>
            <div className="comps__content w-full">
                <p>
    <span style={{ color: "#f1c40f" }}>
      <span style={{ fontSize: 20 }}>Контакты</span>
    </span>
                </p>
                <p>
                    <br/>
                    <span style={{fontSize: 14}}>
      Если у Вас возникли какие-либо вопросы, наши эксперты всегда готовы
      помочь! <br/>Свяжитесь с нами по электронной почте службы поддержки:{" "}
                        <span style={{color: "#f1c40f"}}> <br/>support@fantom-fx.net</span><br/><span
                        style={{color: "#f1c40f"}}>fantom-fx.supp@yandex.ru</span>
    </span><br/>
                    <span style={{color: "rgba(170, 170, 170, 1)"}}>ИНН: 3459762735</span>
                </p>
    {/*            <p>*/}
    {/*                <br/>*/}
    {/*                <span style={{fontSize: 14}}>*/}
    {/*  Наша команда поддержки готова ответить на Ваши запросы по электронной*/}
    {/*  почте 24/7. Независимо от того, нужна ли Вам помощь с торговлей,*/}
    {/*  безопасностью аккаунта или техническими вопросами, Вы можете быть уверены,*/}
    {/*  что наши специалисты предоставят Вам качественную поддержку.*/}
    {/*  <br />*/}
    {/*  <br />*/}
    {/*  Мы ценим Ваше участие в нашем криптовалютном сообществе и стремимся*/}
    {/*  сделать Ваше пребывание на платформе максимально комфортным. Не*/}
    {/*  стесняйтесь обращаться к нам — мы здесь, чтобы помочь!*/}
    {/*</span>*/}
    {/*            </p>*/}
            </div>
        </div>
    );
};

export default Contacts;